import axios from 'axios';
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../Style/Header/Header.css';

const Header = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const showToast = () => {
        toast.success('Loggedout', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };
    let Logout = async (e) => {
        // console.log('ohohoho')
        e.preventDefault()
        let y = window.confirm('Do you want to logout?')
        if (y) {
            let val = await axios.get('/logout')
            try {
                if (val.status === 200) {
                    localStorage.setItem('keyId', JSON.stringify(null))
                    localStorage.setItem('isAdmin', JSON.stringify(null))
                    showToast()
                    setTimeout(() => {
                        navigate('/login')
                    }, 1940);
                }
                else {
                    toast.error('Request failed!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000 // Duration in milliseconds
                    });
                }

            }
            catch (err) {
                console.log(err)
            }
        }
    }
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };
    let deleteAccount=async(e)=>{
        e.preventDefault()
        let y = window.confirm('Confirm Delete?')
        if (y) {
            let keyId=JSON.parse(localStorage.getItem('keyId'))
            let val = await axios.delete(`/User/${keyId}`)
            try {
                if (val.status === 200) {
                    localStorage.setItem('keyId', JSON.stringify(null))
                    localStorage.setItem('isAdmin', JSON.stringify(null))
                    showToast()
                    setTimeout(() => {
                        navigate('/login')
                    }, 1940);
                }
                else {
                    toast.error('Request failed!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000 // Duration in milliseconds
                    });
                }

            }
            catch (err) {
                console.log(err)
            }
        }
    }

    return (
        <div>
            <ToastContainer />
            {/* <header style={{backgroundColor:"#5650CE"}} className="header-section"> */}
            {/* <nav className="navbar navbar-expand-lg mini_Header" > */}
            <header style={{ backgroundColor: "#321691",zIndex:301 }} className="header-section Header_bar">

                <div className="container">
                    
                    <div className="header-wrapper">
                        <div className="logo">
                            <a href="/dashboard">
                                {/* <img src="assets/images/logo/logo.png" alt="logo" /> */}
                                {/* <img style={{color:'white'}} src="img/profile.png" alt="logo" /> */}
                                {/* <img style={{color:'white',height:"100px",margin:"0%",padding:"0%"}} src="img/default2.png" alt="logo" /> */}
                                {/* <img src="img/cover4.png" alt="logo" /> */}
                                <img style={{ height: "90px", marginTop: "-20%", marginBottom: '-25%', padding: "0%" }} src="img/default4.png" alt="logo" />
                                {/* <img style={{}} src="img/cover5.png" alt="logo" /> */}
                                {/* <img style={{height:"135px",marginTop:"-20%",marginBottom:'-25%',padding:"0%"}} src="img/default5.png" alt="logo" /> */}
                                {/* <h6>MY LOVODI</h6>  */}
                            </a>
                        </div>
                        <ul className="menu">
                            <li>
                                {/* <a href="/Matches" className="active">Matches</a> */}
                                {/* <Link onClick={toggleMobileMenu} to="/Matches" className={`nav-link text-white ${location.pathname === '/Matches' ? 'active' : ''}`}>Matches</Link> */}
                                <Link onClick={toggleMobileMenu} to="/newMatches" className={`nav-link text-white ${location.pathname === '/newMatches' ? 'active' : ''}`}>Matches</Link>
                            </li>
                            <li>
                                {/* <a href="/inbox">Inbox</a> */}
                                {/* <Link onClick={toggleMobileMenu} to="/inbox" className={`nav-link text-white ${location.pathname === '/inbox' ? 'active' : ''}`}>Inbox</Link> */}
                                <Link onClick={toggleMobileMenu} to="/inboxReceived-newestFirst" className={`nav-link text-white ${location.pathname === '/inboxReceived-newestFirst' ? 'active' : ''}`}>Inbox</Link>
                            </li>
                            {/* <li> */}
                            {/* <a href="/notification">Notification</a> */}
                            {/* <Link onClick={toggleMobileMenu} to="/notification" className={`nav-link text-white ${location.pathname === '/notification' ? 'active' : ''}`}>Notification</Link> */}
                            {/* </li> */}
                            <li>
                                {/* <a href="/Chat">Chat</a> */}
                                <Link onClick={toggleMobileMenu} to="/Chat" className={`nav-link text-white ${location.pathname === '/Chat' ? 'active' : ''}`}>Chat</Link>
                            </li>
                            <li>
                                {/* <a href="/myplan">My plan</a> */}
                                <Link onClick={toggleMobileMenu} to="/myplan" className={`nav-link text-white ${location.pathname === '/myplan' ? 'active' : ''}`}>Myplan</Link>
                            </li>
                            {/* <li> */}
                            {/* <a href="/help">Help</a> */}
                            {/* <Link onClick={toggleMobileMenu} to="/help" className={`nav-link text-white ${location.pathname === '/help' ? 'active' : ''}`}>Help</Link>
                            </li> */}
                            <li>
                                {/* <a href="/DashboardMyPhotos">Photo</a> */}
                                <Link onClick={toggleMobileMenu} to="/DashboardMyPhotos" className={`nav-link text-white ${location.pathname === '/DashboardMyPhotos' ? 'active' : ''}`}>Photos</Link>
                            </li>
                            <li>
                                {/* <a href="/allplan">All plan</a> */}
                                <Link onClick={toggleMobileMenu} to="/allplan" className={`nav-link text-white ${location.pathname === '/allplan' ? 'active' : ''}`}>All plan</Link>
                            </li>
                            {/* <li>
                                <Link onClick={toggleMobileMenu} to="/billing" className={`nav-link text-white ${location.pathname === '/billing' ? 'active' : ''}`}>Cart</Link>
                            </li> */}

                            <li className="separator">
                                <span>|</span>
                            </li>
                            {/* <li>
                                <div className="serch-icon">
                                    <a href="/search">
                                        <i className="fas fa-search"></i>
                                    </a>
                                </div>
                            </li> */}

                            {/* <li>
                                <div className="language-select">
                                    <select className="select-bar">
                                        <option value="">EN</option>
                                        <option value="">IN</option>
                                        <option value="">BN</option>
                                    </select>
                                </div>
                            </li> */}
                            <li className="user-profile">
                                <a href="#">
                                    <img src="assets/images/user-demo.png" alt="" />
                                </a>
                                <ul className="submenu">
                                    <li>
                                        <a href="/DashboardProfile">Profile</a>
                                    </li>
                                    <li>
                                        <a href="/userpanel">Account Settings</a>
                                    </li>
                                    <li>
                                        <a href="/contactFilter">Contact Filters</a>
                                    </li>
                                    <li>
                                        <a href="/emailAlert">Email / SMS Alerts</a>
                                    </li>
                                    <li>
                                        <a href="/privacyPolicy">Privacy Options</a>
                                    </li>
                                    {/* <li>
                                        <a href="/help">Help</a>
                                    </li> */}
                                    <li>
                                        <a href="/notification">Notification</a>
                                    </li>
                                    <li>
                                        <a href="/generate-tickets">Generate Tickets</a>
                                    </li>
                                    <li>
                                        <a href="" onClick={Logout} >Logout</a>
                                    </li>
                                    <li>
                                            <a href="" onClick={deleteAccount} >Delete Account</a>
                                        </li>
                                </ul>
                            </li>
                        </ul>
                        {/* <div className="header-bar d-lg-none">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div> */}
                        <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                            {/* <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div> */}
                            <span></span>
                            <span></span>
                            <span></span>
                            {/* <span></span> */}
                        </div>

                    </div>
                </div>
            </header>
            {/* </nav> */}
        </div>
    )
}

export default Header