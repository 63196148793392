import User_Info from '../UserDetails/User_Info'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
// #lobby
import React, { useEffect, useRef, useState } from "react"
// const Chat_Section = ({ selectedChat, receiver, mainUser, socket }) => {
const Chat_Section = ({ selectedChat, receiver, mainUser, socket, me }) => {
    console.log('mainUser', mainUser)
    console.log('receiver', receiver)
    console.log('socket chatsection', socket)
    console.log('me chatsection', me)
    let navigate = useNavigate()
    console.log('selectedChat', selectedChat)
    const [showComponent, setComponent] = useState(false);
    const [chatMessages, setChatMessages] = useState(selectedChat?.messages);
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    const [message, sentMessage] = useState({ content: '', sender: keyId, receiver: receiver });
    async function showNotification(notificationData) {
        try {
            let val = await axios.get(`/user/${notificationData?.sender}`);

            if ('Notification' in window) {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        new Notification(`A message: "${notificationData?.content}" has been sent by ${val?.data?.name}`);
                    } else {
                        console.log('Notifications not allowed by user');
                    }
                });
            } else {
                console.log('Notifications not supported by this browser');
            }
        }
        catch (er) {

        }
    }

    useEffect(() => {
        socket.on('getNotification', (notificationData) => {
            console.log('Received notification:', notificationData);
            console.log('Current chatMessages:', chatMessages);
            setChatMessages((prevMessages) => [...prevMessages, notificationData]);

            // Trigger the notification
            showNotification(notificationData);

            // Handle the received notification data here
        });

        // Clean up the socket listener when the component unmounts
        return () => {
            socket.off('getNotification');
        };
    }, [socket]);
    let fetchVoice = async (e) => {
        // setComponent(true)
        console.log('mainUser', mainUser)
        // e.preventDefault();
        let email = mainUser?.email
        let room = mainUser?.email
        socket?.emit("room:join", { email, room });
        // navigate(`/room/${room}`);
    }
    let inputEvent = (e) => {
        console.log(e.target.name, e.target.value)
        sentMessage({ ...message, [e.target.name]: e.target.value })
    }
    let submitEvent = async (e) => {
        try {
            e.preventDefault();
            console.log('message', message)
            let val = await fetch('/chat', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(message)
            })
            if (val.status === 200) {
                // fetchChat()
                alert('sent')
                setChatMessages((prevMessages) => [...prevMessages, message]);
                // inputRef.current.value = '';
                let date = new Date()
                date = date.toLocaleTimeString()
                console.log('Current chatMessages:', chatMessages);
                console.log('keyId in chatHeader', keyId)
                console.log('keyId in chatHeader', receiver)
                console.log('content in chatHeader', message?.content)
                console.log('chatId in chatHeader', selectedChat?._id)
                socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: receiver,
                    receiverId: receiver,
                    type: 'message',
                    content: message?.content,
                    chatId: selectedChat?._id,
                    date: date
                });
            } else {
                alert('err')
            }
        } catch (error) {

        }

    }
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    let navigateCallUser = async (id) => {
        console.log('id', id)
        navigate('/Lobby')
    }
    let fetchData = async (id) => {
        try {
            let val = await axios.get(`/user/${id}`)
            return val.data
        } catch (error) {

        }
    }
    return (
        <div>
            <div className="py-2 px-4 border-bottom d-none d-lg-block">
                <div className="d-flex align-items-center py-1">
                    {/* <div className="position-relative">
                        <img src="https://bootdey.com/img/Content/avatar/avatar3.png" className="rounded-circle mr-1" alt="Sharon Lessman" width="40" height="40" />
                    </div> */}
                    <div className="flex-grow-1 pl-3">
                        <strong> <User_Info title={'name'} id={selectedChat?.receiver} /></strong>
                        {/* <div className="text-muted small"><em>Typing...</em></div> */}
                    </div>
                    <div style={{ display: 'flex' }}>

                        {/* <button onClick={() => { fetchVoice() }} className="btn btn-primary btn-lg mr-1 px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone feather-lg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg> */}
                        <button onClick={() => navigateCallUser('voice')} className="btn btn-primary btn-lg mr-1 px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-phone feather-lg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                        </button>
                        <button onClick={() => navigateCallUser('video')} className="btn btn-info btn-lg mr-1 px-3 d-none d-md-inline-block"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-video feather-lg"><polygon points="23 7 16 12 23 17 23 7"></polygon><rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect></svg></button>
                        <button className="btnF btn-light border btn-lg px-3"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-more-horizontal feather-lg"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg></button>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="chat-messages p-4">
                    {chatMessages?.map((ele, ind) => {
                        return (
                            ele?.sender === keyId ?
                                <div className="chat-message-right pb-4">
                                    <div>
                                        {/* <img src="https://bootdey.com/img/Content/avatar/avatar1.png" className="rounded-circle mr-1" alt="Chris Wood" width="40" height="40" /> */}
                                        {/* <User_Info id={ele?.sender} title={'photos'} /> */}
                                        {/* <div className="text-muted small text-nowrap mt-2">{setDates(ele?.timestamp)?.toString()?.split('T')[0]}</div> */}

                                    </div>
                                    <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                        <div className="font-weight-bold mb-1">You</div>
                                        {ele.content}
                                        <div className="text-muted small text-nowrap mt-2">{setDates(ele?.timestamp)?.toString()?.split('T')[0]}</div>
                                        {/* {console.log('i am ele', ele)} */}
                                    </div>
                                </div>
                                :
                                <div className="chat-message-left pb-4">
                                    <div>
                                        {/* <User_Info id={ele?.sender} title={'photos'} /> */}
                                    </div>
                                    <div className="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                        <div className="font-weight-bold mb-1"> <User_Info title='name' id={receiver} /> </div>
                                        {ele.content}
                                        <div className="text-muted small text-nowrap mt-2">{setDates(ele?.timestamp)?.toString()?.split('T')[0]}</div>
                                    </div>
                                </div>

                        )
                    })}
                </div>
            </div>
            {mainUser?.accountType !== 'free' ?
                <div className="flex-grow-0 py-3 px-4 border-top">
                    {(!mainUser?.blockedUsers?.includes(selectedChat.sender)) && (!mainUser?.blockedUsers?.includes(selectedChat.receiver)) ?
                        (!fetchData(selectedChat.sender)?.blockedUsers?.includes(mainUser._id)) && (!fetchData(selectedChat.receiver)?.blockedUsers?.includes(mainUser._id)) ?
                            <div className="input-group">
                                <input onChange={(e) => inputEvent(e)} type="text" className="form-control" name='content' placeholder="Type your message" />
                                <div>
                                    <button onClick={(e) => submitEvent(e)} className=" custom-button mx-2">Send</button>
                                </div>
                            </div>
                            : 'You are not allowed to sent messages'
                        : 'You are not allowed to sent messages'}
                </div>
                : 'Only premium members can send messages'}
        </div>
    )
}

export default Chat_Section