import React, { useState } from 'react';
// import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
// import GroupIcon from '@mui/icons-material/Group';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import CallIcon from '@mui/icons-material/Call';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import SendIcon from '@mui/icons-material/Send';
import '../Style/Cards/Card6.css';
import { useNavigate } from 'react-router-dom';
import User_Card_Info from '../UserDetails/User_Card_Info';
// import User_Info from '../UserDetails/User_Info';

const Card6 = (params) => {
    // let navigate = useNavigate()
    // // Define images for the slider
    // const images = [
    //     'image1.jpg',
    //     'image2.jpg',
    //     'image3.jpg',
    //     // Add more image URLs here
    // ];
    console.log('params', params)
    let user = params.user;
    let value = params.value;
    // State to manage the currently selected slide
    // const [selectedSlide, setSelectedSlide] = useState(0);

    // const handleSlideChange = (index) => {
    //     setSelectedSlide(index);
    // };

    return (
        value === 'accept' ?
            user?.friends?.length !== 0 ?
                user?.friends?.map((ele, ind) => {
                    return (
                        <>
                        <User_Card_Info socket={params.socket} user={user} ele={ele} />
                        </>
                    )
                })
                : ''
            : user?.sentRequests?.length !== 0 ?
                user?.sentRequests?.map((ele, ind) => {
                    return (
                        <>
                        <User_Card_Info socket={params.socket} user={user} ele={ele} />
                        </>
                    )
                })
                : ''
    );
};

export default Card6;