import React, { useEffect, useState } from 'react'
import Dashboard_Nav from '../Dashboard/Components/Navbar/Dashboard_Nav'
import Header from '../Components/Home/Header'
import Dashboard_Card_1 from '../Dashboard/Components/Card/Dashboard_Card_1'
import Dashboard_Card_2 from '../Dashboard/Components/Card/Dashboard_Card_2'
import Dashboard_Card_Main1 from '../Dashboard/Components/Card/Dashboard_Card_Main1'
import Dashboard_Card_Main2 from '../Dashboard/Components/Card/Dashboard_Card_Main2'
import '../Style/Dashboard_Home/Dashboard_Home.css'
import Dashboard_Breadcrumb from './Dashboard_Breadcrumb'
import Card5 from '../Cards/Card5'
import Card3 from '../Cards/Card3'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'
import axios from 'axios'
import Admin_Topbar from '../Admin/Admin_Topbar'
import New_Header from '../Components/Home/New_Header'

const Dashboard_Home = ({ socket }) => {
  const images = [
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    // Add more image URLs as needed
  ];
  let [isUser, setIsUser] = useState()
  let [user, setUser] = useState()
  let [user2, setUser2] = useState()
  let [user3, setUser3] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let [loadingForChat5, setLoadingForChat5] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])

  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/User/${keyId}`)
      console.log('val.data', val.data)
      setUser(val.data)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser2 = async (keyId) => {
    try {
      let val = await axios.get(`/users/search/${keyId}`)
      // console.log('user2 val.data', val.data)
      let val2 = []
      // val?.data?.potentialMatches?.forEach((ele) => {
      //   console.log('here here_0', ele)
      //   if (!user.sentRequests.includes(ele._id)) {
      //     // console.log('here here_1')
      //     // if (!user.sentRequests.includes(ele._id)) {
      //     //   console.log('here here_2')
      //     //   return val2.push(ele);
      //     // }
      //     val2.push(ele);
      //   }
      // });
      setUser2(val?.data?.potentialMatches)
      // setUser2(val2)
    } catch (error) {
      console.log(error)
    }
  }

  let fetchUser3 = async (keyId) => {
    try {
      let val = await axios.get(`/users/members-looking-for-me//${keyId}`)
      // let val = await axios.get(`/users/search/${keyId}`)
      // console.log('val.data_premium', val)
      // console.log('val.data_premium', val.data)
      setUser3(val?.data?.userIDs)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
    fetchUser2(keyId)
    fetchUser3(keyId)
  }, [])

  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div style={{  width: "100vw" }}>
          <div className='showHeader'>
            <Header />
          </div>
          <Dashboard_Breadcrumb />
          <div className='Dashboard_Home0'>
            {/* style={{backgroundColor:"rgb(86,52,201)",border:"2px solid"}} */}
            <div>
              <Dashboard_Nav />
              <div className='Dashboard_Home'>
                <div className='Hide_seek'>
                  <Dashboard_Card_1 user={user} />
                </div>
                <div className='Dashboard_Home_Main_Cards'>
                  <Dashboard_Card_Main1 user={user} />

                  <div className='Dashboard_Home_Main_Cards_2'>
                    {/* <Dashboard_Card_Main2 images={images} user={user} /> */}
                    <div className='cards_Display'>
                    <div style={{ textAlign: 'center' }}>
                      {/* receive */}
                      {/* <Card3 socket={socket} user={user} value='receive' /> */}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                    {/* visitors */}
                      {/* <Card3 socket={socket} user={user} value='visitors' /> */}
                    </div>
                    </div>
                    {/* , width: '820px' */}
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <div style={{ textAlign: 'center' }}>
                        {user2 ? <Card5 socket={socket} user={user2} mainUser={user} /> : ''}
                      </div>
                    </div>
                  </div>

                </div>

                <div>
                </div>
              </div>
            </div>
          </div>

        </div>
        : navigate('/')
  )
}

export default Dashboard_Home