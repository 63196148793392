import React, {useEffect, useRef, useState } from 'react'
// import '../../Styling/Login.css'
// import Footer from '../HomeComponents/Footer'
// import Navbar from '../HomeComponents/Navbar'
import axios from 'axios'
import { useNavigate,useParams } from 'react-router-dom'
import {
    MDBInput,
    MDBBtn,
    MDBCheckbox,
    MDBRow,
    MDBCol,
    MDBAccordion
} from 'mdb-react-ui-kit';

const Forget_Password = () => {
    let Navigate = useNavigate();
    let useparamsid=useParams()
    let [state, updatestate] = useState({})
    let [loginState, checkLoginState] = useState({})
    const [formErrors, setFormErrors] = useState({});
    
    // const [stateInput, updateStateInput] = useState({});
    const formRef = useRef(null);
    useEffect(()=>{
        let keyId=JSON.parse(localStorage.getItem('keyId'));
        checkLoginState(keyId);
    },[])
    let inputEvent = (e) => {
        if(e.target.name==='password')
        {
          let value=e.target.value;
          value=value.toLowerCase();
          updatestate({ ...state, [e.target.name]: value });
        }
        else
        {
            updatestate({ ...state, [e.target.name]: e.target.value });
        }
    }
    let validateForm = () => {
        const errors = {};
        // Validate individual form fields
        if (!state.password) {
            errors.password = 'password is required';
        }
        // Set the form errors state
        setFormErrors(errors);
        console.log('here_formErrors', formErrors);
        // Return true if the form is valid (no errors), false otherwise
        return Object.keys(errors).length === 0;
    }
    
    let submitEvent = async (e) => {
        e.preventDefault();
        console.log('state', state);
        const isValid = validateForm();
        const formData = new FormData(formRef.current);
        const data = Object.fromEntries(formData.entries());
        if (isValid) {
            try {
                let val = await fetch(`/reset-password/${useparamsid.token}`, {
                    method: "POST",
                    headers: {
                        "content-Type": "application/json"
                    },
                    body: JSON.stringify(state)
                })
                console.log('i am login val', val);
                if (val.status == 200) {
                    alert('PAssword updated sucessfully')
                    Navigate('/');
                }
                else {
                    alert('Error Loggining you in');
                }
            } catch (error) {
                console.log(error);
            }
        }
        else {
            console.log('Form validation errors:', formErrors);
        }
    }
    
    return (
        loginState==null?
        <div >
            {/* <Navbar /> */}
            <div className='Forgot_Part_One'>
            <div style={{width:"670px",margin:'2% auto'}} className='Login'>
                <form action="/"  ref={formRef}>
                    <h1 style={{ color: "#000" }}>Don't Share Link with anyone</h1>
                    <div className="icon">
                        <i className="fas fa-user-circle"></i>
                    </div>
                    <MDBRow tag="form" className='g-3' >

                        <MDBCol md="12">
                        <label for="password"><strong style={{ color: "#000" }}>New Password</strong></label>
                            <MDBInput required onChange={inputEvent} type="password" name="password" placeholder="new password" />
                            {formErrors.password && <p className="error">{formErrors.password}</p>}
                        </MDBCol>
                        <MDBCol size="12">
                            {/* <MDBBtn type='submit' className='MDBBtn' onClick={submitEvent}>Login</MDBBtn> */}
                            <button type='submit' className='custom-button' onClick={submitEvent}>Reset</button>
                        </MDBCol>
                    </MDBRow>

            {/* </div> */}
                </form>
                {/* <Newsletter /> */}
            </div>
            </div>
        </div>
        :
        // 'fawad'
        Navigate('/')
    )
}

export default Forget_Password