import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const Admin_Members_Nav = () => {
  const location = useLocation();

  return (
    // bg-primary
    <div className='Dashboard_Nav '>
      <nav className="navbar navbar-expand-lg mini_Header" style={{backgroundColor:"#ff4d4d"}}>
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('active'))}} to="/admin-members-active" className={`nav-link text-white ${location.pathname === '/admin-members-active' ? 'active' : ''}`}>Active</Link>
              </li> */}
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('trial'))}} to="/admin-members-all" className={`nav-link text-white ${location.pathname === '/admin-members-all' ? 'active' : ''}`}>All
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('trial'))}} to="/admin-members-trial" className={`nav-link text-white ${location.pathname === '/admin-members-trial' ? 'active' : ''}`}>Trial
                </Link>
                
              </li>
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('free'))}} to="/admin-members-free" className={`nav-link text-white ${location.pathname === '/admin-members-free' ? 'active' : ''}`}>Free</Link>
              </li>
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('premium'))}} to="/admin-members-premium" className={`nav-link text-white ${location.pathname === '/admin-members-premium' ? 'active' : ''}`}>Premium</Link>
              </li>
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('deleted'))}} to="/admin-members-deleted" className={`nav-link text-white ${location.pathname === '/admin-members-deleted' ? 'active' : ''}`}>Deleted</Link>
              </li>
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('blocked'))}} to="/admin-members-blocked" className={`nav-link text-white ${location.pathname === '/admin-members-blocked' ? 'active' : ''}`}>Blocked</Link>
              </li>
              <li className="nav-item">
                <Link onClick={()=>{localStorage.setItem('member_link',JSON.stringify('reported'))}} to="/admin-members-reported" className={`nav-link text-white ${location.pathname === '/admin-members-reported' ? 'active' : ''}`}>Reported</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Admin_Members_Nav;
