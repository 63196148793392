import React, { useEffect, useState } from 'react'
import Admin_Sidebar from '../Admin_Home/Components/Admin_Sidebar'
import Admin_Home_Header from '../Admin_Home/Components/Admin_Home_Header'
import Admin_Home_Table from '../Admin_Home/Components/Admin_Home_Table'
import Admin_Members_Nav from '../Admin_Members/Components/Admin_Members_Nav'
import User_Panel_Header from '../../Components/User_Panel/User_Panel_Header'
import Admin_Plan_Table from '../Admin_Plan/Components/Admin_Plan_Table'
import Admin_Tickets_Table from '../Admin_Tickets/Components/Admin_Tickets_Table'
import Admin_Tickets_Nav from '../Admin_Tickets/Components/Admin_Tickets_Nav'
import Admin_Blog_Table from '../Admin_Blogs/Components/Admin_Blog_Table'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Admin_Social_Header from '../Admin_Social/Components/Admin_Social_Header'
import Admin_Topbar from '../Admin_Topbar'
import Admin_Terms_Header from '../Admin_Terms/Components/Admin_Terms_Header'

const Admin_Terms = () => {
    let [plans, updatePlans] = useState([])
    let allPlans = async () => {
        try{
        let val = await axios.get('/blog');
        updatePlans(val.data)}
        catch(err){

        }
    }
    useEffect(() => {
        allPlans()
    }, [])
    let navigate = useNavigate()
    let [isAdmin, setIsAdmin] = useState()
    let [isStaff, setIsStaff] = useState()
    let [loading, setLoading] = useState(true)
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let verify_Admin_And_Staff = async (keyId) => {
        try {
            let val = await axios.get(`/user/${keyId}`)
            console.log('val.data.isAdmin', val.data.isAdmin)
            console.log('val.data.isEmployee', val.data.isEmployee)
            setIsAdmin(val.data.isAdmin)
            setIsStaff(val.data.isEmployee)
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
            console.log(err)
        }
    }
    useEffect(() => {
        verify_Admin_And_Staff(keyId)
    }, [])
    return (
        loading ?
            (
                <Loader />
            )
            :
            isAdmin || isStaff ?
                <div className='Admin_Home'>
                    <div id="app">
                        <div class="main-wrapper main-wrapper-1">
                            <div class=""></div>
                            <Admin_Topbar />
                            <div className='hideElements'>
                                <Admin_Sidebar />
                            </div>
                            <div class="main-content">
                                <section class="section">
                                    <Admin_Terms_Header data={plans} />
                                </section>
                            </div>
                        </div>
                    </div >
                </div >
                : navigate('/')
    )
}

export default Admin_Terms