import axios from 'axios'
import React, { useEffect, useState } from 'react'
import User_Info from '../../UserDetails/User_Info'
import { useNavigate } from 'react-router-dom'

const Profile_Single_Header = () => {
    let [user, setUser] = useState({})
    let [mainUser, setMainUser] = useState({})
    let [membership, setMembership] = useState({})
    let [contactView, setContactView] = useState(false)
    let [contactView2, setContactView2] = useState(false)
    let [contactDetailSum, setContactDetailSum] = useState()
    let [overallSum, setOverallSum] = useState()
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let singleProfileId = JSON.parse(localStorage.getItem('singleProfileId'))
    console.log('singleProfileId', singleProfileId)
    let fetchUser = async () => {
        try {
            let val = await axios.get(`/user/${singleProfileId}`)
            console.log('searching here', val)
            setUser(val.data)
        }
        catch (err) {

        }
    }
    useEffect(() => {
        fetchUser()
    }, [singleProfileId])
    let fetchMainUser = async () => {
        let keyId = JSON.parse(localStorage.getItem('keyId'))
        try {
            let val = await axios.get(`/user/${keyId}`)
            setMainUser(val.data)
            fetchMembership(keyId)
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchMainUser()
    }, [])
    function getViewContactInfoLimit(features) {
        const viewContactInfoFeature = features.find(feature => feature.includes('View contact info'));
        if (viewContactInfoFeature) {
            // Extracting the numeric limit
            const limit = viewContactInfoFeature.match(/\d+/);
            if (limit) {
                return parseInt(limit[0]); // Convert the extracted limit to a number
            }
        }
        return null; // Return null if the limit is not found
    }
    let contactsSum = 0;
    let contactsSum2 = 0;
    let fetchEachPlan = async (plan) => {
        try {
            let val = await axios.get(`/allPlansss/${plan.plan}`)
            console.log('ele4 contactsSum plan.isRenew', plan.isRenew)
            if (plan.isRenew == false) {
                const viewContactInfoLimit = getViewContactInfoLimit(val.data?.features)
                if (viewContactInfoLimit !== null) {
                    contactsSum = contactsSum + viewContactInfoLimit;
                }
                console.log('ele4', viewContactInfoLimit)
                console.log('ele4 contactsSum', contactsSum)
                setContactView2(true)
                setContactDetailSum(contactsSum)
            }
            else {
                const viewContactInfoLimit = getViewContactInfoLimit(val.data?.features)
                if (viewContactInfoLimit !== null) {
                    contactsSum2 = contactsSum2 + viewContactInfoLimit;
                }
                console.log('viewContactInfoLimit', viewContactInfoLimit)
                console.log('contactsSum2', contactsSum2)
                // setContactView2(true)
                // setContactDetailSum(contactsSum2)
                for (let index = 0; index < plan.renewTimes; index++) {
                    contactsSum2 += contactsSum2;

                }
            }
            let totalSum = contactsSum + contactsSum2;
            console.log('totalSum (plan.renewTimes)', plan.renewTimes);
            console.log('totalSum (contactsSum)', contactsSum);
            console.log('totalSum (contactsSum2)', contactsSum2);
            console.log('totalSum (setContactDetailSum)', contactDetailSum);
            console.log('totalSum', totalSum);
            setOverallSum(totalSum)
        } catch (error) {

        }
    }
    let fetchMembership = async (data) => {
        try {
            let val = await axios.get(`/membership-plan/${data}`)
            console.log('membership data', val.data)
            val?.data?.map((ele, ind) => {
                return (
                    <>
                        {console.log('ele', ele)}
                        {/* {(ele) => fetchEachPlan(ele)} */}
                        {fetchEachPlan(ele)}
                    </>
                )
            })
            setMembership(val.data)
        } catch (error) {

        }
    }
    let navigate = useNavigate()
    let viewDetails = async () => {
        // contactsViewed.push(singleProfileId)
        try {
            let newUserId = singleProfileId
            console.log('mainUser?.contactsViewed?.includes(singleProfileId)', mainUser?.contactsViewed?.includes(singleProfileId))
            if (mainUser?.contactsViewed?.includes(singleProfileId).toString() === 'false') {
                try {
                    let val = await fetch(`/user/${keyId}`, {
                        method: "PUT",
                        body: JSON.stringify({ newUserId })
                    })
                    console.log('val', val)
                    setContactView(true)
                } catch (error) {

                }
                // if(val.status)
            }
            else {
                setContactView(true)
            }
        }
        catch (err) {

        }
    }
    // useEffect(() => {
    //     viewDetails()
    // }, [])
    
  const Blocked = async () => {
    try {
    let y=window.confirm('Do you really want to block?')      
    if(y){
        const response = await axios.post(`/users/block/${singleProfileId}`);
        const responseData = response.data;
        console.log('Response Data:', responseData);
        if(response.status==200)
        {
            alert('Blocked sucessfull!')
            navigate('/dashboard')
        }
        else{
            alert('error in proceeding!')
        }
    }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            // setIsBlocked(true);
        }
    }
};
    return (
        // !mainUser?.blockedUsers?.includes(singleProfileId) ?
            <div>
                <section className="profile-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5 col-md-7">
                                <div className="left-profile-area">
                                    <div className="profile-about-box">
                                        <div className="top-bg"></div>
                                        <div className="p-inner-content">
                                            <div className="profile-img">
                                                {<User_Info id={singleProfileId} title={'photos'} />}
                                            </div>
                                            <h5 className="name">
                                                {<User_Info id={singleProfileId} title={'name'} />}
                                            </h5>
                                            <ul className="p-b-meta-one">
                                                <li>
                                                    <span><User_Info id={singleProfileId} title={'age'} /> Years Old</span>
                                                </li>
                                                <li>
                                                    <span> <i className="fas fa-map-marker-alt"></i>{<User_Info id={singleProfileId} title={'country'} />}</span>
                                                </li>
                                            </ul>
                                            <div className="p-b-meta-two">
                                                <div className="right">
                                                    <p className="custom-button">
                                                        <i onClick={Blocked} className="fab fa-cloudversify"></i> Block
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-meta-box">
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6">
                                <div className="profile-main-content">
                                    <div className="info-box">
                                        <div className="header">
                                            <h4 className="title">
                                                Base
                                            </h4>
                                        </div>
                                        <div className="content">
                                            <ul className="infolist">
                                                <li>
                                                    <span>
                                                        Name
                                                    </span>
                                                    <span>
                                                        {/* Albert Don */}
                                                        {/* {user.name} */}
                                                        {<User_Info id={singleProfileId} title={'name'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Birthday
                                                    </span>
                                                    <span>
                                                        {/* 1998-01-19 */}
                                                        {/* {user.dob} */}
                                                        {<User_Info id={singleProfileId} title={'dob'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        I am a
                                                    </span>
                                                    <span>
                                                        {/* {user.gender} */}
                                                        {<User_Info id={singleProfileId} title={'gender'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Looking for a
                                                    </span>
                                                    <span>
                                                        {user.gender === 'male' ? 'Woman' : 'Man'}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Marital status
                                                    </span>
                                                    <span>
                                                        {/* single */}
                                                        {/* {user.status} */}
                                                        {<User_Info id={singleProfileId} title={'status'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Country
                                                    </span>
                                                    <span>
                                                        {/* France */}
                                                        {/* {user.country} */}
                                                        {<User_Info id={singleProfileId} title={'country'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        City
                                                    </span>
                                                    <span>
                                                        {/* {user.city} */}
                                                        {<User_Info id={singleProfileId} title={'city'} />}
                                                    </span>
                                                </li>
                                                {
                                                    mainUser?.accountType !== 'free' ?
                                                        contactView2 ?
                                                            // mainUser?.contactsViewed?.length <= contactDetailSum ?
                                                            mainUser?.contactsViewed?.length <= overallSum ?
                                                                <>
                                                                    <button className='custom-button' onClick={() => {
                                                                        viewDetails()
                                                                    }} >View Contact Details</button>
                                                                </>
                                                                :
                                                                ''
                                                            :
                                                            ''
                                                        // <>
                                                        // mainUser?.contactsViewed?.length;{mainUser?.contactsViewed?.length}
                                                        // contactDetailSum;{contactDetailSum}
                                                        // </>
                                                        : ''
                                                }


                                                {contactView ?
                                                    <>
                                                        <li>
                                                            <span>
                                                                Email
                                                            </span>
                                                            <span>
                                                                {<User_Info id={singleProfileId} title={'email'} />}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                Phone
                                                            </span>
                                                            <span>
                                                                {<User_Info id={singleProfileId} title={'phone'} />}
                                                            </span>
                                                        </li>
                                                    </>
                                                    : ''
                                                }
                                                <li>
                                                    <span>
                                                        Income
                                                    </span>
                                                    <span>
                                                        {<User_Info id={singleProfileId} title={'income'} />}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <div className="header">
                                            <h4 className="title">
                                                Myself Summary
                                            </h4>
                                        </div>
                                        <div className="content">
                                            <p className="text">
                                                {/* I don`t like talk too much to be honest and
                                            especially about myself. I am man of actions, I do a lot of sports, I adore to travel and to see the world. */}
                                                {/* {user.description} */}
                                                {<User_Info id={singleProfileId} title={'description'} />}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <div className="header">
                                            <h4 className="title">
                                                More Info
                                            </h4>
                                        </div>
                                        <div className="content">
                                            <ul className="infolist">
                                                <li>
                                                    <span>
                                                        Smoking
                                                    </span>
                                                    <span>
                                                        {<User_Info id={singleProfileId} title={'smoke'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Drink
                                                    </span>
                                                    <span>
                                                        {<User_Info id={singleProfileId} title={'drink'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Language
                                                    </span>
                                                    <span>
                                                        {/* {user.motherTongue} */}
                                                        {<User_Info id={singleProfileId} title={'motherTongue'} />}
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Height
                                                    </span>
                                                    <span>
                                                        {<User_Info id={singleProfileId} title={'height'} />}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <div className="content">
                                            <ul className="infolist">
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            // :
            // <>
            //     <h5 className="name">
            //         {<User_Info id={singleProfileId} title={'name'} />}
            //     </h5>
            //     <ul className="p-b-meta-one">
            //         <li>
            //             <span><User_Info id={singleProfileId} title={'age'} /> Years Old</span>
            //         </li>
            //         <li>
            //             <span> <i className="fas fa-map-marker-alt"></i>{<User_Info id={singleProfileId} title={'country'} />}</span>
            //         </li>
            //     </ul>
            //     <button className='custom-button' onClick={unblockAccount} >Unblock Account</button>

            // </>
    )
}

export default Profile_Single_Header