import axios from 'axios'
import React, { useEffect, useState } from 'react'

const Blog_Header = () => {
    let [stories, getStories] = useState()
    let fetchSuccess = async () => {
        try {
            let val =await axios.get(`/blog`)
            console.log('val.data blogs',val)
            console.log('val.data blogs',val.data)
            const reversedBlogs = val.data.reverse();
            console.log('reversedBlogs',reversedBlogs)
            if (reversedBlogs.length > 0) {
                getStories(reversedBlogs[0])
            }
            console.log('blogs',stories)
            // .slice(0, 3)
            // getStories(val.data.reverse())
        }
        catch (err) {
    
        }
    }
    useEffect(() => {
        fetchSuccess()
    }, [])
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    return (
        <div>
            <section className="blog-page single-blog-page">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-blog">
                                        <div className="img">
                                        {stories?.img?
                                            <img style={{width:"730px",height:"413px"}} src={`uploads/${stories?.img}`} alt="" />
                                            :
                                            <img src="assets/images/blog/blog1.png" alt="" />}
                                        </div>
                                        <div className="content">
                                            <div className="left">
                                                <div className="avatar">
                                                    {/* <img src="assets/images/blog/author-avarat.png" alt="" /> */}
                                                    {/* <img src="assets/images/blog/author-avarat.png" alt="" /> */}
                                                </div>
                                            </div>
                                            <div className="right">
                                                <p className="date">
                                                {/* {} */}
                                                {setDates(stories?.createdAt)?.toString()?.split('T')[0]}
                                                </p>
                                                <h4 className="title">
                                                {stories?.title}
                                                    {/* Tips You Should Know When You Plan To
                                                    Date Online */}
                                                </h4>
                                                <p className="text">
                                                    {stories?.description}
                                                    {/* For in free time, when we have the option of choosing nothing hinders as little as possible what pleases him most */}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="post-footer">
                                            <div className="left">
                                                <a href="#" className="read-more-btn">{stories?.category}</a>
                                            </div>
                                            {/* <div className="right">
                                                <a href="#" className="read-more-btn">Continue Reading</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">


                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Blog_Header