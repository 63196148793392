import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Style/Cards/Card4.css';
import User_Card_Info2 from '../UserDetails/User_Card_Info2';

const Card4 = (params) => {
    const { value, user, mainValue, socket } = params;

    const [recievedRequests, setrecievedRequests] = useState([]);
    const [declinedRequests, setDeclinedRequests] = useState([]);

    useEffect(() => {
        console.log('MainValue changed:', mainValue);

        // Update recievedRequests and declinedRequests based on mainValue
        if (mainValue === 'oldest') {
            setrecievedRequests(user?.recievedRequests ? [...user.recievedRequests].reverse() : []);
            setDeclinedRequests(user?.declinedRequests ? [...user.declinedRequests].reverse() : []);
        } else if (mainValue === 'newest') {
            setrecievedRequests(user?.recievedRequests || []);
            setDeclinedRequests(user?.declinedRequests || []);
        }
        // Add conditions for other mainValue values if needed
    }, [mainValue, user]);

    let requestsToRender = [];
    if (value === 'recieve') {
        requestsToRender = mainValue === 'oldest' ? recievedRequests : [...recievedRequests].reverse();
    }

    return (
        value === 'recieve' && requestsToRender.length > 0 ?
            requestsToRender.map((ele, ind) => (
                <User_Card_Info2 socket={socket} user={user} ele={ele} key={ind} />
            ))
            : null
    );
};

export default Card4;
