import React, { useEffect, useState } from 'react'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Header from '../Components/Home/Header'
import Inbox_Navbar from './Inbox_Navbar'
import Card6 from '../Cards/Card6'
import Inbox_Sidebar from './Inbox_Sidebar'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Accepted_Messages = ({socket}) => {
  let [user, setUser] = useState(null)
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()

  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])

  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/User/${keyId}`)
      console.log('val.data', val.data)
      setUser(val.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
  }, [])

  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div>
          <Dashboard_Breadcrumb />
          <Header />
          <Inbox_Navbar />
          <div style={{ display: 'flex', margin: '1%' }}>
            <Inbox_Sidebar />
            <div>
              <Card6 socket={socket} user={user} value='accept' />
            </div>
          </div>

        </div>
        : navigate('/')
  )
}
export default Accepted_Messages