import React, { useState } from 'react';
import '../../../Style/Dashboard_Home/Dashboard_Referrals_Header.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard_Referrals_Header = () => {
  let [success, showSuccess] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});
  let validateForm = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    else if (!/^[a-zA-Z\s]+$/.test(formData.name)) {
      errors.name = 'Name should only contain alphabets';
    }
    if (!formData.email) {
      errors.email = 'email is required';
    }
    else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.refferedEmail) {
      errors.refferedEmail = 'Reffered email is required';
    }
    else if (!/\S+@\S+\.\S+/.test(formData.refferedEmail)) {
      errors.refferedEmail = 'Reffered email is invalid';
    }
    if (!formData.phone) {
      errors.phone = 'phone is required';
    }
    if (formData?.phone?.length > 15) {
      errors._id = 'Phone should be maximum 15';
    }
    if (!formData.message) {
      errors.message = 'message is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const showToast = () => {
    toast.success('Request successful!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('formData', formData)
    let isValid = validateForm()
    if (isValid) {
      try {
        let val = await fetch('/refferel', {
          method: "POST",
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify(formData)
          // body:formData
        })
        if (val.status === 200) {
          showToast();
          // alert('sucee')
        }
        else {
          toast.error('Request failed!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000 // Duration in milliseconds
          });
        }
      } catch (err) {

      }
    }

  };

  return (
    // <div className="Dashboard_Referrals_Header">
    // <div className="referral-form-container">
    //   <h2>Referrals Form</h2>
    //   <form onSubmit={handleSubmit}>
    // <label htmlFor="referredName">Name of Referred Person:</label>
    // <input
    //   type="text"
    //   name="referredName"
    //   id="referredName"
    //   value={formData.referredName}
    //   onChange={handleInputChange}
    //   required
    // />

    //     <label htmlFor="email">Email Address:</label>
    //     <inpu/t
    //       type="email"
    //       name="email"
    //       id="email"
    //       value={formData.email}
    //       onChange={handleInputChange}
    //       required
    //     />

    //     <label htmlFor="facebookLink">Facebook Link:</label>
    //     <inpu/t
    //       type="text"
    //       name="facebookLink"
    //       id="facebookLink"
    //       value={formData.facebookLink}
    //       onChange={handleInputChange}
    //     />

    //     <label htmlFor="whatsappNumber">WhatsApp Number:</label>
    //     <inpu/t
    //       type="tel"
    //       name="whatsappNumber"
    //       id="whatsappNumber"
    //       value={formData.whatsappNumber}
    //       onChange={handleInputChange}
    //     />

    //     <label htmlFor="message">Message:</label>
    //     <textarea
    //       name="message"
    //       id="message"
    //       value={formData.message}
    //       onChange={handleInputChange}
    //     />

    //     <button type="submit">Submit</button>
    //   </form>
    // </div>
    // </div>
    <>
      <ToastContainer />
      <div className="Dashboard_Referrals_Header">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-5 mr-auto">
                <h3 className="mb-3">Let's Reffer your friends</h3>
                <p>It is very important for the customer to pay attention to the adipiscing process. Refusal to follow, the other duties here will be easy, neither laborious nor particularly remunerative. Nor is the need to follow her freed from the great, but this is the inventor of the flight, who will explain the whole thing because none of us can overcome the pains of desire?</p>
                <p>It is as if the choice is never equaled by the multitude of praisers. The pleasures of those who accuse him, our chosen one born of blessed pain, just a choice which no one, who criticizes him from the whole, hinders from following the distinction.</p>
                <p>Some pain, the benefits of life pains I'll forgive the mistake! For it will happen to some of the elect that we are able to perform these duties with great pain. His choice repels pains and hinders them, in what way, but the very matter of things, but I will explain to the just one with easy flattery, the wise provide for the result? <a href="#">info@mywebsite.com</a></p>

              </div>

              <div className="col-md-6">
                <div className="box">
                  {/* <h3 className="heading">How Can We Help?</h3> */}
                  <form className="mb-5" method="post" id="contactForm" name="contactForm">
                    <div className="row">

                      <div className="col-md-6 form-group">
                        {/* <label for="name" className="col-form-label">Name *</label>
                      <input type="text" className="form-control" name="name" id="name" placeholder="Your name"/> */}
                        <label className="col-form-label" htmlFor="referredName">Name of Referred Person:</label>
                        <input
                          type="text"
                          name="name"
                          id="referredName"
                          placeholder='name'
                          className="form-control"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                        {formErrors.name && <p className="error">{formErrors.name}</p>}
                      </div>
                      <div className="col-md-6 form-group">
                        <label for="WhatsApp" className="col-form-label">WhatsApp Number</label>
                        <input required type="tel" className="form-control" onChange={handleInputChange} name="phone" id="organization" placeholder="whatsapp of referrel" />
                        {formErrors.phone && <p className="error">{formErrors.phone}</p>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label for="email" className="col-form-label">Email *</label>
                        <input required type="email" className="form-control" onChange={handleInputChange} name="email" id="email" placeholder="Your email address" />
                        {formErrors.email && <p className="error">{formErrors.email}</p>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label for="email" className="col-form-label">Person's Email *</label>
                        <input required type="email" className="form-control" onChange={handleInputChange} name="refferedEmail" id="email" placeholder="Your email address" />
                        {formErrors.refferedEmail && <p className="error">{formErrors.refferedEmail}</p>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label for="message" className="col-form-label">Message*</label>
                        <textarea required className="form-control" onChange={handleInputChange} name="message" id="message" cols="30" rows="7"></textarea>
                        {formErrors.message && <p className="error">{formErrors.message}</p>}
                      </div>
                    </div>
                    <div className="row mb-3">
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <input type="submit" onClick={handleSubmit} value="Send Message" className="custom-button btn btn-block btn-primary rounded-0 py-2 px-4" />
                        <span className="submitting"></span>
                      </div>
                    </div>
                  </form>

                  <div id="form-message-warning mt-4"></div>
                  <div id="form-message-success">
                    Your message was sent, thank you!
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Dashboard_Referrals_Header