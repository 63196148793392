import React, { useEffect, useState } from 'react'
import Notification_Header from '../Notification_Components/Notification_Header'
import Header from '../Components/Home/Header'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Notification_Navbar from '../Notification_Components/Notification_Navbar'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Notification_Home = (props) => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div>
        <Header/>
        <Dashboard_Breadcrumb/>
        {/* <Notification_Navbar/> */}
        <Notification_Header socket={props.socket} />
    </div>
    :navigate('/')
  )
}

export default Notification_Home