import React, { useEffect, useState } from 'react'
import Admin_Home_Nav from '../Admin_Home/Components/Admin_Home_Nav'
import Admin_Sidebar from '../Admin_Home/Components/Admin_Sidebar'
import Admin_Home_Header from '../Admin_Home/Components/Admin_Home_Header'
import Admin_Home_Revenue_Chart from '../Admin_Home/Components/Admin_Home_Revenue_Chart'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Admin_Topbar from '../Admin_Topbar'


const Admin_Home = () => {
  let [data, setData] = useState([])
  let fetchDetails = async () => {
    try {
      let val = await axios.get('/user')
      let val2 = []
      val?.data?.map((ele, ind) => {
        if (ele?.isAdmin === false) {
          val2.push(ele)
        }
      })
      setData(val2)
    }
    catch (err) {

    }
  }
  useEffect(() => {
    fetchDetails()
  }, [])

  let navigate = useNavigate()
  let [isAdmin, setIsAdmin] = useState()
  let [isStaff, setIsStaff] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let verify_Admin_And_Staff = async (keyId) => {
    try {
      let val = await axios.get(`/user/${keyId}`)
      console.log('val.data.isAdmin', val.data.isAdmin)
      console.log('val.data.isEmployee', val.data.isEmployee)
      setIsAdmin(val.data.isAdmin)
      setIsStaff(val.data.isEmployee)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    verify_Admin_And_Staff(keyId)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isAdmin || isStaff ?
        <div className='Admin_Home'>
          <div id="app">
            <div class="main-wrapper main-wrapper-1">
            
            
            <div class=""></div>
              <Admin_Topbar />
              <div className='hideElements'>
                <Admin_Sidebar data={data} />
              </div>
              {/* <!-- Main Content --> */}
              <div class="main-content">
                <section class="section">
                  <Admin_Home_Header data={data} />
                  {/* <Admin_Home_Revenue_Chart data={data} /> */}
                  {/* <div class="row d-flex">
                <Admin_Home_Chart1 />
                <Admin_home_Chart2 />
                <Admin_home_Chart3 />
              </div> */}
                  {/* <Admin_Home_Table /> */}
                  {/* <div class="row">
                <Admin_Home_Support_Tickets/>
                <Admin_Home_Payments/>
              </div> */}
                </section>
                {/* <Admin_Home_Settings/> */}
              </div>
              {/* <footer class="main-footer">
            <div class="footer-left">
              <a href="templateshub.net">Templateshub</a></a>
        </div>
        <div class="footer-right">
        </div>
      </footer> */}
            </div>
          </div >
        </div >
        : navigate('/login')
  )
}

export default Admin_Home