import React, { useEffect, useState } from 'react'
import Billing_Breadcrumb from '../Billing_Components/Billing_Breadcrumb'
import Header from '../Home/Header'
import Change_Password_Header from '../Change_Password_Components/Change_Password_Header'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Change_Password_Home = () => {
  let navigate=useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
    <div>
        <Header/>
        <Billing_Breadcrumb/>
        <Change_Password_Header/>
    </div>
    :
    navigate('/login')
  )
}

export default Change_Password_Home