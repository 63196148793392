import React, { useState } from 'react'
import '../../../Style/Dashboard_Home/Dashboard_MyPhotos_Display.css'

const MyPhotos_Display = () => {
  const [selectedOption, setSelectedOption] = useState('visibleToAll');
  const [inputValue, setInputValue] = useState({ 'displayOption': 'visibleToAll' });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  }
  let inputEvent = (e) => {
    console.log('e.target.name', e)
    console.log('e.target.name', e.target.name)
    console.log('e.target.value', e.target.value)
    setInputValue({ [e.target.name]: e.target.value })
  }
  let submitEvent = async (e) => {
    e.preventDefault()
    console.log('inputValue', inputValue)
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    try {
      let val = await fetch(`/privacy/${keyId}`, {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(inputValue)
      })
      if (val.status === 200) {
        alert('changes saved')
        let val2 = await val.json()
        console.log('val2', val2)
      }
      else {
        alert('error saving updates')
      }
    } catch (error) {

    }
  }
  return (
    <div className='MyPhotos_Display'>
      <div className="container">
        <h1>Choose Display Option</h1>
        <div className="image-container">
          <div className="image_original">
            <img style={{ height: '220px', backgroundColor: 'white', width: '250px', border: '1px solid', borderRadius: '5px' }} src="img/fawad4.png" alt="Original Image" />
          </div>
          <div className="image_blurred">
            <img style={{ height: '220px', backgroundColor: 'white', width: '250px', border: '1px solid', borderRadius: '5px' }} src="img/fawad3.png" alt="Original Image" />
          </div>
        </div>
        <form>
          <label htmlFor="displayOption">Display Option:</label>
          <select onChange={inputEvent} id="displayOption" name="displayOption" >
            <option value="visibleToAll">Visible to all Members</option>
            <option value="visibleToMembersILikeAndPremium">Visible to Members I like and to all Premium Members</option>
            <option value="Only Visible to Members I like">Only Visible to Members I like</option>
          </select>
          <button className='custom-button' type="submit" onClick={submitEvent}>Save my settings</button>
        </form>
      </div>
    </div>
  );
}

export default MyPhotos_Display