import React, { useEffect, useState } from 'react'
import Email_Alerts_Header from '../Email_Alerts_Components/Email_Alerts_Header'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Header from '../Components/Home/Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Email_Alerts_Header2 from '../Email_Alerts_Components/Email_Alerts_Header2'

const Email_Alerts = () => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div>
        <Header/>
        <Dashboard_Breadcrumb/>
        <Email_Alerts_Header2/>
    </div>
    :
    navigate('/')
  )
}

export default Email_Alerts