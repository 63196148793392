import React, { useState } from 'react'

const Change_Password_Header = () => {
    const [formErrors, setFormErrors] = useState({});
    const [state2, updateState2] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    let inputEvent2 = (e) => {
        updateState2({ ...state2, [e.target.name]: e.target.value });
    }
    let validateForm = () => {
        const errors = {};
        if (!state2?.currentPassword) {
            errors.currentPassword = 'Password is required';
        }
        if (!state2?.newPassword) {
            errors.newPassword = 'New Password is required';
        }
        if (state2?.newPassword?.length < 5) {
            errors.newPassword = 'password should be minimum 5 characters';
        }
        if (state2?.currentPassword===state2?.newPassword&&state2?.newPassword ) {
            errors.newPassword = 'old password can not be used';
        }
        console.log('errors',errors)
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }
    
    let submitEvent = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
        console.log('isValid',isValid)
        if(isValid)
        {
            try {
                
                let val = await fetch('/userPassword', {
                    method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(state2)
            })
            if(val.status===200)
            {
                alert('Password updated sucessfully')
                window.location.reload()
            }
            else if(val.status===402)
            {
                alert('Password not matching!!')
            }
            else if(val.status===403)
            {
                alert('current password is incorrect!!')
            }
            else
            {
                alert('Error while updating!!')
            }
        } catch (error) {
            alert(error)
            
        }
        }
        else{

        }
    }
   
    return (
        <div>
            <section className="user-setting-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-5">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <button className="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className="icon">
                                                <i className="fas fa-user"></i>
                                            </div>
                                            <span>My Profile</span>
                                            <div className="t-icon">
                                                <i className="fas fa-plus"></i>
                                                <i className="fas fa-minus"></i>
                                            </div>
                                        </button>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul className="links">
                                                <li>
                                                    <a href="/DashboardProfile">Profile Info</a>
                                                </li>
                                                <li>
                                                    <a href="/notification">Notifications</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <button className="collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div className="icon">
                                                <i className="fas fa-cogs"></i>
                                            </div>
                                            <span>
                                                Account
                                            </span>
                                            <div className="t-icon">
                                                <i className="fas fa-plus"></i>
                                                <i className="fas fa-minus"></i>
                                            </div>
                                        </button>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul className="links">
                                                {/* <li>
                                                    <a href="user-account-info.html">Account Info</a>
                                                </li> */}
                                                <li>
                                                    <a href="/change-password">Change Password</a>
                                                </li>
                                                {/* <li>
                                                    <a href="#">Privacy Settings</a>
                                                </li> */}
                                                {/* <li>
                                                    <a href="user-verify-account.html">Verified account</a>
                                                </li>
                                                <li>
                                                    <a href="user-close-account.html">Close Account</a>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <button className="collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div className="icon">
                                                <i className="far fa-credit-card"></i>
                                            </div>
                                            <span>
                                                Subscriptions & Payments
                                            </span>
                                            <div className="t-icon">
                                                <i className="fas fa-plus"></i>
                                                <i className="fas fa-minus"></i>
                                            </div>
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul className="links">
                                                {/* <li>
                                                    <a  href="/billing">Billing & Payout</a>
                                                </li> */}
                                                <li>
                                                    <a href="/allplan">Upgrade Membership Plan</a>
                                                </li>
                                                <li>
                                                    <a className="active" href="/purchaseHistory">View Purchase History</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-md-7 ">
                            <div className="page-title">
                                Change Password
                            </div>
                            <div className="input-info-box mt-30">
                                <div className="my-table">
                                    <div className="table-filter">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="my-input-box">
                                                    <label for="">Current Password</label>
                                                    <input name="currentPassword" onChange={inputEvent2} type="password" />
                                                    {formErrors.currentPassword && <p className="error">{formErrors.currentPassword}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="my-input-box">
                                                    <label htmlFor="password">New Password</label>
                                                    <div style={{ position: 'relative' }}>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            id="password"
                                                            name="newPassword"
                                                            // name="password"
                                                            placeholder="Enter your password"
                                                            onChange={inputEvent2}
                                                        />
                                                        <span
                                                            onClick={togglePasswordVisibility}
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            {showPassword ? (
                                                                <i className="far fa-eye-slash"></i> // Use your eye icon here
                                                            ) : (
                                                                <i className="far fa-eye"></i> // Use your eye icon here
                                                            )}
                                                        </span>
                                                    </div>
                                                        {formErrors.newPassword && <p className="error">{formErrors.newPassword}</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <button onClick={submitEvent} className="custom-button">Save changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="buttons  mt-30">
                                <button type="submit" className="custom-button">Submit</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Change_Password_Header