import React, { useEffect, useState } from 'react'
import Admin_Sidebar from '../Admin_Home/Components/Admin_Sidebar'
import Admin_Contact_Table from '../Admin__Contact/Components/Admin_Contact_Table'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Admin_Topbar from '../Admin_Topbar'

const Admin_Contact = (props) => {
    let [plans, updatePlans] = useState([])
    let allPlans = async () => {
        try {
            let val = await axios.get('/contact');
            let val2 = []
            console.log('val', val)
            val?.data?.map((ele, ind) => {
                if (ele?.reply === null) {
                    val2.push(ele);
                }
            })
            console.log('val2', val2)
            updatePlans(val2)
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        allPlans()
    }, [])
    let navigate = useNavigate()
    let [isAdmin, setIsAdmin] = useState()
    let [isStaff, setIsStaff] = useState()
    let [loading, setLoading] = useState(true)
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let verify_Admin_And_Staff = async (keyId) => {
        try {
            let val = await axios.get(`/user/${keyId}`)
            console.log('val.data.isAdmin', val.data.isAdmin)
            console.log('val.data.isEmployee', val.data.isEmployee)
            setIsAdmin(val.data.isAdmin)
            setIsStaff(val.data.isEmployee)
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
            console.log(err)
        }
    }
    useEffect(() => {
        verify_Admin_And_Staff(keyId)
    }, [])
    return (
        loading ?
            (
                <Loader />
            )
            :
            isAdmin || isStaff ?
                <div className='Admin_Home'>
                    <div id="app">
                        <div class="main-wrapper main-wrapper-1">
                            <div class=""></div>
                            <Admin_Topbar />
                            <div className='hideElements'>
                                <Admin_Sidebar />
                            </div>
                            <div class="main-content">
                                <section class="section">
                                    <Admin_Contact_Table data={plans} socket={props?.socket} />
                                </section>
                            </div>
                        </div>
                    </div >
                </div >
                : navigate('/')
    )
}

export default Admin_Contact