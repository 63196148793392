import axios from 'axios';
import React, { useEffect, useState } from 'react'
const Dashboard_Card_1 = (params) => {
    let user = params.user
    const [allPlans, setAllPlans] = useState(null);
    const [trialPeriodDays, setTrialPeriodDays] = useState(null);
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let fetchPlans = async (category) => {
        // let val=await axios.get(`/allPlanss/${category}`)
        try {
            let keyId = JSON.parse(localStorage.getItem('keyId'))
            console.log('keyId', keyId)
            let val = await axios.get(`/membership-plan/${keyId}`)
            // let val = await axios.get(`/membership-plan/6576963f966331254ce074ff`)
            console.log('all plans val.data', val.data)
            setAllPlans(val.data)
        }
        catch (err) {
            console.log('err', err)
        }
    }
    useEffect(() => {
        fetchPlans()
    }, [])
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        const year = dateObject.getFullYear();
        const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
        const day = ('0' + dateObject.getDate()).slice(-2);

        // Construct the formatted date string
        const formattedDate = `${year}-${month}-${day}`;

        // Log the formatted date
        console.log('Formatted Date:', formattedDate);
        return formattedDate;
    }
    return (
        <div>
            <div className="card" style={{ "width": "16rem" }}>
                {/* <img src="img/fawad.png" style={{  margin: 'auto' }} className="card-img-top" alt="..." /> */}
                {/* <img src={user?.photos[0] || 'img/default_man.jpg'} style={{ margin: 'auto' }} className="card-img-top" alt="..." /> */}
                {
                    user?.gender === 'male' ?
                        user?.photos && user?.photos?.length !== 0 ?
                            <img style={{ height: "250px", width: "248px", margin: 'auto' }} className="card-img-top" src={`uploads/${user?.photos[0]}`} alt="" />
                            :
                            <img style={{ margin: 'auto' }} className="card-img-top" src='img/default_man.jpg' alt="" />
                        :
                        user?.photos && user?.photos?.length !== 0 ?
                            <img style={{ height: "250px", width: "248px", margin: 'auto' }} className="card-img-top" src={`uploads/${user?.photos[0]}`} alt="" />
                            :
                            <img style={{ margin: 'auto' }} className="card-img-top" src='img/default_woman.jpg' alt="" />
                }
                <div className="card-body d-flex" style={{ justifyContent: 'space-between', alignContent: "center", alignItems: "end" }} >
                    <div>
                        <p style={{ margin: "0%", padding: "0%" }} className="card-title">
                            <b>{user?.name}</b>
                            <br />
                        </p>
                        {/* <p style={{margin:"0%",padding:"0%"}} className="">SH46046288</p> */}
                        <p style={{ margin: "0%", padding: "0%" }} className="">{user?.email}</p>
                    </div>
                    <a href="/userpanel" className=" ">Edit</a>
                </div>
                <div className="card-body d-flex" style={{ justifyContent: 'space-between', alignContent: "center", alignItems: "end" }} >
                    <div>
                        <p style={{ margin: "0%", padding: "0%" }} className="card-title">Account Type</p>
                        {
                            user?.accountType !== 'free' && user?.isActive !== 'false' ?
                                <p style={{ margin: "0%", padding: "0%" }} className="card-text"><b> Premium Membership</b></p>
                                :
                                user?.accountType == 'welcome' ?
                                    <p style={{ margin: "0%", padding: "0%" }} className="card-text"><b> Welcome Plan</b></p>
                                    :
                                    <p style={{ margin: "0%", padding: "0%" }} className="card-text"><b> Free Membership</b></p>}
                    </div>
                    <a href="/allplan" className="">Upgrade</a>
                </div>
                <div className="card-body d-flex" style={{ justifyContent: 'space-between', alignContent: "center", alignItems: "center" }} >
                <div>
                    {allPlans && allPlans?.map((ele, ind) => {
                        return (
                            <>
                                <p style={{ margin: "0%", padding: "0%" }} className="card-text"><b> {ele?.plan}</b></p>
                                <a href="#" className="">Expiry date: {setDates(ele?.updatedAt)?.toString()?.split('T')[0]}</a>
                            </>
                        )
                    })}
                </div>
                    {/* <div>
                        <p style={{margin:"0%",padding:"0%"}} className="card-title">Mobile no. is verified</p>
                        <p style={{margin:"0%",padding:"0%"}} className="card-text"> <b> Verify your ID</b></p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Dashboard_Card_1