import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import User_Info from '../../../UserDetails/User_Info';
const Admin_Package_Payment_Table = ({ data }) => {
    let params = useParams();
    let navigate = useNavigate();
    let member_link = JSON.parse(localStorage.getItem('member_link'));
    console.log('member_link', member_link)
    console.log('params', params)
    console.log('data here')
    console.log('data here', data)
    let [fetchedData, setFetchedData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [userPlan, setUserPlan] = useState({});
    const [newData, setNewData] = useState([]);
    const handleClose = () => setShowModal(false);
    const handleClose2 = () => setShowDropdown(false);
    const handleClose3 = () => setShowNote(false);
    const handleShow = (ele) => {
        setShowModal(true)
        setFetchedData(ele)
    };
    let deleteAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/allPlans/${id}`,
                    {
                        method: "Delete"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) {

            }
        }
    }
    const handleEdit = (fetchedData) => {
        setShowDropdown(true); // Show the dropdown when the button is clicked
        setUserPlan(fetchedData)
    };
    const addNote = (fetchedData) => {
        setShowNote(true); // Show the dropdown when the button is clicked
    };

    let changePlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            setUserPlan({ ...userPlan, [e.target.name]: value })
        }
        else {
            setUserPlan({ ...userPlan, [e.target.name]: e.target.value })
        }
    }
    let submitPlan = async (e) => {
        e.preventDefault()
        console.log(userPlan)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/allPlans/${fetchedData?._id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(userPlan)
            })
            if (val.status === 200) {
                alert('changes updated!')
                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (err) { }
    }

    let newDataPlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            console.log('balue', value)
            setNewData({ ...newData, [e.target.name]: value })
        }
        else {
            setNewData({ ...newData, [e.target.name]: e.target.value })
        }
    }
    let submitNewDataPlan = async (e) => {
        e.preventDefault()
        // console.log('notePlan', notePlan)
        console.log('submitNewDataPlan', newData)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/allPlans`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(newData)
            })
            if (val.status === 200) {
                alert('new plan added!')
                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (err) { }
    }
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    const objectToCsv = (data) => {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    const downloadCSV = () => {
        const csvContent = objectToCsv(data);

        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
    };
    return (
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            {/* <h4>Plans</h4> */}
                            <div class="card-header-form">
                                {/* <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" />
                                        <div class="input-group-btn">
                                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        {/* <Button className="custom-button w-25 mx-auto mt-1 " style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                            Create Plan
                        </Button> */}
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tr>
                                        {/* <th class="text-center">
                                            <div class="custom-checkbox custom-checkbox-table custom-control">
                                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad"
                                                    class="custom-control-input" id="checkbox-all" />
                                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                                            </div>
                                        </th> */}
                                        {/* <th>LV Number</th>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th>Date Registered</th>
                                        <th>Expiry Date</th>
                                        <th>Status</th>
                                        <th>Email Address</th> */}
                                        <th>LV number</th>
                                        <th>Name</th>
                                        <th>Package</th>
                                        <th>Payment</th>
                                        <th>Amount</th>
                                        <th>Invoice</th>
                                        <th>Date</th>
                                        {/* <th>Actions</th> */}
                                    </tr>
                                    {/* {member_link?.map((ele, ind) => { */}
                                    {/* free?.map((ele, ind) => { */}

                                    {
                                        data?.map((ele, ind) => {
                                            return (
                                                <tr>
                                                    {/* <td class="p-0 text-center">
                                                        <div class="custom-checkbox custom-control">
                                                            <input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
                                                                id="checkbox-1" />
                                                            <label for="checkbox-1" class="custom-control-label">&nbsp;</label>
                                                        </div>
                                                    </td> */}
                                                    <td>{ele?._id}</td>
                                                    <td class="text-truncate">
                                                        {/* {ele?.name} */}
                                                        <User_Info title='name' id={ele?.userId} />
                                                    </td>
                                                    <td class="align-middle">
                                                        {ele?.plan}
                                                    </td>
                                                    <td class="align-middle">
                                                        {ele?.paymentMethod}
                                                    </td>
                                                    <td class="align-middle">
                                                        {ele?.price}
                                                    </td>
                                                    <td class="align-middle">
                                                        {ele?.invoiceNum}
                                                    </td>
                                                    <td class="align-middle">
                                                        <td>{setDates(ele?.createdAt)?.toString()?.split('T')[0]}</td>
                                                    </td>
                                                    {/* <td>
                                                        <a href="#" className="btn btn-outline-primary" onClick={() => handleShow(ele)}>
                                                            Detail
                                                        </a>
                                                    </td> */}
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                                <div style={{textAlign:'center'}}>
                                <button className='custom-button ' style={{margin:'2% auto',textAlign:'center'}} onClick={downloadCSV}>Generate CSV</button>
                                </div>
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Actions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* <Button className="" variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => {
                                            // console.log('fetchedData', fetchedData)
                                            // localStorage.setItem('singleProfileId', JSON.stringify(fetchedData?._id))
                                            navigate('/allplan')
                                        }}>
                                            View Plan
                                        </Button>
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => blockAccount(fetchedData?._id)}>
                                            Block Account
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => deleteAccount(fetchedData?._id)}>
                                            Delete Plan
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit(fetchedData) }}>
                                            Change Plan
                                        </Button>
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                                            Create Plan
                                        </Button> */}


                                        {/* {member_link==='deleted'?
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { reactivateAccount(fetchedData) }}>
                                            Reactivate Account
                                        </Button>
                                        :''} */}
                                        {/* {member_link==='blocked'?
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { unblockAccount(fetchedData) }}>
                                            Unblock Account
                                        </Button>
                                        :''} */}
                                        {/* Add more options as needed */}
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showDropdown} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Select a Plan</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Edit plan:</Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Name</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="name" id="" value={userPlan?.name} />
                                                <label for="name" class="">Price</label>
                                                <input onChange={changePlan} type="number" className='form-control' name="price" id="" value={userPlan?.price} />
                                                <label for="name" class="">Limit in months</label>
                                                <input onChange={changePlan} type="number" className='form-control' name="time_period_months" id="" value={userPlan?.time_period_months} />
                                                <label for="name" class="">Limit in Days</label>
                                                <input onChange={changePlan} type="number" className='form-control' name="time_period_days" id="" value={userPlan?.time_period_days} />
                                                <label for="name" class="">Categories</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="category" id="" value={userPlan?.category} />
                                                <label for="name" class="">Features</label>
                                                {/* <input onChange={changePlan}  type="text" className='form-control' name="features" id="" value={userPlan?.features} /> */}
                                                <textarea onChange={changePlan} className='form-control' name="features" id="" cols="30" rows="3" value={userPlan?.features}></textarea>
                                                {/* </FormControl> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create new Plan</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            {/* <Form.Group> */}
                                            {/* <Form.Label>Select a plan:</Form.Label> */}
                                            {/* <FormControl   as="select" multiple> */}
                                            {/* <textarea name="description" onChange={changeNote} value={notes?.description}>{notes?.description}</textarea> */}
                                            {/* </FormControl> */}
                                            {/* </Form.Group> */}
                                            <Form.Group>
                                                <Form.Label>Create:</Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Name</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="name" id="" />
                                                <label for="name" class="">Price</label>
                                                <input onChange={newDataPlan} type="number" className='form-control' name="price" id="" />
                                                <label for="name" class="">Limit in months</label>
                                                <input onChange={newDataPlan} type="number" className='form-control' name="time_period_months" id="" />
                                                <label for="name" class="">Limit in Days</label>
                                                <input onChange={newDataPlan} type="number" className='form-control' name="time_period_days" id="" />
                                                <label for="name" class="">Categories</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="category" id="" />
                                                <label for="name" class="">Features</label>
                                                <p>You can add multiple features by separating with commas</p>
                                                {/* <input onChange={changePlan}  type="text" className='form-control' name="features" id="" value={userPlan?.features} /> */}
                                                <textarea onChange={newDataPlan} className='form-control' name="features" id="" cols="30" rows="3" ></textarea>
                                                {/* </FormControl> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="custom-button" variant="secondary" onClick={submitNewDataPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Package_Payment_Table