import React from 'react'
import Membership_Plan from '../../Plans_Components/Membership_Plan'
import Profile_Booster_Plan from '../../Plans_Components/Profile_Booster_Plan'
import View_contact_Plan from '../../Plans_Components/View_contact_Plan'
import Block_Requests_Plan from '../../Plans_Components/Block_Requests_Plan'

const Memebership_Header = ({socket}) => {
    return (
        <div>
            <section className="membership-section">

                <div className="row m-s-top">
                    <div className="col-lg-3 col-md-6">
                        <div className="plan-info">
                            <div className="icon">
                                <img src="assets/images/membership/icon1.png" alt="" />
                            </div>
                            <h4 className="title">
                                Unlimited Messages
                            </h4>
                            <p className="text">
                                Send and receive messages
                                with no limits
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="plan-info">
                            <div className="icon">
                                <img src="assets/images/membership/icon2.png" alt="" />
                            </div>
                            <h4 className="title">
                                View Your Contacts
                            </h4>
                            {/* <br /> */}
                            <p className="text">
                                View info of Mutual Likes
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="plan-info">
                            <div className="icon">
                                <img src="assets/images/membership/icon3.png" alt="" />
                            </div>
                            <h4 className="title">
                                Add Some short videos
                            </h4>
                            {/* <br />
                            <br /> */}
                            <p className="text">
                                Add videos
                                with no limits
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="plan-info">
                            <div className="icon">
                                <img src="assets/images/membership/icon4.png" alt="" />
                            </div>
                            <h4 className="title">
                                View & Reply to Chat
                            </h4>
                            <p className="text">
                                Read and reply to
                                the messages
                            </p>
                        </div>
                    </div>
                </div>

                <Membership_Plan socket={socket} />

                {/* <Profile_Booster_Plan /> */}
                {/* <View_contact_Plan /> */}
                {/* <Block_Requests_Plan /> */}

                <div className="pricing-plans">
                    <img className="shape1" src="assets/images/join/heartshape.png" alt="" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="contact-link">
                                    If you have any questions <a href="#">Contact Us</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Memebership_Header