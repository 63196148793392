import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'

const User_Info_For_Some_Images = (props) => {
    console.log('username2 props', props);
    let [state, updateState] = useState([])
    let [mainUser, setMainUser] = useState([])
    let [privacyState, updatePrivacyState] = useState([])
    // let [state2,updateState2]=useState()
    let fetchUser = async () => {
        console.log('fetchomh', props?.id);
        let propsId = props?.id;
        try{
        let val = await axios.get(`/User/${propsId}`)
        console.log('some val?.data', val?.data);
        updateState(val?.data)
        console.log('props.title', props?.title)
        console.log('state[props.title]', state[props?.title])}
        catch(err){
            
        }
    }
    useEffect(() => {
        if (props?.title && props?.id) {
            fetchUser();
        }
    }, [])
    let fetchMainUser = async (keyId) => {
        try {
            let val = await axios.get(`/user/${keyId}`)
            console.log('val.data', val.data)
            setMainUser(val?.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let keyId = JSON.parse(localStorage.getItem('keyId'));
        fetchMainUser(keyId)
    }, [])
    let fetchPrivacy = async (id, title) => {
        try {
            console.log('fetchomh', props);
            let propsId = id;
            let val = await axios.get(`/privacy/${propsId}`)
            console.log('privacy data here', val?.data);
            console.log('val?.data[title]', val?.data[title]);
            updatePrivacyState(val?.data)
            return val?.data[title]
        } catch (error) {
            console.log('error', error)

        }
    }
    useEffect(() => {
        // Call the fetchPrivacy function when the component mounts
        fetchPrivacy(props?.id, 'photo');
    }, []);
    
    return (
        <>
            {/* <img src={state?.photos[0] || "img/default_man.jpg" } style={{ height: "190px", width: '240px' }} className="card-img-top" alt="..." /> */}
            {/* <img style={{ height: "190px", width: '240px' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" /> */}
            {
                state.length !== 0 ?
                    props?.title === 'photos' ?
                        privacyState ? (
                            privacyState?.photo === 'all members'
                                ?
                                state?.gender === 'male' ?
                                    state?.photos && state?.photos?.length !== 0 ?
                                        <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" />
                                        :
                                        <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`img/default_man.jpg`} alt="" />
                                    :
                                    state?.photos && state?.photos?.length !== 0 ?
                                        <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" />
                                        :
                                        <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`img/default_woman.jpg`} alt="" />
                                :
                                privacyState?.photo === 'only premium members and members you like'
                                    ?
                                    mainUser?.accountType === 'premium' || mainUser?.recievedRequests?.includes(props.id).toString() === 'true' || mainUser?.friends?.includes(props.id).toString() === 'true' ?
                                        state?.gender === 'male' ?
                                            state?.photos && state?.photos?.length !== 0 ?
                                                <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" />
                                                :
                                                <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`img/default_man.jpg`} alt="" />
                                            :
                                            state?.photos && state?.photos?.length !== 0 ?
                                                <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" />
                                                :
                                                <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`img/default_woman.jpg`} alt="" />
                                        :
                                        state?.gender === 'male' ?
                                            'only premium members and members liked by him can view image'
                                            :
                                            'only premium members and members liked by her can view image'

                                    :
                                    privacyState?.photo === 'only members you like'
                                        ?
                                        mainUser?.recievedRequests?.includes(props.id).toString() === 'true' || mainUser?.friends?.includes(props.id).toString() === 'true' ?
                                            state?.gender === 'male' ?
                                                state?.photos && state?.photos?.length !== 0 ?
                                                    <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" />
                                                    :
                                                    <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`img/default_man.jpg`} alt="" />
                                                :
                                                state?.photos && state?.photos?.length !== 0 ?
                                                    <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`uploads/${state?.photos[0]}`} alt="" />
                                                    :
                                                    <img style={{ height: "100px", width: '100px', borderRadius: '200%' }} className="card-img-top" src={`img/default_woman.jpg`} alt="" />
                                            :
                                            state?.gender === 'male' ?
                                                'only members liked by him can view image'
                                                :
                                                'only members liked by her can view image'
                                        :
                                        'Image Not Added'
                        ) : (
                            <p>Loading...</p>
                        )
                        :
                        state[props?.title] !== undefined ?
                            state[props?.title]
                            : ''
                    : ''
            }
        </>
    )
}

export default User_Info_For_Some_Images