import React, { useEffect, useState } from 'react'
import Profile_Single_Breadcrumb from '../Components/Profile_Single/Profile_Single_Breadcrumb'
import Profile_Single_Header from '../Components/Profile_Single/Profile_Single_Header'
import Newsletter from '../Components/Home/Newsletter'
import Header from '../Components/Home/Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Blocked_By_Other from '../Components/Error/Blocked_By_Other'
import Blocked_By_You from '../Components/Error/Blocked_By_You'

const Profile_Single = ({ socket }) => {
  let navigate = useNavigate()
  let [otherUser, setOtherUser] = useState()
  let [mainUser, setMainUser] = useState({})
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let singleProfileId = JSON.parse(localStorage.getItem('singleProfileId'))
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    setIsUser(keyId)
  }, [])

  let fetchMainUser = async () => {
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    try {
      let val = await axios.get(`/user/${keyId}`)
      setMainUser(val.data)
    } catch (error) {
    }
  }
  useEffect(() => {
    fetchMainUser()
  }, [])
  let fetchOtherUser = async () => {
    let keyId = JSON.parse(localStorage.getItem('singleProfileId'))
    try {
      let val = await axios.get(`/user/${keyId}`)
      setOtherUser(val.data)
    } catch (error) {
    }
  }
  useEffect(() => {
    fetchOtherUser()
  }, [])

  let sentNotification = async (keyId, singleProfileId) => {
    try {
      let val = await axios.get(`/user/${singleProfileId}`)
      if (val.status === 200) {
        console.log('val.data?.visitors?.includes(keyId)', val.data?.visitors?.includes(keyId))
        if (!val.data?.visitors?.includes(keyId)) {
          console.log('val.data?.visitors?.includes(keyId) in', val.data?.visitors?.includes(keyId))
          try {
            let val2 = await axios.get(`/user-simple/user-simple/${singleProfileId}`);
            console.log('val.data?.visitors?.includes(keyId) here', val2)
            console.log('val.data?.visitors?.includes(keyId)', val2.data)
            if (val2.status === 200) {
              let date = new Date()
              date = date.toLocaleTimeString()
              let keyId = JSON.parse(localStorage.getItem('keyId'))
              socket?.emit("sendNotification", {
                sender: keyId,
                receiverName: singleProfileId,
                receiverId: singleProfileId,
                type: 'visitor',
                date: date
              });
            }
          } catch (error) {

          }
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let singleProfileId = JSON.parse(localStorage.getItem('singleProfileId'))
    if (keyId !== singleProfileId) {
      sentNotification(keyId, singleProfileId)
    }
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        otherUser?.blockedUsers?.includes(keyId) ?
          <div>
            <Header />
            <Profile_Single_Breadcrumb />
            <Blocked_By_Other />
            <Newsletter />
          </div>
          :
          mainUser?.blockedUsers?.includes(singleProfileId) ?
            <div>
              <Header />
              <Profile_Single_Breadcrumb />
              <Blocked_By_You id={singleProfileId} />
              <Newsletter />
            </div> :
            <div>
              <Header />
              <Profile_Single_Breadcrumb />
              <Profile_Single_Header />
              <Newsletter />
            </div>

        : navigate('/login')
  )
}

export default Profile_Single