import React, { useEffect, useState } from 'react'
import Privacy_Policy_Header from '../Privacy_Policy_Components/Privacy_Policy_Header'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Header from '../Components/Home/Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Privacy_Policy_Header2 from '../Privacy_Policy_Components/Privacy_Policy_Header2'

const Privacy_Policy = () => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div>
        <Dashboard_Breadcrumb/>
        <Header/>
        <Privacy_Policy_Header2/>
    </div>
    :navigate('/')
  )
}

export default Privacy_Policy