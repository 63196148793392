import React, { useEffect, useState } from 'react'
import DashboardMyContact_Header from '../Dashboard/Components/MyContact/DashboardMyContact_Header'
import Header from '../Components/Home/Header'
import Dashboard_Breadcrumb from './Dashboard_Breadcrumb'
import Dashboard_Nav from '../Dashboard/Components/Navbar/Dashboard_Nav'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'

const DashboardMyContact = () => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div>
          <Header />
          <Dashboard_Breadcrumb />
          <Dashboard_Nav />
          <DashboardMyContact_Header />
        </div>
        : navigate('/')
  )
}

export default DashboardMyContact