import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const Admin_Tickets_Nav = () => {
    const location = useLocation();

    return (
        // bg-primary
        <div className='Dashboard_Nav '>
            <nav className="navbar navbar-expand-lg mini_Header" style={{ backgroundColor: "#ff4d4d" }}>
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link onClick={()=>{localStorage.setItem('ticket_link',JSON.stringify('open'))}} to="/admin-tickets-open" className={`nav-link text-white ${location.pathname === '/admin-tickets-open' ? 'active' : ''}`}>Open Tickets</Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={()=>{localStorage.setItem('ticket_link',JSON.stringify('closed'))}} to="/admin-tickets-close" className={`nav-link text-white ${location.pathname === '/admin-tickets-close' ? 'active' : ''}`}>Closed Tickets</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Admin_Tickets_Nav;
