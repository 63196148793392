import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import User_Info from './User_Info';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Privacy_Check_Header from '../Privacy_Check_Components/Privacy_Check_Header';
import axios from 'axios';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';


const User_Card_Info4 = (params) => {
    let ele = params.ele
    let mainUser = params.mainUser
    let socket = params.socket
    let onlineUsers = params.onlineUsers
    let previousActive = params.previousActive
    // console.log('previousActive', previousActive)
    // console.log('onlineUsers', onlineUsers)
    // console.log('socket', socket)
    console.log('mainUser hun vro', mainUser)
    console.log('mainUser hun vro check kr',mainUser && !mainUser?.sentRequests?.includes(ele._id) && !mainUser?.rejectedByReciever?.includes(ele._id) && !mainUser.friends?.includes(ele._id) && !mainUser.recievedRequests?.includes(ele._id))
    const images = [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
    ];
    const [showNote, setShowNote] = useState(false);
    let handleClose = () => { setShowNote(false) }
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    const [message, sentMessage] = useState({ content: '', sender: keyId });
    const [selectedSlide, setSelectedSlide] = useState(0);
    let [privacyState, updatePrivacyState] = useState([])
    let [membership, setMembership] = useState([])
    let [contactView, setContactView] = useState(false)
    let [contactView2, setContactView2] = useState(false)
    let [contactDetailSum, setContactDetailSum] = useState()
    let [overallSum, setOverallSum] = useState()
    const handleSlideChange = (index) => {
        setSelectedSlide(index);
    };
    let navigate = useNavigate()
    const showToast = () => {
        toast.success('Request Successful!!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };
    let inputEvent = (e) => {
        // console.log(e.target.name, e.target.value)
        sentMessage({ ...message, [e.target.name]: e.target.value })
    }
    let submitEvent = async (e) => {
        try {
            e.preventDefault();
            // let receiver=JSON.parse(localStorage.getItem('receiverId'));
            // console.log('message', message)
            let val = await fetch('/chat', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(message)
            })
            if (val.status === 200) {
                // fetchChat()
                alert('sent')
                let date = new Date()
                date = date.toLocaleTimeString()
                // console.log('keyId in chatHeader', keyId)
                // console.log('keyId in chatHeader', message.receiver)
                // console.log('content in chatHeader', message?.content)
                // console.log('chatId in chatHeader', selectedChat?._id)
                socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: message.receiver,
                    receiverId: message.receiver,
                    type: 'message initiation',
                    content: message?.content,
                    // chatId: selectedChat?._id,
                    date: date
                });
            } else {
                alert('err')
            }
        } catch (error) {

        }

    }

    let connected = async (e, id) => {
        e.preventDefault()
        // alert('clicked')
        // console.log(id)
        try {

            let val = await fetch(`add/${id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                }
            })
            if (val.status === 200) {
                let date = new Date()
                date = date.toLocaleTimeString()
                let keyId = JSON.parse(localStorage.getItem('keyId'))
                // console.log('sending soon', socket)
                socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: id,
                    receiverId: id,
                    type: 'sent_Request',
                    date: date
                });
                // try {
                //     let val = await fetch(`/sent-email-notificaion/sent-email-notificaion`, {
                //         method: "PUT",
                //         headers: {
                //             "content-type": "application/json"
                //         },
                //         body: JSON.stringify({
                //             sender: keyId,
                //             receiverName: id,
                //             receiverId: id,
                //             type: 'sent_Request',
                //             date: date
                //         })
                //     }

                //     )
                // }
                // catch (err) { }
                showToast()
                setTimeout(() => {
                    window.location.reload();
                    // navigate('/inboxSent')
                }, 1940);
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }

        } catch (error) {
            // console.log('error', error)
        }
    }

    let nextPage = async (e, id) => {
        // console.log('e', e.target)
        // console.log('id', id)
        localStorage.setItem('singleProfileId', JSON.stringify(id))
        navigate('/singleprofile')
    }
    let fetchPrivacy = async (id, title) => {
        try {
            // console.log('fetchomh', params);
            let paramsId = id;
            let val = await axios.get(`/privacy/${paramsId}`)
            // console.log('privacy data here', val?.data);
            // console.log('val?.data[title]', val?.data[title]);
            updatePrivacyState(val?.data)
            return val?.data[title]
        } catch (error) {
            // console.log('error', error)

        }
    }
    useEffect(() => {
        // Call the fetchPrivacy function when the component mounts
        fetchPrivacy(ele?._id, 'photo');
    }, []);
    let openChat = async (id) => {
        // console.log('id',id)
        if (mainUser?.accountType === 'free' || mainUser?.isActive == 'false') {
            navigate('/allplan')
        }
        else if (mainUser?.accountType !== 'free' || mainUser?.isActive == 'true') {
            setShowNote(true)
            let keyId = JSON.parse(localStorage.getItem('keyId'));
            sentMessage({ ...message, receiver: id })
            // localStorage.setItem('receiverId', JSON.stringify('receiverId'));
            // navigate('/new-chat')
            // let val=await axios.get(``)
        }
    }

    let fetchMembership = async (keyId) => {
        try {
            let val = await axios.get(`/membership-plan/${keyId}`)
             {console.log('hey i am a val here', val)} 
             {console.log('hey i am a val here', val.data)} 
            val?.data?.map((ele, ind) => {
                return (
                    <>
                        {fetchEachPlan(ele)}
                    </>
                )
            })
            setMembership(val.data)
        }
        catch (err) { }

    }
    function getViewContactInfoLimit(features) {
        const viewContactInfoFeature = features.find(feature => feature.includes('Send Interests'));
        if (viewContactInfoFeature) {
            // Extracting the numeric limit
            const limit = viewContactInfoFeature.match(/\d+/);
            if (limit) {
                return parseInt(limit[0]); // Convert the extracted limit to a number
            }
        }
        return null; // Return null if the limit is not found
    }
    let contactsSum = 0;
    let contactsSum2 = 0;
    let fetchEachPlan = async (plan) => {
        try {
            let val = await axios.get(`/allPlansss/${plan.plan}`)
            if (plan.isRenew == false) {
                const viewContactInfoLimit = getViewContactInfoLimit(val.data?.features)
                if (viewContactInfoLimit !== null) {
                    contactsSum = contactsSum + viewContactInfoLimit;
                }
                console.log('ele4 viewContactInfoLimit', viewContactInfoLimit)
                console.log('ele4 contactsSum', contactsSum)
                setContactView2(true)
                setContactDetailSum(contactsSum)
            }
            else {
                const viewContactInfoLimit = getViewContactInfoLimit(val.data?.features)
                if (viewContactInfoLimit !== null) {
                    contactsSum2 = contactsSum2 + viewContactInfoLimit;
                }
                // console.log('viewContactInfoLimit', viewContactInfoLimit)
                // console.log('contactsSum2', contactsSum2)
                // setContactView2(true)
                // setContactDetailSum(contactsSum2)
                for (let index = 0; index < plan.renewTimes; index++) {
                    contactsSum2 += contactsSum2;

                }
            }
            let totalSum = contactsSum + contactsSum2;
            // console.log('totalSum (plan.renewTimes)', plan.renewTimes);
            // console.log('totalSum (contactsSum)', contactsSum);
            // console.log('totalSum (contactsSum2)', contactsSum2);
            // console.log('totalSum (setContactDetailSum)', contactDetailSum);
            console.log('contactsSum', contactsSum);
            console.log('contactsSum2', contactsSum2);
            console.log('totalSum', totalSum);
            setOverallSum(totalSum)

        }
        catch (err) { }
    }
    useEffect(() => {
        fetchMembership(keyId)
    }, [])
    const getTimeAgoString = (timestamp) => {
        const currentTime = Date.now();
        const timeDifference = currentTime - timestamp;

        // Define time intervals in milliseconds
        const intervals = {
            year: 31536000000,
            month: 2592000000,
            week: 604800000,
            day: 86400000,
            hour: 3600000,
            minute: 60000,
        };

        // Calculate time difference in different intervals
        if (timeDifference < intervals.minute) {
            return 'Just now';
        } else if (timeDifference < intervals.hour) {
            const minutes = Math.floor(timeDifference / intervals.minute);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (timeDifference < intervals.day) {
            const hours = Math.floor(timeDifference / intervals.hour);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (timeDifference < intervals.week) {
            const days = Math.floor(timeDifference / intervals.day);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (timeDifference < intervals.month) {
            const weeks = Math.floor(timeDifference / intervals.week);
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (timeDifference < intervals.year) {
            const months = Math.floor(timeDifference / intervals.month);
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else {
            const years = Math.floor(timeDifference / intervals.year);
            return `${years} year${years > 1 ? 's' : ''} ago`;
        }
    };
    return (
        <div>
            <ToastContainer />
            <div className="Card1 ml-5">
                
            {/* style={{width:"760px"}} */}
            {/* style={{width:"60vw"}} */}
                <div  class="User_Card_Info4_Head row no-gutters border border-gray-300 rounded hov-shadow-md mb-4 has-transition position-relative"
                    id="block_id_42">
                    <div  class="col-md-auto" >
                        <div class="text-center text-md-left pt-3 pt-md-0">
                            {/* <img style={{ width: '100%' }} src="https://demo.activeitzone.com/matrimonial/public/uploads/all/AKgrssE0aIV4yzwTzRQsBaNp93KS2PPp5wqMsld9.png"
                                onerror="this.onerror=null;this.src='https://demo.activeitzone.com/matrimonial/public/assets/img/female-avatar-place.png';"
                                alt={`Image `} /> */}
                            <div className="image-slider">
                                <Carousel
                                    showStatus={false}
                                    showThumbs={false}
                                    selectedItem={selectedSlide}
                                    onChange={handleSlideChange}
                                >
                                    {
                                        privacyState ? (
                                            privacyState?.photo === 'all members'
                                                ?
                                                ele?.gender === 'male' ?
                                                    ele?.photos && ele?.photos?.length !== 0 ?
                                                        ele?.photos?.map((image, index) => (
                                                            <div key={index}>
                                                                <img style={{ width: '100%' }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                                            </div>
                                                        ))
                                                        :
                                                        <img style={{ width: '100%' }} src={'img/default_man.jpg'} />
                                                    :
                                                    ele?.photos && ele?.photos?.length !== 0 ?
                                                        ele?.photos?.map((image, index) => (
                                                            <div key={index}>
                                                                <img style={{ width: '100%' }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                                            </div>
                                                        ))
                                                        :
                                                        <img style={{ width: '100%' }} src={'img/default_woman.jpg'} />
                                                :
                                                privacyState?.photo === 'only premium members and members you like'
                                                    ?
                                                    mainUser?.accountType === 'premium' || mainUser?.recievedRequests?.includes(ele._id).toString() === 'true' || mainUser?.friends?.includes(ele._id).toString() === 'true' ?
                                                        ele?.gender === 'male' ?
                                                            ele?.photos && ele?.photos?.length !== 0 ?
                                                                ele?.photos?.map((image, index) => (
                                                                    <div key={index}>
                                                                        <img style={{ width: '100%' }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                                                    </div>
                                                                ))
                                                                :
                                                                <img style={{ width: '100%' }} src={'img/default_man.jpg'} />
                                                            :
                                                            ele?.photos && ele?.photos?.length !== 0 ?
                                                                ele?.photos?.map((image, index) => (
                                                                    <div key={index}>
                                                                        <img style={{ width: '100%' }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                                                    </div>
                                                                ))
                                                                :
                                                                <img style={{ width: '100%' }} src={'img/default_woman.jpg'} />
                                                        :
                                                        ele?.gender === 'male' ?
                                                            'only premium members and members liked by him can view image'
                                                            :
                                                            'only premium members and members liked by her can view image'

                                                    :
                                                    privacyState?.photo === 'only members you like'
                                                        ?
                                                        mainUser?.recievedRequests?.includes(ele._id).toString() === 'true' || mainUser?.friends?.includes(ele._id).toString() === 'true' ?
                                                            ele?.gender === 'male' ?
                                                                ele?.photos && ele?.photos?.length !== 0 ?
                                                                    ele?.photos?.map((image, index) => (
                                                                        <div key={index}>
                                                                            <img style={{ width: '100%' }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <img style={{ width: '100%' }} src={'img/default_man.jpg'} />
                                                                :
                                                                ele?.photos && ele?.photos?.length !== 0 ?
                                                                    ele?.photos?.map((image, index) => (
                                                                        <div key={index}>
                                                                            <img style={{ width: '100%' }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <img style={{ width: '100%' }} src={'img/default_woman.jpg'} />
                                                            :
                                                            ele?.gender === 'male' ?
                                                                'only members liked by him can view image'
                                                                :
                                                                'only members liked by her can view image'
                                                        :
                                                        ''
                                           ) : (
                                            <p>Loading...</p>
                                        )
                                    }
                                </Carousel>
                            </div>

                        </div>
                    </div>
                    <div class="col-md position-static d-flex align-items-center">
                        {/* <span class="absolute-top-right px-4 py-3">
                                            <span
                                                class="badge badge-inline badge-success">Preminum</span>
                                        </span> */}
                        <div class="px-md-4 p-3 flex-grow-1">

                            <h2 class="h6 fw-600 fs-18 text-truncate mb-1">
                                {ele.name}</h2>
                            <div class="mb-2 fs-12">
                                <span class="opacity-60">Member ID: </span>
                                <span class="ml-4 text-primary">{ele._id}</span>
                            </div>
                            <table class="w-100 opacity-70 mb-2 fs-12">
                                <tr onClick={(e) => nextPage(e, ele._id)} >
                                    <td class="py-1 w-25">
                                        <span>age</span>
                                    </td>
                                    <td class="py-1 w-25 fw-400">
                                        {ele?.age}</td>
                                    <td class="py-1 w-25"><span>Height</span></td>
                                    <td class="py-1 w-25 fw-400">
                                        {ele?.height}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-1"><span>Religion</span></td>
                                    <td class="py-1 fw-400">
                                        {ele?.religion}
                                    </td>
                                    <td class="py-1"><span>Caste</span></td>
                                    <td class="py-1 fw-400">
                                        {ele?.community}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-1"><span>First Language</span>
                                    </td>
                                    <td class="py-1 fw-400">
                                        {ele?.motherTounge}
                                    </td>
                                    <td class="py-1"><span>Marital Status</span>
                                    </td>
                                    <td class="py-1 fw-400">
                                        {ele?.status}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-1"><span>location</span></td>
                                    <td class="py-1 fw-400">
                                        {ele?.city},{ele?.country}
                                    </td>
                                </tr>
                            </table>
                            <div class="row gutters-5 text-center">
                                {Array.isArray(onlineUsers) && onlineUsers.length > 0 ? (
                                            onlineUsers.map((ele2, ind2) => {
                                                if (ele2 === ele._id) {
                                                    return (
                                                        <p style={{ margin: '0%' }}>
                                                            <QuestionAnswerIcon style={{ fontSize: '14px', marginRight: '2px' }} />
                                                            Online Now
                                                        </p>
                                                    );
                                                } else {
                                                    return null; // Or handle other cases as needed
                                                }
                                            })
                                        ) : onlineUsers === ele._id ? (
                                            <p style={{ margin: '0%' }}>
                                                <QuestionAnswerIcon style={{ fontSize: '14px', marginRight: '2px' }} />
                                                Online Now
                                            </p>
                                        ) : (
                                            null
                                        )}
                                        {Array.isArray(previousActive) && previousActive.length > 0 ? (
                                            previousActive.map((ele2, ind2) => {
                                                if (ele2?.username === ele._id && onlineUsers?.includes(ele._id) == false) {
                                                    return (
                                                        <p style={{ margin: '0%' }}>
                                                            <QuestionAnswerIcon style={{ fontSize: '14px', marginRight: '2px' }} />
                                                            {/* {()=>{getTimeAgoString(ele2.lastActive)}} */}
                                                            {getTimeAgoString(ele2.lastActive)}
                                                        </p>
                                                    );
                                                } else {
                                                    return null; // Or handle other cases as needed
                                                }
                                            })
                                        ) : previousActive?.username === ele._id ? (
                                            <p style={{ margin: '0%' }}>
                                                <QuestionAnswerIcon style={{ fontSize: '14px', marginRight: '2px' }} />
                                                Online somtimeago
                                            </p>
                                        ) : (
                                            null
                                        )}
                                {
                                    mainUser && mainUser?.sentRequests && mainUser?.sentRequests?.includes(ele._id) && (
                                        <>
                                            <div class="col" >
                                                <a id="report_a_id_42"
                                                    onclick="report_member(42)" class="text-reset c-pointer">
                                                    <span id="report_id_42"
                                                        class="text-dark">
                                                        <PendingIcon />
                                                        <span
                                                            class="d-block fs-10 opacity-60">Waiting Approval</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    mainUser && mainUser?.rejectedByReciever && mainUser?.rejectedByReciever?.includes(ele._id) && (
                                        <>
                                            <div class="col" >
                                                <a id="report_a_id_42"
                                                    onclick="report_member(42)" class="text-reset c-pointer">
                                                    <span id="report_id_42"
                                                        class="text-dark">
                                                        <CancelIcon />
                                                        <i class="las la-info-circle fs-20 text-primary"></i>
                                                        <span
                                                            class="d-block fs-10 opacity-60">Declined</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    mainUser && mainUser?.friends && mainUser?.friends?.includes(ele._id) && (
                                        <div className='card-details1_Right' >
                                            <div class="col">
                                                <a id="report_a_id_42"
                                                    onclick="report_member(42)" class="text-reset c-pointer">
                                                    <span id="report_id_42"
                                                        class="text-dark">
                                                        <i class="las la-info-circle fs-20 text-primary"></i>
                                                        {mainUser?.accountType == 'free' || mainUser?.isActive !== 'true' ?
                                                            <p> <i> Upgrade to Contact him directly</i></p>
                                                            : ''}
                                                        <div>
                                                            <button style={{cursor:"pointer"}} class="d-block fs-10 opacity-60" onClick={(e) => nextPage(e, ele._id)}>  <CallIcon className='buttons' /> Call</button>
                                                            <button style={{cursor:"pointer"}}  class="d-block fs-10 opacity-60" onClick={() => openChat(ele._id)} > <SendIcon className='buttons' /> Chat</button>
                                                        </div>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    contactView2 ?
                                        mainUser?.interestsSent?.length <= overallSum ?
                                            <>
                                                {
                                                    mainUser && !mainUser?.sentRequests?.includes(ele._id) && !mainUser?.rejectedByReciever?.includes(ele._id) && !mainUser.friends?.includes(ele._id) && !mainUser.recievedRequests?.includes(ele._id) && (
                                                        <>
                                                        {/* <h1> 'fawad'</h1> */}
                                                        <div class="col"  >
                                                        <a id="interest_a_id_42"
                                                            href="https://demo.activeitzone.com/matrimonial/interest/requests" class="text-reset c-pointer">
                                                            <i class="la la-heart-o fs-20 text-primary"></i>
                                                            <p> <i> Like this profile?</i></p>
                                                            <span onClick={(e) => { connected(e, ele._id) }} id="interest_id_42"
                                                                class="d-block fs-10 opacity-60 text-primary">
                                                                <CheckCircleOutlineIcon className='CheckCircleOutlineIcon' />
                                                                <p >Connect Now</p>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                            </>
                                            :
                                            ''
                                        :
                                        ''
                                }
                                {
                                    !contactView2 ?
                                        mainUser?.accountType=='welcome' ?
                                            <>
                                                {
                                                    mainUser && !mainUser?.sentRequests?.includes(ele._id) && !mainUser?.rejectedByReciever?.includes(ele._id) && !mainUser.friends?.includes(ele._id) && !mainUser.recievedRequests?.includes(ele._id) && (
                                                        <>
                                                        {/* <h1> 'fawad'</h1> */}
                                                        <div class="col"  >
                                                        <a id="interest_a_id_42"
                                                            href="https://demo.activeitzone.com/matrimonial/interest/requests" class="text-reset c-pointer">
                                                            <i class="la la-heart-o fs-20 text-primary"></i>
                                                            <p> <i> Like this profile?</i></p>
                                                            <span onClick={(e) => { connected(e, ele._id) }} id="interest_id_42"
                                                                class="d-block fs-10 opacity-60 text-primary">
                                                                <CheckCircleOutlineIcon className='CheckCircleOutlineIcon' />
                                                                <p >Connect Now</p>
                                                            </span>
                                                        </a>
                                                    </div>
                                                    </>
                                                    )
                                                }
                                            </>
                                            :
                                            ''
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showNote} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Select a Plan</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            {/* <Form.Label>Select a plan:</Form.Label> */}
                            {/* <FormControl   as="select" multiple> */}
                            <input onChange={(e) => inputEvent(e)} type="text" className="form-control" name='content' placeholder="Type your message" />
                            {/* <textarea name=""  value={notePlan?.description}>{notePlan?.description}</textarea> */}
                            {/* <textarea name="description" id="" cols="30" rows="10"></textarea> */}
                            {/* Add more options as needed */}
                            {/* </FormControl> */}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button onClick={(e) => submitEvent(e)} className=" custom-button mx-2">Send</button>
                    </div>
                    {/* Additional buttons or actions can be added here */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default User_Card_Info4