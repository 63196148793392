import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register_Header = () => {
  let [newUser, setNewUser] = useState({ name: '', email: '', password: '', dob: '', gender: '', status: 'single', city: '' })
  const [minDate, setMinDate] = useState('');
  let navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  const showToast = () => {
    toast.success('Email verification link sent', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000 // Duration in milliseconds
    });
  };
  useEffect(() => {
    const today = new Date();
    const minAllowedDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    const formattedMinDate = minAllowedDate.toISOString().split('T')[0];
    setMinDate(formattedMinDate);
  }, []);
  let inputEvent = (e) => {
    // console.log(e)
    // console.log(e.target.name, e.target.value)
    if (e.target.name === 'email') {
      let email = e.target.value;
      email = email.toLowerCase()
      setNewUser({ ...newUser, [e.target.name]: email })
    }
    else if (e.target.name === 'dob') {
      const userDateOfBirth = e.target.value;
      const currentDate = new Date();
      const birthYear = new Date(userDateOfBirth).getFullYear();
      const currentYear = currentDate.getFullYear();
      const age = currentYear - birthYear;
      setNewUser({ ...newUser, dob: userDateOfBirth, age: age });
    }
    else {
      setNewUser({ ...newUser, [e.target.name]: e.target.value })
    }
  }
  const [formErrors, setFormErrors] = useState({});
  let validateForm = () => {
    const errors = {};
    if (!newUser.name) {
      errors.name = 'Name is required';
    }
    else if (!/^[a-zA-Z\s]+$/.test(newUser.name)) {
      errors.name = 'Name should only contain alphabets';
    }
    if (!newUser.email) {
      errors.email = 'email is required';
    }
    else if (!/\S+@\S+\.\S+/.test(newUser.email)) {
      errors.email = 'Email is invalid';
    }
    if (!newUser.password) {
      errors.password = 'password is required';
    }
    if (!newUser.gender) {
      errors.gender = 'gender is required';
    }
    if (!newUser.status) {
      errors.status = 'status is required';
    }
    if (!newUser.city) {
      errors.city = 'city is required';
    }
    if (!newUser.dob) {
      errors.dob = 'dob is required';
    }

    setFormErrors(errors);
    console.log('here_formErrors', formErrors);
    return Object.keys(errors).length === 0;
  }

  let submitEvent = async (e) => {
    e.preventDefault()
    const isValid = validateForm();
    // let val=await axios.post('/signup',newUser)
    try {
      if (isValid) {
        let val = await fetch('/signup', {
          method: "POST",
          headers: {
            "content-type": "application/json"
          },
          body: JSON.stringify(newUser)
        })
        // console.log('val')
        if (val.status === 200) {
          const data = await val.json();
          let val2 = await fetch(`/partner/${data._id}`, {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify({})
          })
          let val3 = await fetch(`/alert/${data._id}`, {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify({})
          })
          let val4 = await fetch(`/privacy/${data._id}`, {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify({})
          })
          showToast();
          setTimeout(() => {
            navigate('/login')
          }, 1940);
        }
        else if (val.status === 403) {
          toast.error('This email is already registered!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000 // Duration in milliseconds
          });
        }
        else {
          // alert('error creating account')
          toast.error('Request failed!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000 // Duration in milliseconds
          });
        }
      }
    }
    catch (Err) {

    }
  }
  return (
    <div>
      <section className="log-reg">
        <div className="top-menu-area">
          <div className="container">
            <ToastContainer />
            <div className="row">
              <div className="col-lg-5">
                <a href="/login" className="backto-home"><i className="fas fa-chevron-left"></i> Back to Login</a>
              </div>
              <div className="col-lg-7 ">
                <div className="logo">
                  {/* <img src="assets/images/logo2.png" alt="logo" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-end">
            <div className="image">
            </div>
            <div className="col-lg-7">
              <div className="log-reg-inner">
                <div className="section-header">
                  <h2 className="title">
                    Welcome to My Lovodi
                  </h2>
                  <p>
                    Let's create your profile! Just fill in the fields below, and
                    we’ll get a new account.
                  </p>
                </div>
                <div className="main-content">
                  <form action="#">
                    <h4 className="content-title">Acount Details</h4>
                    <div className="form-group">
                      <label for="">Name*</label>
                      <input type="text" name="name" onChange={inputEvent} className="my-form-control" placeholder="Enter Your Name" />
                      {formErrors.name && <p className="error">{formErrors.name}</p>}
                    </div>
                    <div className="form-group">
                      <label for="">Email Address*</label>
                      <input type="email" name="email" onChange={inputEvent} className="my-form-control" placeholder="Enter Your Email" />
                      {formErrors.email && <p className="error">{formErrors.email}</p>}
                    </div>
                    <div className="form-group">
                      <label for="">Password*</label>
                      {/* <input type="text" name="password" onChange={inputEvent} className="my-form-control" placeholder="Enter Your Password" /> */}
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        // name="password"
                        placeholder="Enter your password"
                        onChange={inputEvent}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        {showPassword ? (
                          <i className="far fa-eye-slash"></i> // Use your eye icon here
                        ) : (
                          <i className="far fa-eye"></i> // Use your eye icon here
                        )}
                      </span>
                      {formErrors.password && <p className="error">{formErrors.password}</p>}
                    </div>
                    <h4 className="content-title mt-5">Profile Details</h4>
                    <div className="form-group">
                      <label for="">Birthday*</label>
                      <input type="date" name="dob" max={minDate} onChange={inputEvent} className="my-form-control" />
                      {formErrors.dob && <p className="error">{formErrors.dob}</p>}
                    </div>
                    <div className="form-group">
                      <label for="">I am a*</label>
                      <div className="option">
                        <div className="s-input mr-3">
                          <input type="radio" name="gender" onChange={inputEvent} value='male' id="males1" /><label for="males1">Man</label>
                        </div>
                        <div className="s-input">
                          <input type="radio" name="gender" onChange={inputEvent} id="females1" value='female' /><label for="females1">Woman</label>
                        </div>
                      </div>
                      {formErrors.gender && <p className="error">{formErrors.gender}</p>}
                    </div>
                    <div className="form-group">
                      <label for="">Marital status*</label>
                      {/* <div className="option"> */}
                      {/* nice-select-wraper */}
                      {/* <div className="s-input"> */}
                      <select name="status" onChange={inputEvent}>
                        <option value="single">Single</option>
                        <option value="married">Married</option>
                        <option value="never married">Never Married</option>
                        <option value="divorced">Divorced</option>
                      </select>
                      {formErrors.status && <p className="error">{formErrors.status}</p>}
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                    <div className="form-group">
                      <label for="">City*</label>
                      <input name="city" onChange={inputEvent} type="text" className="my-form-control" placeholder="Enter Your City" />
                      {formErrors.city && <p className="error">{formErrors.city}</p>}
                    </div>
                    {/* #email-confirm */}
                    <button onClick={submitEvent} className="custom-button" data-toggle="modal" data-target="">Create Your Profile
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Register_Header