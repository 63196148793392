import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User_Info from '../UserDetails/User_Info';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import CallIcon from '@mui/icons-material/Call';

const Card5 = (params) => {
    let user = params.user
    let mainUser = params.mainUser
    let socket = params.socket
    let [details, setDetails] = useState([])
    const [showNote, setShowNote] = useState(false);
    let handleClose = () => { setShowNote(false) }
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    const [message, sentMessage] = useState({ content: '', sender: keyId });
    console.log('mainUser', mainUser)
    console.log('card5 user', user)
    console.log('card5 params', params)
    let value = params.value
    let val2 = []
    let nextPage = async (e, id) => {
        console.log('e', e.target)
        console.log('id', id)
        localStorage.setItem('singleProfileId', JSON.stringify(id))
        navigate('/singleprofile')
    }
    let fetchDetails = () => {
        user?.map((ele, ind) => {
            // if((!mainUser?.sentRequests?.includes(ele._id)) && (!mainUser?.rejectedByReciever?.includes(ele._id))) {
            // console.log('(!mainUser?.sentRequests.includes(ele._id))',(!mainUser?.sentRequests.includes(ele._id)))
            // console.log('(!mainUser?.rejectedByReciever?.includes(ele._id))',(!mainUser?.rejectedByReciever?.includes(ele._id)))
            if ((!mainUser?.sentRequests?.includes(ele._id)) && (!mainUser?.rejectedByReciever?.includes(ele._id))) {
                // return (
                // setDetails(ele)
                console.log('details ele', ele)
                val2.push(ele)
                // )
            }
        })
        setDetails(val2)
        console.log('details', details)
        console.log('details val2', val2)
    }
    useEffect(() => {
        fetchDetails()
    }, [user])
    let navigate = useNavigate()
    const showToast = () => {
        toast.success('Request Successful!!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };
    let connected = async (e, id) => {
        e.preventDefault()
        // alert('clicked')
        console.log('connected id', id)
        try {
            let val = await fetch(`add/${id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                }
            })
            if (val.status === 200) {
                let date = new Date()
                date = date.toLocaleTimeString()
                let keyId = JSON.parse(localStorage.getItem('keyId'))
                params.socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: id,
                    receiverId: id,
                    type: 'sent_Request',
                    date: date
                });

                showToast()
                setTimeout(() => {
                    window.location.reload();
                }, 1940);
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    let openChat = async (id) => {
        // console.log('id',id)
        if (user?.accountType === 'free' || user?.isActive == 'false') {
            navigate('/allplan')
        }
        else if (user?.accountType !== 'free' || user?.isActive == 'true') {
            setShowNote(true)
            let keyId = JSON.parse(localStorage.getItem('keyId'));
            sentMessage({ ...message, receiver: id })
        }
    }
    let inputEvent = (e) => {
        sentMessage({ ...message, [e.target.name]: e.target.value })
      }
      let submitEvent = async (e) => {
        try {
          e.preventDefault();
          let val = await fetch('/chat', {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify(message)
          })
          if (val.status === 200) {
            alert('sent')
            let date = new Date()
            date = date.toLocaleTimeString()
            socket?.emit("sendNotification", {
              sender: keyId,
              receiverName: message.receiver,
              receiverId: message.receiver,
              type: 'message initiation',
              content: message?.content,
              // chatId: selectedChat?._id,
              date: date
            });
          } else {
            alert('err')
          }
        } catch (error) {
    
        }
    
      }
      
    return (
        // user?.map((ele, ind) => {
        <>
            <p><i>New Matches ({details?.length})</i></p>
            {details?.map((ele, ind) => {
                return (
                    < div >
                        <ToastContainer />
                        {/* , width: "37rem" */}
                        <div className="card" style={{ display: 'flex', textAlign: 'left' }}  >
                            <div className="card-body" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {/* <img src="img/fawad.png" style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} className="" alt="..." /> */}
                                {/* {ele?.gender === 'male' ?
                                <img src={ele?.photos[0] || "img/default_man.jpg"} style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} className="" alt="..." />
                                :
                                <img src={ele?.photos[0] || "img/default_woman.jpg"} style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} className="" alt="..." />
                            } */}
                                {
                                    // <User_Info id={ele._id} title={'photos'} />
                                    // ele?.gender === 'male' ?
                                    //     ele?.photos && ele?.photos?.length !== 0 ?
                                    //         <img style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} src={`uploads/${ele?.photos[0]}`} alt="" />
                                    //         :
                                    //         <img style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} src='img/default_man.jpg' alt="" />
                                    //     :
                                    //     ele?.photos && ele?.photos?.length !== 0 ?
                                    //         <img style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} src={`uploads/${ele?.photos[0]}`} alt="" />
                                    //         :
                                    //         <img style={{ height: "56px", width: '55px', borderRadius: '200%', display: 'inline' }} src='img/default_woman.jpg' alt="" />
                                }
                                <div onClick={(e) => nextPage(e, ele._id)} style={{ cursor: "pointer" }}>
                                    <p className="card-text">{ele?.name}
                                        <br />
                                        {ele?.age} yrs, {ele?.height}, <br /> {ele?.motherTongue}, {ele?.city}, {ele?.country}
                                    </p>
                                </div>
                                {/* <div style={{cursor:"pointer"}} onClick={(e)=>{connected(e,ele._id)}} className="">Connect Now</div> */}
                                {
                                    mainUser && mainUser?.sentRequests && mainUser?.sentRequests?.includes(ele._id) && (
                                        <div className="custom-message">Waiting Approval</div>
                                    )
                                }

                                {
                                    mainUser && mainUser?.rejectedByReciever && mainUser?.rejectedByReciever?.includes(ele._id) && (
                                        <div className="custom-message">Declined</div>
                                    )
                                }
                                {
                                    mainUser && mainUser?.friends && mainUser?.friends?.includes(ele._id) && (
                                        // <div className="custom-message bg-success text-white">Accepted</div>
                                        <div className='card-details1_Right'>
                                            <p> <i> Upgrade to Contact him directly</i></p>
                                            <div>
                                                <button >  <CallIcon className='buttons' /> Call</button>
                                                <button className='whatsapp'> <WhatsAppIcon className='buttons' /> Whatsapp</button>
                                                <button onClick={() => openChat(ele)} > <SendIcon className='buttons' /> Lovodi Chat</button>
                                            </div>
                                        </div>

                                    )
                                }

                                {
                                    mainUser && !mainUser?.sentRequests?.includes(ele._id) && !mainUser?.rejectedByReciever?.includes(ele._id) && !mainUser?.friends?.includes(ele._id) && !user?.recievedRequests?.includes(ele._id) && (
                                        <div onClick={(e) => { connected(e, ele._id) }} className="custom-button ml-1">
                                            Connect
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div >
                )
            })}
        </>
    )
}

export default Card5