import React, { useEffect, useState } from 'react'
import Card1 from '../Cards/Card1'
import Header from '../Components/Home/Header'
import Members_Breadcrumb from '../Components/Members/Members_Breadcrumb'
import Matches_Nav from '../Matches_Components/Matches_Nav'
import Card2 from '../Cards/Card2'
import Card3 from '../Cards/Card3'
import Matches_Sidebar from './Matches_Sidebar'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
const My_Matches = (params) => {
  let [isUser, setIsUser] = useState()
  let [user, setUser] = useState()
  let [user2, setUser2] = useState()
  let [details, setDetails] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let [onlineUsers, setOnlineUsers] = useState()
  let [previousActive, setPreviousActive] = useState()
  params?.socket?.emit('getOnlineUsers');

  // Listen for the response from the server
  params?.socket?.on('onlineUsers', (onlineUsers) => {
    // onlineUsers will contain the list of users currently online
    console.log('Online users:', onlineUsers);
  });
  params?.socket?.on('userOnline', (data) => {
    console.log(`${data} is now online`);
    console.log(`${data.username} is now online`);
    setOnlineUsers(data?.username)

    // Handle displaying online status in your UI
  });
  params?.socket?.emit('getLastActiveTimes');

  // Receive last active times from the backend
  params?.socket?.on('receivedLastActiveTimes', (lastActiveTimes) => {
    console.log('Last active times:', lastActiveTimes);
    setPreviousActive(lastActiveTimes)
    // Handle the received last active times in your frontend (e.g., update state)
  });

  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  let fetchDetails = async (keyId) => {
    try {
      let val = await axios.get(`/user/${keyId}`)
      console.log('val.data', val.data)
      setDetails(val?.data)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/users/my-matches/${keyId}`)
      console.log('my val.data', val.data)
      setUser(val?.data?.myMatches)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser2 = async (keyId) => {
    try {
      let val = await axios.get(`/user/${keyId}`)
      console.log('val.data', val.data)
      setUser2(val?.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
    fetchUser2(keyId)
    fetchDetails(keyId)
  }, [])

  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div className='My_Matches'>
      <Header />
      <Members_Breadcrumb />
      <Matches_Nav />
      <div style={{ display: 'flex',margin:'1%' }}>
        <Matches_Sidebar />
        <div>
        <Card1 previousActive={previousActive} onlineUsers={onlineUsers} socket={params.socket} mainUser={details} user={user} value='myMatches' />
        </div>
        </div>
        </div>
        :navigate('/')
        )
      }
      
      export default My_Matches