import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import '../Style/MembershipPlan/Plan.css'
import PaypalCheckOut2 from '../Paypal/PaypalCheckOut2';
import PaypalCheckOut3 from '../Paypal/PaypalCheckOut3';
let stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
// let stripeKey = 'pk_test_51MFGxxGsyHFe5lQADpu8EQF9BKy1N7wdpsmYQhwFGtv6ovbZ93uDWb6cTW92Dsr50q2p0zyjOI01O6VDYjthwf5K00zyktNRfo';
const Membership_Plan = ({ socket }) => {
    // console.log('you stripeKey here',stripeKey)
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [price, setPrice] = useState(null);
    const [plan, setPlan] = useState(null);
    const [allPlans, setAllPlans] = useState(null);
    const [trialPeriodDays, setTrialPeriodDays] = useState(null);
    const [code, setCode] = useState('');
    let keyId = JSON.parse(localStorage.getItem('keyId'))

    let fetchCoupon = async (e) => {
        e.preventDefault();
        try {
            console.log('code', code)
            let val = await axios.get(`/coupons/check/${code}`)
            if (val.status == 200) {
                console.log('val.data', val.data)
                alert('Coupn applied successfully')
                const totalAmount = price; 
                const discountPercentage = val.data.discountAmount;
                const discountAmount = (totalAmount * discountPercentage) / 100;
                const discountedTotal = totalAmount - discountAmount;
                console.log("Total Amount:", totalAmount);
                console.log("Discounted Amount:", discountAmount);
                console.log("Discounted Total Amount:", discountedTotal);
                setPrice(discountedTotal)
            }
            else if (val.status == 404) {
                alert('does not exists')
            }
            else {
                alert('does not exists')
            }
        }
        catch (err) {
            alert('does not exists')
            console.log('err', err)
        }
    }

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCountries([...selectedCountries, value]);
            console.log('selectedCountries', selectedCountries)
        } else {
            setSelectedCountries(selectedCountries.filter(country => country !== value));
            console.log('selectedCountries hehe', selectedCountries)
        }
    };
    let buyNow = (e, plan, price, months, days, category) => {
        setSelectedCategory(category)
        let totalDays;
        if (months > 0 && days > 0) {
            // Convert months to days and add days
            totalDays = months * 30 + days;
        } else if (months === 0 && days > 0) {
            // Only days provided
            totalDays = days;
        } else if (months > 0 && days === 0) {
            // Only months provided, consider 30 days per month
            totalDays = months * 30;
        }
        console.log('buyNow Total days:', totalDays);
        // ?ele2?.time_period_months*ele2?.time_period_days:ele2?.time_period_days
        // console.log(e.target)
        console.log('buyNow', plan)
        console.log('buyNow', price)
        console.log('buyNow', months)
        console.log('buyNow', days)
        setShowPopup(true);
        setPrice(price);
        setPlan(plan)
        setTrialPeriodDays(totalDays)
    }
    const onToken = async (token, recipient) => {

        try {
            let requestBody = {
                tokenId: token,
                amount: parseFloat(price * 100), // Amount in cents
                recipientId: recipient,
                plan: plan,
                trialPeriodDays: trialPeriodDays,
            }
            // if (selectedCategory == 'block') {
            //     requestBody.blockedCountries = selectedCountries;
            // }
            const response = await axios.post('/payment/payment', {
                tokenId: token,
                amount: parseFloat(price * 100), // Amount in cents
                recipientId: recipient,
                plan: plan,
                trialPeriodDays: trialPeriodDays,
                blockedCountries: selectedCountries
            });
            if (response.status === 200) {
                localStorage.setItem('subscription', JSON.stringify('true'))
                localStorage.setItem('monthlyChargesActive', JSON.stringify('true'))
                alert('Thank You for your payment');
                let date = new Date()
                date = date.toLocaleTimeString()
                let keyId = JSON.parse(localStorage.getItem('keyId'))
                console.log('sending soon', socket)
                console.log('Response Data:', response.data);
                socket?.emit("sendNotification", {
                    sender: response.data._id,
                    receiverName: keyId,
                    receiverId: keyId,
                    type: 'payment',
                    date: date
                });
                window.location.reload()
            }
        }
        catch (error) {
            alert(error)
        }
    }
    const handleOptionSelect = (option) => {
        // Handle the selected option (PayPal or Stripe)
        setSelectedOption(option);
        // Here you can add logic to redirect the user to the respective payment gateway
        // For example:
        // if (option === 'PayPal') {
        //   // Redirect to PayPal payment page
        // } else if (option === 'Stripe') {
        //   // Redirect to Stripe payment page
        // }
    };
    let country = [
        "Afghanistan",
        "Albania",
        "Algeria",

        "Antigua & Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire",
        "Bosnia & Herzegovina",
        "Botswana",
        "Brazil",
        "Canada",
        "Cayman Islands",
        "Chad",
        "Channel Islands",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Island",
        "Colombia",
        "Comoros",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote DIvoire",
        "Croatia",
        "Cuba",
        "Curaco",
        "Denmark",
        "Dominica",

        "Ecuador",
        "Egypt",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        // "French Guiana",
        // "French Polynesia",
        // "French Southern Ter",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Great Britain",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        // "Guam",
        // "Guatemala",
        // "Guinea",
        "Guyana",
        "Haiti",
        "Hawaii",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "Indonesia",
        "India",
        "Iran",
        "Iraq",
        "Ireland",
        // "Isle of Man",
        // "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        // "Kiribati",
        // "Korea North",
        "Korea",
        "Kuwait",
        "Kyrgyzstan",
        // "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        // "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        // "Macedonia",
        // "Madagascar",
        "Malaysia",
        "Malawi",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Midway Islands",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Nambia",
        "Nauru",
        "Nepal",
        "Netherlands",
        // "Netherlands (Holland, Europe)",
        // "Nevis",
        // "New Caledonia",
        "New Zealand",
        // "Nicaragua",
        // "Niger",
        "Nigeria",
        // "Niue",
        // "Norfolk Island",
        "Norway",
        "Oman",
        "Pakistan",
        // "Palau Island",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        // "Pitcairn Island",
        "Poland",
        "Portugal",
        // "Puerto Rico",
        "Qatar",
        // "Republic of Montenegro",
        // "Republic of Serbia",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "St Barthelemy",
        "St Eustatius",
        "St Helena",
        "St Kitts-Nevis",
        "St Lucia",
        "St Maarten",
        "St Pierre & Miquelon",
        "St Vincent & Grenadines",
        "Saipan",
        "Samoa",
        "Samoa American",
        "San Marino",
        "Sao Tome & Principe",
        "Saudi Arabia",
        "Senegal",
        // "Seychelles",
        // "Sierra Leone",
        "Singapore",
        "Slovakia",
        // "Slovenia",
        // "Solomon Islands",
        "Somalia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Tahiti",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks & Caicos Is",
        "Tuvalu",
        "Uganda",
        "United Kingdom",
        "Ukraine",
        "United Arab Emirates",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City State",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (Brit)",
        "Virgin Islands (USA)",
        "Wake Island",
        "Wallis & Futuna Is",
        "Yemen",
        "Zaire",
        "Zambia",
        "Zimbabwe"
    ]
    let fetchPlans = async (category) => {
        // let val=await axios.get(`/allPlanss/${category}`)
        try {
            let val = await axios.get(`/allPlanss/membership`)
            console.log('val.data', val.data)
            setAllPlans(val.data)
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchPlans()
    }, [])
    return (
        <div>
            <div className="container">
                {allPlans ?
                    allPlans?.map((ele, ind) => {
                        return (
                            <div style={{ marginTop: '200px' }}>
                                {/* <div className="container"> */}
                                <div className="row justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="content">
                                            <div className="section-header">
                                                <h6 className="sub-title extra-padding">
                                                    Upgrade Your Profile
                                                </h6>
                                                <h2 className="title">
                                                    {/* Membership Plans */}
                                                    {ele?.category} Plans
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="pricing-plan-wrapper">
                                            {/* <img className="left-img" src="assets/images/membership/left-img.png" alt="" />
                                            <img className="right-img" src="assets/images/membership/right-img.png" alt="" /> */}
                                            <div className="row">
                                                {ele?.data?.map((ele2, ind2) => {
                                                    return (
                                                        <div className="col-lg-3 col-md-6">
                                                            <div className="single-plan">
                                                                <p className="duration">
                                                                    {ele2.name}
                                                                    {/* Plan */}
                                                                </p>
                                                                <h4 className="number">
                                                                    <sup>$</sup>{ele2?.price}
                                                                </h4>
                                                                <p className="stamet">
                                                                    {
                                                                        ele2?.time_period_months !== 0 ? <>{ele2?.time_period_months} Months</> : ''
                                                                    }
                                                                    {
                                                                        ele2?.time_period_days !== 0 ? <>{ele2?.time_period_days} Days</> : ''
                                                                    }
                                                                    {/* {ele2?.time_period_months} Months {ele2?.time_period_days} Days */}
                                                                </p>
                                                                <div >
                                                                    <ul style={{ textAlign: 'left' }}>
                                                                        {ele2?.features?.map((ele3, ind3) => {
                                                                            return (
                                                                                <li><CheckIcon style={{ fontSize: "15px" }} /> {ele3} </li>
                                                                            )
                                                                        })}
                                                                        {/* <li><CheckIcon style={{ fontSize: "15px" }} /> 80 View contact infos</li>
                                                                    <li><CheckIcon style={{ fontSize: "15px" }} /> 50 daily Send Interests</li>
                                                                    <li><CheckIcon style={{ fontSize: "15px" }} /> View Chat</li>
                                                                    <li><CheckIcon style={{ fontSize: "15px" }} /> Reply Chat</li>
                                                                    <li><CheckIcon style={{ fontSize: "15px" }} /> Add Shorts</li>
                                                                    <li><CheckIcon style={{ fontSize: "15px" }} /> Priority over Free member</li> */}
                                                                    </ul>
                                                                </div>
                                                                {
                                                                    ele2?.price === 0 ?
                                                                        <a href="#" className="custom-button">Register Now!</a>
                                                                        // :
                                                                        // ele?.category == 'block'
                                                                        //     ?
                                                                        //     <>

                                                                        //         <a onClick={(e) => { buyNow(e, ele2.name, ele2.price, ele2.time_period_months, ele2.time_period_days, ele?.category) }} href="#" className="custom-button">Confirm</a>
                                                                        //     </>
                                                                        :
                                                                        <a onClick={(e) => { buyNow(e, ele2.name, ele2.price, ele2.time_period_months, ele2.time_period_days, ele2?.category) }} href="#" className="custom-button">Buy Now!</a>
                                                                }
                                                                <img className="shape" src="assets/images/membership/plan-bg.png" alt="" />
                                                                {showPopup && (
                                                                    <div className="popup-background">
                                                                        <div className="popup">
                                                                            {/* ele2?.category;{ele2?.category}
                                                                            selectedCategory;{selectedCategory} */}
                                                                            {/* width:"1600px", */}
                                                                            <div style={{ marginTop: "14px", paddingTop: "14px" }}>
                                                                                {
                                                                                    selectedCategory == 'block' ?
                                                                                        country?.map((option, ind) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <label htmlFor={option}>{option}</label>
                                                                                                    <input
                                                                                                        style={{ height: '12px', width: '16px', margin: '1px' }}
                                                                                                        type="checkbox"
                                                                                                        key={option}
                                                                                                        value={option}
                                                                                                        name={option}
                                                                                                        id={option}
                                                                                                        onChange={handleCheckboxChange}
                                                                                                    />
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                            <h2>Select Payment Method</h2>
                                                                            <p>Use coupon</p>
                                                                            <input type="text" onChange={(e) => { setCode(e.target.value) }} name="code" placeholder='write coupon code here' id="" />
                                                                            <button className='custom-button m-2' onClick={fetchCoupon} >Apply </button>
                                                                            <div className="payment-options">
                                                                                <StripeCheckout
                                                                                    name="Buy Plan"
                                                                                    description="Enjoy premium version"
                                                                                    image="img/Donate.jpg"
                                                                                    shippingAddress
                                                                                    billingAddress
                                                                                    amount={price * 100}
                                                                                    token={(token) => onToken(token, keyId)}
                                                                                    stripeKey={stripeKey}
                                                                                >
                                                                                    <button className='custom-button' onClick={() => handleOptionSelect('Stripe')}>Pay with Stripe</button>
                                                                                </StripeCheckout>
                                                                                <PaypalCheckOut2 amount={parseFloat(price || 1)} recipientId={keyId} selectedCountries={selectedCountries} plan={plan} trialPeriodDays={trialPeriodDays} socket={socket} />
                                                                            </div>
                                                                            <button className="close-button" onClick={() => setShowPopup(false)}>
                                                                                Close
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : ''}
            </div>
        </div>
    )
}

export default Membership_Plan