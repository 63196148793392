import React,{useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Style/Matches/Matches_Nav.css'
const Matches_Nav = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
  };
  const location = useLocation();
  let newMatches=JSON.parse(localStorage.getItem('newMatches'))
  let myMatches=JSON.parse(localStorage.getItem('myMatches'))
  let nearMeMatches=JSON.parse(localStorage.getItem('nearMeMatches'))
      return (
      <div className="Admin_Topbar">
          <nav className="navbar" >
              {/* <div className="logo">Your Logo</div> */}
              <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
              </div>
              <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
              <li className="nav-item">
              <Link to="/newMatches" className={`nav-link text-white ${location.pathname === '/newMatches' ? 'active' : ''}`}>New Matches ({newMatches || 0})</Link>
            </li>
            <li className="nav-item">
              <Link to="/todayMatches" className={`nav-link text-white ${location.pathname === '/todayMatches' ? 'active' : ''}`}>Today's Matches</Link>
            </li>
            <li className="nav-item">
              <Link to="/myMatches" className={`nav-link text-white ${location.pathname === '/myMatches' ? 'active' : ''}`}>My Matches ({myMatches || 0})</Link>
            </li>
            <li className="nav-item">
              <Link to="/nearMatches" className={`nav-link text-white ${location.pathname === '/nearMatches' ? 'active' : ''}`}>Near Me ({nearMeMatches || 0})</Link>
            </li>
            <li className="nav-item">
              <Link to="/recentlyViewed" className={`nav-link text-white ${location.pathname === '/recentlyViewed' ? 'active' : ''}`}>Recently Viewed</Link>
            </li>
            <li className="nav-item">
              <Link to="/moreMatches" className={`nav-link text-white ${location.pathname === '/moreMatches' ? 'active' : ''}`}>More Matches</Link>
            </li>
              </ul>
          </nav>
      </div>
  );
};

export default Matches_Nav;
