import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../Style/Admin/Admin_Social/Admin_SocialLinks.css'; // Import your CSS file for styling

const Admin_Terms_Header = () => {
    const [socialMediaLinks, setSocialMediaLinks] = useState({
        facebook: '',
        twitter: '',
        instagram: '',
        linkedin: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSocialMediaLinks({
            ...socialMediaLinks,
            [name]: value,
        });
    };
    let fetchDetails = async () => {
        try {
            let val = await axios.get(`/terms`);
            console.log('social-links',val.data)
            setSocialMediaLinks(val.data)
        }
        catch (err) {

        }
    }
    useEffect(() => {
        fetchDetails()
    }, [])
    let submitEvent=async()=>{
        try{
        let val=await fetch('/terms',{
            method:"POST",
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify(socialMediaLinks)
        })
        if(val.status===200)
        {
            alert('Updated Sucessfully!')
            window.location.reload()
        }
        else{
            
            alert('Can not proceed with the request currently!')
        }}
        catch(err){
            
        }
    }
    return (
        <div className='Admin_Social_Header'>
            <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Add Terms & Conditions here</h4>
                    </div>
                    <div class="card-body">
                        <div className="social-links-container">
                            {/* <h2>Add your social media links:</h2> */}
                            <div className="social-inputs">
                                <div className="social-input">
                                    <label htmlFor="facebook">Content:</label>
                                    {/* <input style={{color:"black"}}
                                        type="text"
                                        id="facebook"
                                        name="facebook"
                                        value={socialMediaLinks.facebook}
                                        onChange={handleInputChange}
                                    /> */}
                                    <textarea name="content" id="" value={socialMediaLinks.content} onChange={handleInputChange} cols="30" rows="140">
                                    </textarea>
                                </div>
                            </div>
                            <div className="social-links">
                                <button className='custom-button' onClick={submitEvent} >Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Terms_Header