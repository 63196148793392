import axios from 'axios'
import React, { useEffect, useState } from 'react'

const Success_Stories = () => {
    let [stories, getStories] = useState()
    let fetchSuccess = async () => {
        try {
            let val = await axios.get(`/success-story`)
            console.log('val.data', val)
            console.log('val.data', val.data)
            let val2 = val.data.reverse()
            getStories(val2.slice(0, 3))
            // getStories(val.data.slice(0, 3))
        }
        catch (err) {

        }
    }
    useEffect(() => {
        fetchSuccess()
    }, [])
    return (
        <div>
            <section className="sucess-stories-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="content">
                                <div className="section-header">
                                    <h6 className="sub-title wow fadeInUp">
                                        Love in faith
                                    </h6>
                                    <h2 className="title wow fadeInUp">
                                        Success Stories
                                    </h2>
                                    <p className="text wow fadeInUp">
                                        Some a nor tortor. Until he was targeted by the author of the school. The transporter of the property of the urn. Tomorrow is an element.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {stories?.map((ele, ind) => {
                            return (
                                <div className="col-lg-4 col-md-6">
                                    <div className="single-story-box wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="img">
                                            {ele?.photos ?
                                                <img style={{ width: "288px", height: "198px" }} src={`uploads/${ele?.photos}` || 'assets/images/sucess/img1.jpg'} alt="" />
                                                :
                                                <img src={'assets/images/sucess/img1.jpg'} alt="" />}

                                        </div>
                                        <div className="content">
                                            <div className="author">
                                                {/* <img style={{width:"60px",height:"60px"}}  src={`uploads/${ele?.photos}` || 'assets/images/sucess/p1.png' }  alt="" /> */}
                                                {/* <img src="assets/images/sucess/p1.png" alt="" /> */}
                                                <span></span>
                                            </div>
                                            <h4 className="title">
                                                {/* Love horoscope for Cancer
                                                There will be... */}
                                                {ele?.name} &#10084; {ele?.partnerName}
                                            </h4>
                                            <p className="date">
                                                {ele?.meetDate} is the meet date
                                            </p>
                                            <p>
                                                {ele?.description}
                                            </p>
                                        </div>
                                        <div className="box-footer">
                                            <div className="left">
                                                {/* <ul className="box-social-links">
                                                    <li>
                                                        <a href="#">
                                                            <i className="fab fa-facebook-f"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="fab fa-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </ul> */}
                                            </div>
                                            {/* <div className="right">
                                                <a href="#">
                                                    Read More<i className="fas fa-arrow-right"></i>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="single-story-box wow fadeInUp" data-wow-delay="0.2s">
                                <div className="img">
                                    <img src="assets/images/sucess/img2.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="author">
                                        <img src="assets/images/sucess/p2.png" alt="" />
                                        <span></span>
                                    </div>
                                    <h4 className="title">
                                        ‘love at first sight’ is all
                                        about initial attraction...
                                    </h4>
                                    <p className="date">
                                        December 11, 2021
                                    </p>
                                </div>
                                <div className="box-footer">
                                    <div className="left">
                                        <ul className="box-social-links">
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <a href="#">
                                            Read More<i className="fas fa-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="single-story-box wow fadeInUp" data-wow-delay="0.3s">
                                <div className="img">
                                    <img src="assets/images/sucess/img3.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="author">
                                        <img src="assets/images/sucess/p3.png" alt="" />
                                        <span></span>
                                    </div>
                                    <h4 className="title">
                                        What women actually
                                        want to feel on their...
                                    </h4>
                                    <p className="date">
                                        December 14, 2021
                                    </p>
                                </div>
                                <div className="box-footer">
                                    <div className="left">
                                        <ul className="box-social-links">
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <a href="#">
                                            Read More<i className="fas fa-arrow-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Success_Stories