import React, { useEffect, useState } from 'react'
import MyPhotos_Header from '../Dashboard/Components/MyPhotos/MyPhotos_Header'
import MyPhotos_Top from '../Dashboard/Components/MyPhotos/MyPhotos_Top'
import Header from '../Components/Home/Header'
import Dashboard_Breadcrumb from './Dashboard_Breadcrumb'
import MyPhotos_Display from '../Dashboard/Components/MyPhotos/MyPhotos_Display'
import Dashboard_Nav from '../Dashboard/Components/Navbar/Dashboard_Nav'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'
import axios from 'axios'
const Dashboard_MyPhotos = () => {
  let [isUser, setIsUser] = useState()
  let [user, setUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  
  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/User/${keyId}`)
      console.log('val.data', val.data)
      setUser(val.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
  }, [])
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div>
      <Dashboard_Breadcrumb/>
      <Header/>
      <Dashboard_Nav/>
        <MyPhotos_Top user={user}/>
        <MyPhotos_Display user={user}/>
        <MyPhotos_Header user={user}/>
    </div>
    :navigate('/')
  )
}

export default Dashboard_MyPhotos