import React, { useState } from 'react'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Carousel } from 'react-responsive-carousel';
import User_Info from '../UserDetails/User_Info';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';


const User_Card_Info = ({ user, ele, socket }) => {
    let navigate = useNavigate()
    const [showNote, setShowNote] = useState(false);
    let handleClose = () => { setShowNote(false) }
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    const [message, sentMessage] = useState({ content: '', sender: keyId });
    // Define images for the slider
    console.log('user,ele', user, ele)
    const images = [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
        // Add more image URLs here
    ];
    const [selectedSlide, setSelectedSlide] = useState(0);

    const handleSlideChange = (index) => {
        setSelectedSlide(index);
    };
    const showToast = () => {
        toast.success('Request Successful!!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };
    let inputEvent = (e) => {
        // console.log(e.target.name, e.target.value)
        sentMessage({ ...message, [e.target.name]: e.target.value })
    }
    let submitEvent = async (e) => {
        try {
            e.preventDefault();
            let val = await fetch('/chat', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(message)
            })
            if (val.status === 200) {
                alert('sent')
                let date = new Date()
                date = date.toLocaleTimeString()
                socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: message.receiver,
                    receiverId: message.receiver,
                    type: 'message initiation',
                    content: message?.content,
                    // chatId: selectedChat?._id,
                    date: date
                });
            } else {
                alert('err')
            }
        } catch (error) {
        }
    }
    let connected = async (e, id) => {
        e.preventDefault()
        // alert('clicked')
        console.log(id)
        try {

            let val = await fetch(`add/${id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                }
            })
            if (val.status === 200) {
                showToast()
                setTimeout(() => {
                    window.location.reload();
                    // navigate('/inboxSent')
                }, 1940);
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }

        } catch (error) {
            console.log('error', error)
        }
    }
    let nextPage = async (e, id) => {
        console.log('nextPage e', e.target)
        console.log('nextPage id', id)
        localStorage.setItem('singleProfileId', JSON.stringify(id))
        navigate('/singleprofile')
    }
    let openChat = async (id) => {
        // console.log('id',id)
        if (user?.accountType === 'free' || user?.isActive == 'false') {
            navigate('/allplan')
        }
        else if (user?.accountType !== 'free' || user?.isActive == 'true') {
            setShowNote(true)
            // let keyId = JSON.parse(localStorage.getItem('keyId'));
            sentMessage({ ...message, receiver: id })
            // localStorage.setItem('receiverId', JSON.stringify('receiverId'));
            // navigate('/new-chat')
            // let val=await axios.get(``)
        }
    }
    return (
        <div className="Card6 Card1">
            <div class="row no-gutters border border-gray-300 rounded hov-shadow-md mb-4 has-transition position-relative"
                id="block_id_42">
                <ToastContainer />
                <div className="card-container">
                    <div className="image-slider">
                        <Carousel
                            showStatus={false}
                            showThumbs={false}
                            selectedItem={selectedSlide}
                            onChange={handleSlideChange}
                        >
                            {/* {ele.photos?.map((image, index) => (
                            <div key={index}>
                                <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={image} alt={`Image ${index}`} />
                            </div>
                        ))} */}
                            {ele?.gender === 'male' ?
                                ele?.photos && ele?.photos?.length !== 0 ?
                                    ele?.photos?.map((image, index) => (
                                        <div key={index}>
                                            <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                        </div>
                                    ))
                                    :
                                    <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={'img/default_man.jpg'} />
                                :
                                ele?.photos && ele?.photos?.length !== 0 ?
                                    ele?.photos?.map((image, index) => (
                                        <div key={index}>
                                            <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                        </div>
                                    ))
                                    :
                                    <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={'img/default_woman.jpg'} />
                            }
                        </Carousel>
                    </div>
                    {/* <div class="col-md-auto">
                                        <div class="text-center text-md-left pt-3 pt-md-0">
                                            <img src="https://demo.activeitzone.com/matrimonial/public/uploads/all/AKgrssE0aIV4yzwTzRQsBaNp93KS2PPp5wqMsld9.png"
                                                onerror="this.onerror=null;this.src='https://demo.activeitzone.com/matrimonial/public/assets/img/female-avatar-place.png';"
                                                class="img-fit mw-100 size-150px size-md-250px rounded-circle md-rounded-0"/>
                                        </div>
                                    </div> */}
                    <div className=" ">
                    {/* card-details */}
                        <div  class="col-md position-static d-flex align-items-center">
                            <div class="px-md-4 p-3 flex-grow-1">

                                <h2 class="h6 fw-600 fs-18 text-truncate mb-1">
                                    <User_Info id={ele} title={'name'} /></h2>
                                <div class="mb-2 fs-12">
                                    <span class="opacity-60">Member ID: </span>
                                    <span class="ml-4 text-primary"><User_Info id={ele} title={'_id'} /></span>
                                </div>
                                <table class="w-100 opacity-70 mb-2 fs-12">
                                    <tr onClick={(e) => nextPage(e, ele)}>
                                        <td class="py-1 w-25">
                                            <span>age</span>
                                        </td>
                                        <td class="py-1 w-25 fw-400">
                                            <User_Info id={ele} title={'age'} /></td>
                                        <td class="py-1 w-25"><span>Height</span></td>
                                        <td class="py-1 w-25 fw-400">
                                            <User_Info id={ele} title={'height'} />
                                        </td>
                                    </tr>
                                    <tr onClick={(e) => nextPage(e, ele)}>
                                        <td class="py-1"><span>Religion</span></td>
                                        <td class="py-1 fw-400">
                                            <User_Info id={ele} title={'religion'} />
                                        </td>
                                        <td class="py-1"><span>Caste</span></td>
                                        <td class="py-1 fw-400">
                                            <User_Info id={ele} title={'community'} />
                                        </td>
                                    </tr>
                                    <tr onClick={(e) => nextPage(e, ele)}>
                                        <td class="py-1"><span>First Language</span>
                                        </td>
                                        <td class="py-1 fw-400">
                                            <User_Info id={ele} title={'motherTongue'} />
                                        </td>
                                        <td class="py-1"><span>Marital Status</span>
                                        </td>
                                        <td class="py-1 fw-400">
                                            <User_Info id={ele} title={'status'} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-1"><span>location</span></td>
                                        <td class="py-1 fw-400">
                                            <User_Info id={ele} title={'city'} />
                                        </td>
                                    </tr>
                                </table>
                                <div class="row gutters-5 text-center">
                                    {/* <div class="col">
                                                        <i class="las la-user fs-20 text-primary"></i>
                                                        <span
                                                            class="d-block fs-10 opacity-60">Full Profile</span>
                                                </div> */}
                                    {
                                        user && user?.sentRequests && user?.sentRequests?.includes(ele) && (
                                            <div class="col">
                                                <a id="report_a_id_42"
                                                    onclick="report_member(42)" class="text-reset c-pointer">
                                                    <span id="report_id_42"
                                                        class="text-dark">
                                                        <PendingIcon />
                                                        <span
                                                        class="">Waiting Approval</span>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    }

                                    {
                                        user && user?.rejectedByReciever && user?.rejectedByReciever?.includes(ele) && (
                                            <div class="col">
                                                <a id="report_a_id_42"
                                                    onclick="report_member(42)" class="text-reset c-pointer">
                                                    <span id="report_id_42"
                                                        class="text-dark">
                                                        <CancelIcon />
                                                        <i class="las la-info-circle fs-20 text-primary"></i>
                                                        <span
                                                    
                                                            class="">Declined</span>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    }
                                    {
                                        user && user?.friends && user?.friends?.includes(ele) && (
                                            // <div className="custom-message bg-success text-white">Accepted</div>
                                            <div className='card-details1_Right'>
                                                <div class="col">
                                                    <a id="report_a_id_42"
                                                        onclick="report_member(42)" class="text-reset c-pointer">
                                                        <span onClick={() => openChat(ele._id)} id="report_id_42"
                                                            class="text-dark">
                                                                <SendIcon style={{cursor:"pointer"}} className='buttons' /> Chat
                                                            {/* <i class="las la-info-circle fs-20 text-primary"></i> */}
                                                            {/* {user?.accountType == 'free' || user?.isActive !== 'true' ?
                                                                <p> <i> Upgrade to Contact him directly</i></p>
                                                                : ''} */}
                                                                {/* <button class=""  > <SendIcon className='buttons' /> Chat</button> */}
                                                            {/* <div>
                                                                <button class="" onClick={(e) => nextPage(e, ele._id)}>  <CallIcon className='buttons' /> Call</button>
                                                            </div> */}
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        user && !user?.sentRequests?.includes(ele) && !user?.rejectedByReciever?.includes(ele) && !user.friends?.includes(ele) && !user.recievedRequests?.includes(ele) && (
                                            <div className="profile-container">
                                                <p> <i> Like this profile?</i></p>
                                                <div onClick={(e) => { connected(e, ele._id) }} className='icon-container'>
                                                    {/* <CheckCircleOutlineIcon className='CheckCircleOutlineIcon' /> */}
                                                    <p >Connect Now {ele} </p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* </div> */}
                </div>
            </div>
            <Modal show={showNote} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Select a Plan</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            {/* <Form.Label>Select a plan:</Form.Label> */}
                            {/* <FormControl   as="select" multiple> */}
                            <input onChange={(e) => inputEvent(e)} type="text" className="form-control" name='content' placeholder="Type your message" />
                            {/* <textarea name=""  value={notePlan?.description}>{notePlan?.description}</textarea> */}
                            {/* <textarea name="description" id="" cols="30" rows="10"></textarea> */}
                            {/* Add more options as needed */}
                            {/* </FormControl> */}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button onClick={(e) => submitEvent(e)} className=" custom-button mx-2">Send</button>
                    </div>
                    {/* Additional buttons or actions can be added here */}
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default User_Card_Info