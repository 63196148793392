import React, { useState } from 'react';
import { Link,useLocation } from 'react-router-dom';

const Dashboard_Nav = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };
    const location = useLocation();

    return (
        <div className="Admin_Topbar">
            <nav className="navbar" >
                {/* <div className="logo">Your Logo</div> */}
                <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                    <li className="nav-item">
                        <Link onClick={toggleMobileMenu} to="/dashboard" className={`nav-link text-white ${location.pathname === '/dashboard' ? 'active' : ''}`}>Dashboard</Link>
                    </li>
                    <li className="nav-item">
                        {/* <Link onClick={toggleMobileMenu} to="/DashboardProfile" className={`nav-link text-white ${location.pathname === '/DashboardProfile' ? 'active' : ''}`}>My Profile</Link> */}
                        <Link onClick={toggleMobileMenu} to="/DashboardProfileView" className={`nav-link text-white ${location.pathname === '/DashboardProfileView' ? 'active' : ''}`}>My Profile</Link>
                    </li>
                    <li className="nav-item">
                        <Link onClick={toggleMobileMenu} to="/DashboardMyPhotos" className={`nav-link text-white ${location.pathname === '/DashboardMyPhotos' ? 'active' : ''}`}>My Photos</Link>
                    </li>
                    <li className="nav-item">
                        <Link onClick={toggleMobileMenu} to="/DashboardPreferences" className={`nav-link text-white ${location.pathname === '/DashboardPreferences' ? 'active' : ''}`}>Partner Preferences</Link>
                    </li>
                    {/* <li className="nav-item">
                <Link onClick={toggleMobileMenu} to="/DashboardMyOrders" className={`nav-link text-white ${location.pathname === '/DashboardMyOrders' ? 'active' : ''}`}>My Orders</Link>
              </li> */}
                    <li className="nav-item">
                        <Link onClick={toggleMobileMenu} to="/DashboardMyContact" className={`nav-link text-white ${location.pathname === '/DashboardMyContact' ? 'active' : ''}`}>My Contact</Link>
                    </li>
                    <li className="nav-item">
                        <Link onClick={toggleMobileMenu} to="/Referrals" className={`nav-link text-white ${location.pathname === '/Referrals' ? 'active' : ''}`}>Referrals</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Dashboard_Nav;
