import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import '../../../Style/Dashboard_Home/Profile_Preferences_Header2.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact_Filter_Header2() {
  let [partner, setPartner] = useState(null)
  const [preferences, setPreferences] = useState({
    minAge: partner?.minAge,
    maxAge: partner?.maxAge,
    minHeight: partner?.minHeight,
    maxHeight: partner?.maxHeight,
    maritalStatus: partner?.maritalStatus,
    community: partner?.community,
    religion: partner?.religion,
    motherTongue: partner?.motherTongue,
    country: partner?.country,
    // stateLivingIn: 'Open to all',
    // residencyStatus: 'Open to all',
    // education: partner?.education,
    // workingWith: partner?.workingWith,
    // occupation: partner?.occupation,
    // minIncome: partner?.minIncome,
    // postedBy: partner?.postedBy,
    // diet: partner?.diet,
  })
  const showToast = () => {
    toast.success('Request successful!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Duration in milliseconds
    });
  };

  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let fetchDetails = async () => {
    try {
    //   let val = await axios.get(`/partner/${keyId}`)
      let val = await axios.get(`/privacy/${keyId}`)
      console.log('val.data', val.data)
      setPartner(val.data)
      setPreferences({
        minAge: val.data?.minAge,
        maxAge: val.data?.maxAge,
        minHeight: val.data?.minHeight,
        maxHeight: val.data?.maxHeight,
        maritalStatus: val.data?.maritalStatus,
        community: val.data?.community,
        religion: val.data?.religion,
        motherTongue: val.data?.motherTongue,
        country: val.data?.country,
        // stateLivingIn: 'Open to all',
        // residencyStatus: 'Open to all',
        // education: val.data?.education,
        // workingWith: val.data?.workingWith,
        // occupation: val.data?.occupation,
        // minIncome: val.data?.minIncome,
        // postedBy: val.data?.postedBy,
        // diet: val.data?.diet
      })
    } catch (error) {
      setPartner(error)
      console.log('error', error)
    }
  }

  // const [preferences, setPreferences] = useState({
  //   minAge: 29,
  //   maxAge: 35,
  //   minHeight: "5' 8''",
  //   maxHeight: "6' 8''",
  //   maritalStatus: 'Never Married',
  //   community: 'Open to all',
  //   religion: 'Muslim',
  //   motherTongue: 'Urdu, Punjabi, English',
  //   country: 'Open to all',
  //   // stateLivingIn: 'Open to all',
  //   // residencyStatus: 'Open to all',
  //   education: 'Open to all',
  //   workingWith: 'Open to all',
  //   occupation: 'Open to all',
  //   minIncome: 'Open to all',
  //   postedBy: 'Open to all',
  //   diet: 'Open to all',
  // });

  const [preferences2, setPreferences2] = useState({})
  // const [preferences, setPreferences] = useState({
  // minAge: partner?.minAge,
  // maxAge: partner?.maxAge,
  // minHeight: partner?.minHeight,
  // maxHeight: partner?.maxHeight,
  // maritalStatus: partner?.status,
  // community: partner?.community,
  // religion: partner?.religion,
  // motherTongue: partner?.motherTongue,
  // country: partner?.country,
  // // stateLivingIn: 'Open to all',
  // // residencyStatus: 'Open to all',
  // education: partner?.education,
  // workingWith: partner?.workingWith,
  // occupation: partner?.occupation,
  // minIncome: partner?.minIncome,
  // postedBy: partner?.postedBy,
  // diet: partner?.diet,
  // });

  const [editingField, setEditingField] = useState(null);
  const [newOption, setNewOption] = useState('');
  const [communityOptions, setCommunityOptions] = useState([]);
  const fieldOptions = {
    // 'Open to all', 
    ageRange: ['29 to 35', '36 to 40', '41 to 45'], // Example options for ageRange
    // 'Open to all',
    maritalStatus: [ 'Single', 'Never Married', 'Married', 'Divorced', 'Widowed', 'Awaiting Divorce', 'Annulled'],
    religion: [
      'Muslim',
      'Christian',
      'Jewish',
      'Hindu',
      'Sikh',
      'Parsi',
      'Jain',
      'Buddhist',
      'No Religion',
      'Spiritual - not religious',
      'Other'
    ],
    // 'Open to all',
    diet: [ 'Veg', 'Non-veg', 'Eggetarian'],
    // 'Open to all',
    postedBy: [ 'Self', 'Parent / Guardian', 'Friends / Siblings'],
    occupation: ['Accounting, Banking & Finance', 'Administration & HR', 'Advertising, Media & Entertainment', 'Agriculture',
      'Airline & Aviation', 'Architecture & Design', 'Artists, Animators & Web Designers', 'Beauty, Fashion & Jewellery Designers',
      'BPO, KPO, & Customer Support',
      'Civil Services / Law Enforcement',
      'Defense',
      'Education & Training',
      'Engineering',
      'Hotel & Hospitality',
      'IT & Software Engineering',
      'Legal',
      'Medical & Healthcare',
      'Merchant Navy',
      'Sales & Marketing',
      'Science',
      'Corporate Professionals',
      'Others',
      'Non Working'
    ],
    workingWith: [
      'Private Company',
      'Government / Public Sector',
      'Defense / Civil Services',
      'Business / Self Employed',
      'Non Working'
    ],
    education: [
      'Doctorate',
      'Masters',
      'Bachelor / Undergraduate',
      'Associate / Diploma',
      'High School and below'
    ],
    education: [
      'Doctorate',
      'Masters',
      'Bachelor / Undergraduate',
      'Associate / Diploma',
      'High School and below'
    ],
    // 'Open to all',
    motherTongue: [
      'Hindi',
      'Marathi',
      'Punjabi',
      'Bengali',
      'Gujarati',
      'Urdu',
      'Telugu',
      'Kannada',
      'English',
      'Tamil',
      'Odia',
      'Marwari',
      'Aka',
      'Arabic',
      'Arunachali',
      'Assamese',
      'Awadhi',
      'Baluchi',
      'Bhojpuri',
      'Bhutia',
      'Brahui',
      'Brij',
      'Burmese',
      'Chattisgarhi',
      'Chinese',
      'Coorgi',
      'Dogri',
      'French',
      'Garhwali',
      'Garo',
      'Haryanavi',
      'Himachali/Pahari',
      'Hindko',
      'Kakbarak',
      'Kanauji',
      'Kashmiri',
      'Khandesi',
      'Khasi',
      'Konkani',
      'Koshali',
      'Kumaoni',
      'Kutchi',
      'Ladakhi',
      'Lepcha',
      'Magahi',
      'Maithili',
      'Malay',
      'Malayalam',
      'Manipuri',
      'Miji',
      'Mizo',
      'Monpa',
      'Nepali',
      'Pashto',
      'Persian',
      'Rajasthani',
      'Russian',
      'Sanskrit',
      'Santhali',
      'Seraiki',
      'Sindhi',
      'Sinhala',
      'Sourashtra',
      'Spanish',
      'Swedish',
      'Tagalog',
      'Tulu',
      'Other'
    ],
    // 'Open to all',
    country: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antigua & Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bonaire",
      "Bosnia & Herzegovina",
      "Botswana",
      "Brazil",
      "British Indian Ocean Ter",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Canary Islands",
      "Cape Verde",
      "Cayman Islands",
      "Central African Republic",
      "Chad",
      "Channel Islands",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos Island",
      "Colombia",
      "Comoros",
      "Congo",
      "Cook Islands",
      "Costa Rica",
      "Cote DIvoire",
      "Croatia",
      "Cuba",
      "Curaco",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Ter",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Great Britain",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guinea",
      "Guyana",
      "Haiti",
      "Hawaii",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "Indonesia",
      "India",
      "Iran",
      "Iraq",
      "Ireland",
      "Isle of Man",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea North",
      "Korea South",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macau",
      "Macedonia",
      "Madagascar",
      "Malaysia",
      "Malawi",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Midway Islands",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montserrat",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Nambia",
      "Nauru",
      "Nepal",
      "Netherland Antilles",
      "Netherlands (Holland, Europe)",
      "Nevis",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau Island",
      "Palestine",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Pitcairn Island",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Republic of Montenegro",
      "Republic of Serbia",
      "Reunion",
      "Romania",
      "Russia",
      "Rwanda",
      "St Barthelemy",
      "St Eustatius",
      "St Helena",
      "St Kitts-Nevis",
      "St Lucia",
      "St Maarten",
      "St Pierre & Miquelon",
      "St Vincent & Grenadines",
      "Saipan",
      "Samoa",
      "Samoa American",
      "San Marino",
      "Sao Tome & Principe",
      "Saudi Arabia",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Tahiti",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad & Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks & Caicos Is",
      "Tuvalu",
      "Uganda",
      "United Kingdom",
      "Ukraine",
      "United Arab Emirates",
      "United States of America",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City State",
      "Venezuela",
      "Vietnam",
      "Virgin Islands (Brit)",
      "Virgin Islands (USA)",
      "Wake Island",
      "Wallis & Futuna Is",
      "Yemen",
      "Zaire",
      "Zambia",
      "Zimbabwe"
    ],
  };

  const handleEdit = (field) => {
    setEditingField(field);
    setNewOption(preferences[field]);
  };

  const handleInputChange = (e,field) => {
    setNewOption(e.target.value);
    // setPreferences({ ...preferences, [e.target.name]: newOption });
    // let updatedOptions = [...preferences[field]];
    console.log('e.target.name',e.target.name)
    console.log('e.target.value',e.target.value)
    console.log('field',field)
    // setPreferences({ ...preferences, [field]: updatedOptions });
    setPreferences({ ...preferences, [field]: e.target.value });
    console.log('preferences', preferences)
  };

  const handleConfirm = async () => {
    if (window.confirm(`Confirm changes to ${editingField}?`)) {
      setPreferences({ ...preferences, [editingField]: newOption });
      console.log('preferences', preferences)
      console.log('newOption', newOption)
      console.log('editingField', editingField)
      // setPreferences2(preferences.preferences);
      // console.log('preferences2', preferences2)
      // console.log('newOption', newOption)

    //   let val = await fetch(`/partner/${keyId}`,
    try{
      let val = await fetch(`/privacy/${keyId}`,
        {
          method: "PUT",
          headers: {
            "content-Type": "application/json"
          },
          body: JSON.stringify(preferences)
        }
      )
      console.log('val', val)
      if (val.status === 200) {
        showToast()
        window.location.reload();
      }
      else {
        toast.error('Request failed!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000 // Duration in milliseconds
        });
      }
      setEditingField(null);
}catch(Err)
{
  
}
    }
  };

  const handleCancel = () => {
    setEditingField(null);
  };

  // 'Open to all',
  const minIncomeRanges = [
    'Less than $20,000',
    '$20,000 - $40,000',
    '$40,000 - $60,000',
    '$60,000 - $80,000',
    '$80,000 - $100,000',
    '$100,000 - $150,000',
    '$150,000 - $200,000',
    '$200,000 - $300,000',
    '$300,000 - $500,000',
    'More than $500,000',
  ];

  const updateCommunityOptions = (religion) => {
    // Simulated dynamic community options based on religion
    let updatedCommunities = [];
    if (religion === 'Muslim') {
      updatedCommunities = ['CommunityA', 'CommunityB', 'CommunityC'];
    } else if (religion === 'Christian') {
      updatedCommunities = ['CommunityX', 'CommunityY', 'CommunityZ'];
    }
    setCommunityOptions(updatedCommunities);
  };
  const handleCheckboxChange = (e, field) => {
    const { value, checked } = e.target;
    let updatedOptions = [...preferences[field]];
    console.log('updatedOptions', updatedOptions)
    if (checked) {
      updatedOptions.push(value);
      console.log('updatedOptions', updatedOptions)
    } else {
      updatedOptions = updatedOptions.filter((option) => option !== value);
      console.log('updatedOptions', updatedOptions)
    }

    setPreferences({ ...preferences, [field]: updatedOptions });
    console.log('preferences', preferences)
  };
  useEffect(() => {
    updateCommunityOptions(preferences.religion);
  }, [preferences.religion]);
  useEffect(() => {
    fetchDetails()
  }, []);

  return (
    <div className="Profile_Preferences_Header2">
      <div className="partner-preferences">
        <h2>Contact Filters</h2>
        <ToastContainer />
        {Object.entries(preferences).map(([field, value]) => (
          <div className="preference-item" key={field}>
            <span>{field}</span>
            {editingField === field ? (
              <div className='container'>
                {field === 'community' ? (
                  <select name={field} value={newOption} onChange={handleInputChange}>
                    {communityOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}

                  </select>
                ) :
                  field.includes('minAge')? (
                    //  field ==='Age' || field==='Height' ? (
                    <div className='d-flex'>
                      <input
                        name={field}
                        type="text"
                        value={newOption}
                        // onChange={handleInputChange}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    </div>
                  ) :
                  field.includes('maxAge')? (
                    //  field ==='Age' || field==='Height' ? (
                    <div className='d-flex'>
                      <input
                        name={field}
                        type="text"
                        value={newOption}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    </div>
                  ) :
                  field.includes('minHeight') ? (
                    //  field ==='Age' || field==='Height' ? (
                    <div className='d-flex'>
                      <input
                        name={field}
                        type="text"
                        value={newOption}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    </div>
                  ) :
                  field.includes('maxHeight')? (
                    //  field ==='Age' || field==='Height' ? (
                    <div className='d-flex'>
                      <input
                        name={field}
                        type="text"
                        value={newOption}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    </div>
                  ) :
                    field.includes('minIncome') ? (
                      <div className='d-flex'>
                        <select
                          name={field}
                          value={newOption}
                          // onChange={(e) => setNewOption(e.target.value)}
                          onChange={(e) => {
                            setNewOption(e.target.value)
                            let value = e.target.value
                            const incomeRange = value.split(' - ');
                            // Removing non-digit characters and extracting the numbers
                            const minIncomeValue = parseInt(incomeRange[0].replace(/\D/g, ''), 10);
                            const maxIncomeValue = parseInt(incomeRange[1].replace(/\D/g, ''), 10);
                            console.log('minIncomeValue', minIncomeValue)
                            console.log('maxIncomeValue', maxIncomeValue)
                            setPreferences({ ...preferences, minIncome: minIncomeValue, maxIncome: maxIncomeValue });
                          }}


                        >
                          {minIncomeRanges.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                      : (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center' }}>
                            {fieldOptions[field] &&
                              fieldOptions[field].map((option) => (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                  <div>
                                    {/* <input onChange={(e) => handleCheckboxChange(e, field)} checked={preferences[field].includes(option)} style={{ height: '12px', width: '16px', margin: '1px' }} type="checkbox" key={option} value={option} name={field} id={option} /> */}
                                    {/* <input onChange={(e) => handleCheckboxChange(e, field)} checked={Array.isArray(preferences[field]) ? preferences[field].includes(option) : false} style={{ height: '12px', width: '16px', margin: '1px' }} type="checkbox" key={option} value={option} name={field} id={option} /> */}
                                    <input
                                      onChange={(e) => handleCheckboxChange(e, field)}
                                      checked={
                                        Array.isArray(preferences[field])
                                          ? preferences[field].includes(option)
                                          : false
                                      }
                                      style={{ height: '12px', width: '16px', margin: '1px' }}
                                      type="checkbox"
                                      key={option}
                                      value={option}
                                      name={field}
                                      id={option}
                                    />
                                  </div>
                                  <div>
                                    {/* {field} */}
                                    <label htmlFor={option}>{option}</label>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                <div className='mt-2 w-50'>
                  <button className='custom-button w-5 m-1' onClick={handleConfirm}>Confirm</button>
                  <button className='custom-button w-5' onClick={handleCancel}>Cancel</button>
                </div>
              </div>
            ) : (
              <span>{value}</span>
            )}
            <i className="edit-icon" onClick={() => handleEdit(field)}>
              Edit
            </i>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Contact_Filter_Header2;
