import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const User_Panel_Profile_View = () => {
    const showToast = () => {
        toast.success('Request successful!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
        });
    };
    // let [newUser, modifyUser] = useState({})
    let [user, setUser] = useState({})
    let navigate = useNavigate()
    const [formErrors, setFormErrors] = useState({});
    let validateForm = () => {
        const errors = {};
        if (!user.name) {
            errors.name = 'Name is required';
        }
        else if (!/^[a-zA-Z\s]+$/.test(user.name)) {
            errors.name = 'Name should only contain alphabets';
        }
        if (!user.email) {
            errors.email = 'email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(user.email)) {
            errors.email = 'Email is invalid';
        }
        if (!user.description) {
            errors.description = 'description is required';
        }
        if (!user.country) {
            errors.country = 'country is required';
        }
        if (!user.city) {
            errors.city = 'city is required';
        }
        if (!user.occupation) {
            errors.occupation = 'occupation is required';
        }
        if (!user.birthPlace) {
            errors.birthPlace = 'birthPlace is required';
        }
        if (!user.status) {
            errors.status = 'status is required';
        }
        if (!user.dob) {
            errors.dob = 'dob is required';
        }
        //   <input style={{color:'black'}} onChange={inputEvent} value={user?.birthPlace} name="birthPlace" type="text" placeholder="Birthplace" />
        { formErrors.name && <p className="error">{formErrors.name}</p> }
        // if (!user.age) {
        //   errors.age = 'age is required';
        // }
        if (!user.height) {
            errors.height = 'height is required';
        }
        if (!user.postedBy) {
            errors.postedBy = 'posted By is required';
        }
        if (!user.religion) {
            errors.religion = 'religion is required';
        }
        if (!user.motherTongue) {
            errors.motherTongue = 'mother Tongue is required';
        }
        if (!user.diet) {
            errors.diet = 'diet is required';
        }
        if (!user.healthInformation) {
            errors.healthInformation = 'health Information is required';
        }
        if (!user.fatherStatus) {
            errors.fatherStatus = 'father Status is required';
        }
        if (!user.motherStatus) {
            errors.motherStatus = 'mother Status is required';
        }
        if (!user.familyType) {
            errors.familyType = 'family Type is required';
        }
        if (!user.jobPlace) {
            errors.jobPlace = 'job Place is required';
        }
        if (!user.jobStartedYear) {
            errors.jobStartedYear = 'job Started Year is required';
        }
        if (!user.educationTitle) {
            errors.educationTitle = 'education Title is required';
        }

        setFormErrors(errors);
        console.log('here_formErrors', formErrors);
        return Object.keys(errors).length === 0;
    }

    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let fetchUser = async () => {
        try {
            let val = await axios.get(`/user/${keyId}`)
            console.log('user val.data', val.data)
            setUser(val.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchUser()
    }, [])

    let inputEvent = (e) => {
        if (e.target.name === 'dob') {
            const userDateOfBirth = e.target.value; // Replace this with the user's date of birth
            const currentDate = new Date();
            const birthYear = new Date(userDateOfBirth).getFullYear();
            const currentYear = currentDate.getFullYear();
            const age = currentYear - birthYear;
            console.log('age', age)
            // setUser({ ...user, age: age })
            // setUser({ ...user, dob: userDateOfBirth })
            setUser({ ...user, dob: userDateOfBirth, age: age });
            console.log('user', user)
            console.log('user.dob', user.dob)
            console.log('user.age', user.age)
        }
        else {
            console.log('user', user)
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }
    let Discard = () => {
        let y = window.confirm('Continue without saving changes?')
        if (y) {
            window.location.reload();
        }
    }
    let submitEvent = async (e) => {
        e.preventDefault()
        const isValid = validateForm();
        console.log('user', user)
        if (isValid) {
            try {
                let val = await fetch(`user/${keyId}`, {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(user)
                })
                if (val.status === 200) {
                    showToast();
                    window.location.reload();
                }
                else {
                    toast.error('Request failed!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000 // Duration in milliseconds
                    });
                }
            } catch (error) {
                console.log('error', error)
            }
        }
        else {
            // alert('Error! Fill all fields to proceed');
            toast.error('Error! Fill all fields to proceed!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000 // Duration in milliseconds
            });
        }
    }
    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <div>
            <section className="user-setting-section">
                <div className="container">
                    <ToastContainer />
                    <div className="row">
                        <div className="col-xl-12 col-md-7 ">

                            <div className="input-info-box mt-30">

                                <div className="header">
                                    {user?.name} ({user?._id})

                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="my-input-box">
                                                {/* {user?.photos} */}
                                                <img style={{ height: "220px",width:"100%" }} src={`/uploads/${user?.photos}`} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-9" style={{display:'flex',flexWrap:'wrap',justifyContent:"space-between"}}>
                                        {/* <div className="col-md-3">
                                            <div className="my-input-box">
                                                <p><i> <b>  Email: </b> {user?.email || 'none' }</i></p>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Country: </b> {user?.country || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  City: </b> {user?.city || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Occupation: </b> {user?.occupation || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Date of birth: </b> {user?.dob || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Status: </b> {user?.status || 'none'}</i></p>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Birth Place: </b> {user?.birthPlace || 'none'}</i></p>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Height: </b> {user?.height || 'none'}</i></p>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Email: </b> {user?.email}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Age: </b> {user?.age || 'none'}</i></p>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Posted by: </b> {user?.postedBy || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Diet: </b> {user?.diet || 'none'}</i></p>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="my-input-box">
                                                <p><i> <b>  Religion: </b> {user?.religion}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="my-input-box">
                                                <p><i> <b>  : </b> {user?.religion}</i></p>
                                            </div>
                                        </div> */}
                                            {/* <p><i> <b>  Email: </b> {user?.email}</i></p> */}
                                            {/* <div >
                                                <div className="my-input-box"> */}
                                                    {/* <p><i> <b>  Country: </b> {user?.country}</i></p> */}
                                                {/* </div>
                                            </div> */}
                                            {/* <div >
                                                <div className="my-input-box"> */}
                                                    {/* <p><i> <b>  City: </b> {user?.city}</i></p> */}
                                                {/* </div>
                                            </div> */}
                                            {/* <div >
                                                <div className="my-input-box"> */}
                                                    {/* <p><i> <b>  Date of birth: </b> {user?.dob}</i></p> */}
                                                {/* </div>
                                            </div> */}
                                            {/* <div >
                                                <div className="my-input-box"> */}
                                                    {/* <p><i> <b>  Occupation: </b> {user?.occupation}</i></p> */}
                                                {/* </div>
                                            </div> */}
                                            {/* <div >
                                                <div className="my-input-box"> */}
                                                    {/* <p><i> <b>  Status: </b> {user?.status}</i></p> */}
                                                {/* </div>
                                            </div> */}
                                            {/* <div >
                                                <div className="my-input-box"> */}
                                                    {/* <p><i> <b>  Birth Place: </b> {user?.birthPlace}</i></p> */}
                                                {/* </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="input-info-box mt-30">
                                {/* <div className='' style={{color:"white",display:'flex',justifyContent:'space-between',padding:"0% 1%",backgroundColor: "rgb(50, 22, 145)", alignItems: "center", justifyItems: "center", justifySelf: "center", alignSelf: "center"}}> */}
                                <div className='d-flex justify-content-between align-items-center text-white pl-2 pr-2' style={{ backgroundColor: "rgb(50, 22, 145)" }}>
                                    <div style={{ fontSize: "19px", fontWeight: "bold" }} className=" mt-1">
                                        About
                                    </div>
                                    <p className='mt-1' onClick={() => navigate('/DashboardProfile')} style={{ cursor: "pointer" }}>Edit</p>
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <p>{user?.description  }</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                {/* <label className='' for="">Profile Name:</label> */}
                                                <p><i> <b>  Name: </b> {user?.name || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                {/* <label for="">Public Email:</label> */}
                                                <p><i> <b>  Email: </b> {user?.email || 'none'}</i></p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                {/* <label for="">Country</label> */}
                                                <p><i> <b>  Country: </b> {user?.country || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                {/* <label for="city">City</label> */}
                                                <p><i> <b>  City: </b> {user?.city || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Date of birth: </b> {user?.dob || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Occupation: </b> {user?.occupation || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Status: </b> {user?.status || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Birth Place: </b> {user?.birthPlace || 'none'}</i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className='d-flex justify-content-between align-items-center text-white pl-2 pr-2 mb-1' style={{ backgroundColor: "rgb(50, 22, 145)" }}>
                                    <div style={{ fontSize: "19px", fontWeight: "bold" }} className=" mt-1">
                                        More Info
                                    </div>
                                    <p className='mt-1' onClick={() => navigate('/DashboardProfile')} style={{ cursor: "pointer" }}>Edit</p>
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Height: </b> {user?.height || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                {/* <textarea onChange={inputEvent} value={user?.postedBy} name="postedBy" placeholder="Profile Posted By"></textarea> */}
                                                <p><i> <b>  Posted by: </b> {user?.postedBy || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Religion: </b> {user?.religion || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Mother Tongue: </b> {user?.motherTongue || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Diet: </b> {user?.diet || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Health Information: </b> {user?.healthInformation || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Smoke: </b> {user?.smoke || 'not specified'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Drink: </b> {user?.drink || 'not specified'}</i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className='d-flex justify-content-between align-items-center text-white pl-2 pr-2 mb-1' style={{ backgroundColor: "rgb(50, 22, 145)" }}>
                                    <div style={{ fontSize: "19px", fontWeight: "bold" }} className=" mt-1">
                                        Family Details
                                    </div>
                                    <p className='mt-1' onClick={() => navigate('/DashboardProfile')} style={{ cursor: "pointer" }}>Edit</p>
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Father's Status: </b> {user?.fatherStatus  || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Mother's Status: </b> {user?.motherStatus  || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Brothers: </b> {user?.brothers  || 'not specified'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Sisters: </b> {user?.sisters  || 'not specified'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                {/* <label for="">Family Type </label> */}
                                                <p><i> <b>  Family type: </b> {user?.familyType  || 'none'}</i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className='d-flex justify-content-between align-items-center text-white pl-2 pr-2 mb-1' style={{ backgroundColor: "rgb(50, 22, 145)" }}>
                                    <div style={{ fontSize: "19px", fontWeight: "bold" }} className=" mt-1">
                                        Jobs & education
                                    </div>
                                    <p className='mt-1' onClick={() => navigate('/DashboardProfile')} style={{ cursor: "pointer" }}>Edit</p>
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Job Place: </b> {user?.jobPlace  || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Year started: </b> {user?.jobStartedYear  || 'none'}</i></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <p><i> <b>  Education: </b> {user?.educationTitle  || 'none'}</i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default User_Panel_Profile_View