import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../Style/Dashboard_Home/DashboardMyContact_Header.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DashboardMyContact_Header = () => {
  let [contactDetails, updateContactDetails] = useState()
  let [profile, setProfile] = useState()
  let navigate = useNavigate()
  const [formErrors, setFormErrors] = useState({});
  let validateForm = () => {
    const errors = {};
    if (!contactDetails.contactPersonName) {
      errors.contactPersonName = 'Name is required';
    }
    else if (!/^[a-zA-Z\s]+$/.test(contactDetails.contactPersonName)) {
      errors.contactPersonName = 'Name should only contain alphabets';
    }
    if (!contactDetails.contactPersonRelationship) {
      errors.contactPersonRelationship = 'Relationship is required';
    }
    else if (!/^[a-zA-Z\s]+$/.test(contactDetails.contactPersonRelationship)) {
      errors.contactPersonRelationship = 'Relationship should only contain alphabets';
    }
    if (!contactDetails.minCallTime) {
      errors.minCallTime = 'Call time is required';
    }
    if (!contactDetails.maxCallTime) {
      errors.maxCallTime = 'Call time is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  let inputEvent = (e) => {
    updateContactDetails({ ...contactDetails, [e.target.name]: e.target.value })
  }
  let inputEvent2 = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value })
  }
  const showToast = () => {
    toast.success('Contact updated successfully!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  };
  let submitEvent = async (e) => {
    e.preventDefault()
    let isValid = validateForm()
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    if (isValid) {
      try{
      let val = await fetch(`/User/${keyId}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(contactDetails)
      })
      if (val.status === 200) {
        showToast();
        setTimeout(() => {
          window.location.reload()
        }, 1940);
      }
      else {
        toast.error('Request failed!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000
        });
      }}
      catch(err)
      {

      }
    }
  }
  let validateField = () => {
    const errors = {};
    if (!profile._id) {
      errors._id = '_id is required';
    }
    if (profile?._id?.length < 24) {
      errors._id = 'ID should be minimum 24 characters';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }
  let getProfile = async (e) => {
    e.preventDefault()
    try {
      let isValid = validateField()
      if (isValid) {
        let val = await axios.get(`/User/${profile?._id}`);
        if (val.status === 200 || val.status === 202) {
          localStorage.setItem('singleProfileId', JSON.stringify(profile?._id))
          toast.success('Id found is successfull!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, 
          });
          setTimeout(() => {
            navigate('/singleprofile') 
          }, 1940);
        }
        else {
          toast.error('No ID found!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000 
          });
        }
      }
    }
    catch (err) {
      toast.error('No ID found!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000 
      });
      console.log(err)
    }
  }
  let fetchContactDetails = async () => {
    try {
      let keyId = JSON.parse(localStorage.getItem('keyId'))
      let val = await axios.get(`/User/${keyId}`)
      updateContactDetails(val.data)
    }
    catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchContactDetails()
  }, [])
  return (
    <div className='DashboardMyContact_Header'>
      <ToastContainer />
      <div className="quick-links-container">
        <div className="sidebar">
          <h2>Quick Links</h2>
          <ul>
            <li onClick={() => { navigate('/newMatches') }} className='section' >New Matches</li >
            <li onClick={() => { navigate('/myMatches') }} className='section' >My Matches</li >
            <li onClick={() => { navigate('/nearMatches') }} className='section' >Near Me</li >
            <li onClick={() => { navigate('/Referrals') }} className='section' >Refer A Friend</li >
            <li onClick={() => { navigate('/DashboardProfile') }} className='section' >My Contact Details</li >
            {/* <li className='section'  >Shortlists & more</li >
            <li className='section' >My Saved Searches</li >
            <li className='section' >My Help</li >
            <li className='section' >Need Help?</li >
            <li className='section' >Security Tips</li > */}
          </ul>
          <h2>Profile ID Search</h2>
          {formErrors._id && <p className="error">{formErrors._id}</p>}
          <div className="profile-search">
            <input type="text" onChange={inputEvent2} name="_id" placeholder="Enter Profile ID" />
            <button className='custom-button' onClick={getProfile} >Go</button>
          </div>
        </div>
        <div className="main-content">
          <div className="contact-details">
            <form>
              <label htmlFor="contact-name">Name of contact person</label>
              <input onChange={inputEvent} value={contactDetails?.contactPersonName} name="contactPersonName" type="text" id="contact-name" />
              {formErrors.contactPersonName && <p className="error">{formErrors.contactPersonName}</p>}
              <label htmlFor="relationship">Relationship with the member</label>
              <input onChange={inputEvent} value={contactDetails?.contactPersonRelationship} name="contactPersonRelationship" type="text" id="relationship" />
              {formErrors.contactPersonRelationship && <p className="error">{formErrors.contactPersonRelationship}</p>}
              <label htmlFor="convenient-time">Convenient time to call</label>
              <label htmlFor="minTime">From</label>
              <input onChange={inputEvent} name="minCallTime" value={contactDetails?.minCallTime} type="time" id="minTime" />
              {formErrors.minCallTime && <p className="error">{formErrors.minCallTime}</p>}
              <label htmlFor="maxTime">To</label>
              <input onChange={inputEvent} name="maxCallTime" value={contactDetails?.maxCallTime} type="time" id="maxTime" />
              {formErrors.maxCallTime && <p className="error">{formErrors.maxCallTime}</p>}
              <button onClick={submitEvent} className="save-button custom-button">Save Changes</button>
              {/* <label htmlFor="contact-setting">Contact display setting</label>
              <select id="contact-setting">
              <option value="no-one">No one (Matches won't be able to call you)</option>
            </select> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardMyContact_Header