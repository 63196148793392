import React, { useEffect, useState } from 'react'
import Membership_Breadcrumb from '../Components/Membership/Membership_Breadcrumb'
import Header from '../Components/Home/Header'
import Memebership_Header from '../Components/Membership/Memebership_Header'
import Membership_Faq from '../Components/Membership/Membership_Faq'
import Newsletter from '../Components/Home/Newsletter'
import My_Plan from '../Plans_Components/My_Plan'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Membership = ({ value,socket }) => {
  let navigate=useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
    value === 'allplan' ?
      <div>
        <Header />
        <Membership_Breadcrumb />
        <Memebership_Header socket={socket} />
        <Membership_Faq />
        <Newsletter />
      </div>
      :
      <div>
        <Header />
        <Membership_Breadcrumb />
        {/* <Memebership_Header /> */}
        <My_Plan socket={socket} />
        {/* <Membership_Faq /> */}
        <Newsletter />
      </div>
      :navigate('/login')
  )
}

export default Membership