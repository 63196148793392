import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
const Admin_Tickets_Table = ({ data, socket }) => {
    let params = useParams();
    let navigate = useNavigate();
    let ticket_link = JSON.parse(localStorage.getItem('ticket_link'));
    console.log('ticket_link', ticket_link)
    console.log('params', params)
    console.log('data here')
    console.log('data here', data)
    console.log('socket here', socket)
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let [fetchedData, setFetchedData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [userPlan, setUserPlan] = useState({});
    const [newData, setNewData] = useState([]);
    const [details, setDetails] = useState([]);
    const handleClose = () => setShowModal(false);
    const handleClose2 = () => setShowDropdown(false);
    const handleClose3 = () => setShowNote(false);
    const handleClose4 = () => setShowDropdown2(false);
    const handleShow = (ele) => {
        setShowModal(true)
        setFetchedData(ele)
    };
    let openTicket = [], closedTicket = []
    data?.map((ele, ind) => {
        if (ele.openTicket === true) {
            openTicket.push(ele);
        }
        if (ele.closedTicket === true) {
            closedTicket.push(ele);
        }
    })
    let ticketFunc = () => {
        if (ticket_link === 'open') {
            setDetails(openTicket)
        }
        else if (ticket_link === 'closed') {
            setDetails(closedTicket)
        }
    }
    let deleteAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/ticket/${id}`,
                    {
                        method: "Delete"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (Err) {

            }
        }
    }
    const handleEdit = (fetchedData) => {
        setShowDropdown(true); // Show the dropdown when the button is clicked
        console.log('fetchedData_hun', fetchedData)
        setUserPlan(fetchedData)
    };
    const handleEdit2 = (fetchedData) => {
        setShowDropdown2(true); // Show the dropdown when the button is clicked
        setUserPlan(fetchedData)
    };
    const addNote = (fetchedData) => {
        setShowNote(true); // Show the dropdown when the button is clicked
    };

    let changePlan = (e) => {
        // else {
        setUserPlan({ ...userPlan, [e.target.name]: e.target.value })
        // }
    }
    let submitPlan = async (e) => {
        e.preventDefault()
        console.log('Moye Moye', fetchedData)
        console.log('Moye Moye', fetchedData._id)
        // console.log(fetchedData._id)
        let id = fetchedData._id;
        // let id = fetchedData?.queries[0]?.id;
        // let val = await fetch(`/ticket/${fetchedData?._id}`, {
        // method: "PUT",
        try {
            let val = await fetch(`/ticket`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(userPlan, id)
            })
            if (val.status === 200 || val.status === 201) {
                alert('changes updated!')
                // props?.socket?.emit("sendNotification", {
                console.log('sendNotification here socket', socket)
                socket?.emit("sendNotification", {
                    sender: keyId,
                    // receiverName: receiver,
                    receiverId: userPlan?.userId,
                    type: 'admin ticket reply',
                    content: userPlan?.reply,
                    chatId: userPlan?._id,
                    // date: date
                });
                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (Err) { }
    }

    let newDataPlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            console.log('balue', value)
            setNewData({ ...newData, [e.target.name]: value })
        }
        else {
            setNewData({ ...newData, [e.target.name]: e.target.value })
        }
    }
    let submitNewDataPlan = async (e) => {
        e.preventDefault()
        // console.log('notePlan', notePlan)
        console.log('submitNewDataPlan', newData)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/ticket`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(newData)
            })
            if (val.status === 200) {
                alert('new plan added!')
                window.location.reload()
            }
            else { alert('error!') }
        } catch (error) {

        }
    }
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    let approveStory = async (data) => {
        let y;
        {
            data.approval ?
                y = window.confirm(`Do you want to remove its approval?`)
                :
                y = window.confirm(`Do you want to approve?`)
        }
        if (y) {
            let approval;
            if (data?.approval) {
                approval = false
            }
            else {
                approval = true
            }
            try {
                let val = await fetch(`/ticket/${fetchedData?._id}`, {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({ approval })
                })
                if (val.status === 200) {
                    alert('changes updated!')
                    window.location.reload()
                }
                else { alert('error!') }
            }
            catch (Err) {

            }
        }

    }

    useEffect(() => {
        ticketFunc()
    }, [ticket_link])
    let closeTicket = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/ticket/close/${id}`,
                    {
                        method: "PUT",
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({})
                    }
                )
                if (val.status === 200) {
                    alert('closed')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            } catch (error) {

            }
        }

    }
    let reOpenTicket = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/ticket/open/${id}`,
                    {
                        method: "PUT",
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({})
                    }
                )
                if (val.status === 200) {
                    alert('opened')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            } catch (error) {

            }
        }

    }
    return (
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            {/* <h4>Queries</h4> */}
                            <div class="card-header-form">
                                {/* <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" />
                                        <div class="input-group-btn">
                                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        {/* <Button className="custom-button w-25 mx-auto mt-1 " style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                            Create Story
                        </Button> */}
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tr>
                                        {/* <th class="text-center">
                                            <div class="custom-checkbox custom-checkbox-table custom-control">
                                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad"
                                                    class="custom-control-input" id="checkbox-all" />
                                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                                            </div>
                                        </th> */}
                                        {/* <th>LV Number</th>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th>Date Registered</th>
                                        <th>Expiry Date</th>
                                        <th>Status</th>
                                        <th>Email Address</th> */}
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        {/* <th>Message</th> */}
                                        {/* <th>Approval</th> */}
                                        <th>Actions</th>
                                    </tr>

                                    {
                                        details?.map((ele, ind) => {
                                            return (
                                                <tr>
                                                    {/* <td class="p-0 text-center">
                                                        <div class="custom-checkbox custom-control">
                                                            <input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
                                                                id="checkbox-1" />
                                                            <label for="checkbox-1" class="custom-control-label">&nbsp;</label>
                                                        </div>
                                                    </td> */}
                                                    <td>{ele?._id}</td>
                                                    <td class="text-truncate">
                                                        {ele?.name}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {ele?.email}
                                                    </td>
                                                    {/* <td class="align-middle">
                                                        {ele?.message}
                                                    </td> */}
                                                    {/* <td class="align-middle">
                                                        <td>{setDates(ele?.createdAt)?.toString()?.split('T')[0]}</td>
                                                    </td> */}
                                                    {/* {console.log('ele.approval', ele.approval)} */}
                                                    {/* {ele.approval ?
                                                        <td>
                                                            <div class="badge badge-success">Approved</div>
                                                        </td>
                                                        : <td>
                                                            <div class="badge badge-success">Not Approved</div>
                                                        </td>} */}
                                                    <td>
                                                        <a href="#" className="btn btn-outline-primary" onClick={() => handleShow(ele)}>
                                                            Detail
                                                        </a>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Actions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => handleEdit2(fetchedData)}>
                                            View
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => deleteAccount(fetchedData?._id)}>
                                            Delete
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit(fetchedData) }}>
                                            Reply
                                        </Button>
                                        {ticket_link === 'open' ?
                                            <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { closeTicket(fetchedData._id) }}>
                                                Close Ticket
                                            </Button>
                                            :
                                            <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { reOpenTicket(fetchedData._id) }}>
                                                ReOpen Ticket
                                            </Button>}
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showDropdown} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        {/* <Modal.Title>Select a Plan</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                {/* <Form.Label>Reply:</Form.Label> */}
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                {/* <label for="name" class="">Name</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="name" id="" value={userPlan?.name} />
                                                <label for="name" class="">Email</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="partnerName" id="" value={userPlan?.partnerName} />
                                                <label for="name" class="">Meet Date</label>
                                                <input onChange={changePlan} type="date" className='form-control' name="meetDate" id="" value={userPlan?.meetDate} />
                                                <label for="name" class="">Wedding Date</label>
                                                <input onChange={changePlan} type="date" className='form-control' name="weddingDate" id="" value={userPlan?.weddingDate} /> */}
                                                <label for="name" class="">Write Reply:</label>
                                                <br />
                                                {/* <input onChange={changePlan} type="text" className='form-control' name="description" id="" value={userPlan?.description} /> */}
                                                {/* {userPlan?queries?.message} */}
                                                <textarea onChange={changePlan} className='form-control' name="reply" id="" cols="30" rows="3"></textarea>
                                                {/* <label for="name" class="">Features</label> */}
                                                {/* <input onChange={changePlan}  type="text" className='form-control' name="features" id="" value={userPlan?.features} /> */}
                                                {/* </FormControl> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Sent
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showDropdown2} onHide={handleClose4}>
                                    <Modal.Header closeButton>
                                        {/* <Modal.Title>Select a Plan</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                {/* <Form.Label>Reply:</Form.Label> */}
                                                {/* <br /> */}
                                                {/* <label for="name" class="">MEssages:</label> */}
                                                {/* <br /> */}
                                                {/* {userPlan?.queries?.map((ele,ind)=>{
                                                    return(
                                                        <>
                                                        <label for="name" class=""><b>User Query:</b> </label>
                                                        <p>{ele?.message}</p>
                                                        {ele?.reply?
                                                        <>
                                                        <label for="name" class=""><b>Admin Response:</b> </label>
                                                        <p>{ele?.reply}</p>
                                                        </>
                                                        :''}
                                                        <i>{setDates(ele?.timestamp)?.toString()?.split('T')[0]}</i>
                                                        </>
                                                    )
                                                })} */}
                                                {/* <label for="name" class="">Features</label> */}
                                                {/* <input onChange={changePlan}  type="text" className='form-control' name="features" id="" value={userPlan?.features} /> */}
                                                {/* </FormControl> */}
                                                <>
                                                    <label for="name" class=""><b>User Query:</b> </label>
                                                    <p>{fetchedData?.message}</p>
                                                    {fetchedData?.reply ?
                                                        <>
                                                            <label for="name" class=""><b>Admin Response:</b> </label>
                                                            <p>{fetchedData?.reply}</p>
                                                        </>
                                                        : ''}
                                                    <i>{setDates(fetchedData?.timestamp)?.toString()?.split('T')[0]}</i>
                                                </>

                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create new Story</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Create:</Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Name</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="name" id="" />
                                                <label for="name" class="">Email</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="partnerName" id="" />
                                                <label for="name" class="">Meet Date</label>
                                                <input onChange={newDataPlan} type="date" className='form-control' name="meetDate" id="" />
                                                <label for="name" class="">Wedding Date</label>
                                                <input onChange={newDataPlan} type="date" className='form-control' name="weddingDate" id="" />
                                                <label for="name" class="">Description</label>
                                                <textarea onChange={newDataPlan} className='form-control' name="description" id="" cols="30" rows="3" ></textarea>
                                                {/* <input onChange={newDataPlan} type="text" className='form-control' name="category" id="" /> */}
                                                {/* <input onChange={changePlan}  type="text" className='form-control' name="features" id="" value={userPlan?.features} /> */}
                                                {/* </FormControl> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="custom-button" variant="secondary" onClick={submitNewDataPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Tickets_Table