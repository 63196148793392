import React, { useEffect, useState } from 'react'
import Contact_Breadcrumb from '../Components/Contact/Contact_Breadcrumb'
import Contact_Header from '../Components/Contact/Contact_Header'
import Newsletter from '../Components/Home/Newsletter'
import Header from '../Components/Home/Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Contact = () => {
  let navigate = useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div>
          <Header />
          <Contact_Breadcrumb />
          <Contact_Header />
          <Newsletter />
        </div>
        : navigate('/login')
  )
}

export default Contact