import React, { useEffect, useState } from 'react'
import Chat_Header from '../Chat_Components/Chat_Header'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Header from '../Components/Home/Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Chat_Home = (props) => {
    console.log('chat_home props',props)
    console.log('socket in chat',props.socket)
    let [isUser, setIsUser] = useState()
    let [mainUser, setMainUser] = useState()
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
    let [loading, setLoading] = useState(true)
    let navigate = useNavigate()
    let fetchMainUser=async()=>{
      try{
      let val=await axios.get(`/user/${keyId}`)
      console.log('mainUser in home',val.data)
      setMainUser(val.data)
    }
    catch(err){
        console.log(err)
      }
    }
    useEffect(() => {
      setIsUser(keyId)
      fetchMainUser(keyId)
      setLoading(false)
    }, [])
    return (
      loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div>
            <Header/>
            <Dashboard_Breadcrumb/>
            <Chat_Header mainUser={mainUser} socket={props.socket} me={props.me} activeUsers={props.activeUsers} />
        </div>
        :navigate('/login')
    )
}

export default Chat_Home