import React, { useState } from 'react'
import '../../../Style/Dashboard_Home/Dashboard_MyPhotos_Top.css'
import MyPhotos_Display from './MyPhotos_Display';
const MyPhotos_Top = ({user}) => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [photoUploadMessage, setPhotoUploadMessage] = useState('');
  let keyId=JSON.parse(localStorage.getItem('keyId'))  
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const allowedFormats = ['jpg', 'jpeg', 'png', 'webp'];

    if (files.length > 5) {
      setPhotoUploadMessage('You can upload a maximum of 5 photos.');
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileFormat = file.name.split('.').pop().toLowerCase();

      if (!allowedFormats.includes(fileFormat)) {
        setPhotoUploadMessage('Please upload photos in jpg, jpeg, png, or webp format.');
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('photos', files[i]);
    }
    try {
      // const response = await fetch(`/user/${keyId}`, {
      //   method: 'PUT',
      //   headers: {
      //     'content-type':'application/json'
      //   },
      //   body: formData,
      // });
      const response = await fetch(`/user/${keyId}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // Handle successful photo upload, if needed
        console.log('Photos uploaded:', data);
        alert('Photos uploaded:');
        window.location.reload()
      } else {
        // Handle error response from the server
        console.error('Failed to upload photos:', response.statusText);
        alert('Failed to upload photos:');
      }
    } catch (error) {
      // Handle fetch error
      console.error('Error during photo upload:', error);
    }
  };

  return (
    <>
      <div className='Dashboard_MyPhotos_Top'>
        <div className="photo-upload">
        {user?.photos?.length!==5?
          <p>Get more responses by uploading up to 5 photos on your profile.</p>
          :
          <p>You can't upload more images.</p>}
          {user?.photos?.length!==5?
          <label className="upload-button custom-button">
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              multiple
              onChange={handleFileUpload}
            />
            Browse Photo
          </label>
          :''}
          <p className="upload-message">{photoUploadMessage}</p>
        </div>
      </div>
    </>
  )
}

export default MyPhotos_Top
