import React, { useEffect, useState } from 'react'
import Card1 from '../Cards/Card1'
import Header from '../Components/Home/Header'
import Members_Breadcrumb from '../Components/Members/Members_Breadcrumb'
import Matches_Nav from '../Matches_Components/Matches_Nav'
import Card2 from '../Cards/Card2'
import Card9 from '../Cards/Card9'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import  axios  from 'axios'

const Recently_Viewed_Matches = (params) => {
  let [isUser, setIsUser] = useState()
  let [user, setUser] = useState()
  let [details, setDetails] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  let fetchDetails = async (keyId) => {
    try {
      let val = await axios.get(`/user/${keyId}`)
      console.log('val.data', val.data)
      setDetails(val?.data)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/User/${keyId}`)
      console.log('val.data', val.data)
      setUser(val.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
    fetchDetails(keyId)
  }, [])

  
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div className='New_Matches'>
        <Header/>
        <Members_Breadcrumb/>
        <Matches_Nav/>
        <div style={{display:'flex',justifyContent:'',flexWrap:'wrap',width:"70%",margin:'3% auto',backgroundColor:'white',}}>
        <Card9 socket={params.socket} mainUser={details} user={user} />
        {/*<Card9/>*/}
        {/* <Card2/> */}
    </div>
    </div>
    :navigate('/')
  )
}

export default Recently_Viewed_Matches