import React, { useEffect, useState } from 'react'
import Header from '../Components/Home/Header'
import User_Panel_Header from '../Components/User_Panel/User_Panel_Header'
import User_Panel_Breadcrumb from '../Components/User_Panel/User_Panel_Breadcrumb'
import Newsletter from '../Components/Home/Newsletter'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'

const User_Panel = () => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div>
        <Header/>
        <User_Panel_Breadcrumb/>
        <User_Panel_Header/>
        <Newsletter/>
    </div>
    :
    navigate('/')
  )
}

export default User_Panel