import React, { useState } from 'react'
import '../../../Style/Dashboard_Home/Dashboard_MyPhotos.css'
const MyPhotos_Header = ({ user }) => {

    return (
        <div className='MyPhotos_Header'>
            <div className="container">
                {user?.photos?.map((ele, ind) => {
                    const handleDelete = async () => {
                        try {
                            const response = await fetch(`/deleteImage/${ele}`, {
                                method: 'DELETE',
                            });
                            if (response.ok) {
                                alert('Image deleted:')
                                window.location.reload()
                                console.log('Image deleted:', ele);
                            } else {
                                alert('Failed to delete image')
                                console.error('Failed to delete image:', response.statusText);
                            }
                        } catch (error) {
                            console.error('Error deleting image:', error);
                        }
                    };
                    return (
                        <div className="card">
                            {/* {ele} */}
                            <img src={`uploads/${ele}`} />
                            <div className="card__head">Dashy</div>
                            <button
                                onClick={handleDelete}
                                style={{
                                    position: 'absolute',
                                    // top: '5px',
                                    // right: '5px',
                                    background: 'none',
                                    border: 'none',
                                    // borderRadius: '21px',
                                    cursor: 'pointer',
                                    fontSize: '26px',
                                    color: 'red',
                                    textAlign:'right'
                                }}
                            >
                                &times; {/* Displaying a close icon using '×' symbol */}
                            </button>
                        </div>
                    )
                })}
                {/* <div className="card">
                    <img src="img/fawad.png"/>
                        <div className="card__head">Cool</div>
                </div>
                <div className="card">
                <img src="img/fawad2.png"/>
                        <div className="card__head">Wonder</div>
                </div>
                <div className="card">
                <img src="img/fawad2.png"/>
                        <div className="card__head">Amaze</div>
                </div>
                <div className="card">
                <img src="img/fawad.png"/>
                        <div className="card__head">Keep it</div>
                </div> */}
            </div>
        </div>
    )
}

export default MyPhotos_Header