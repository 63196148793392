import React, { useEffect, useState } from 'react'
import Profile_Preferences_Header from '../Dashboard/Components/Profile_Preferences/Profile_Preferences_Header'
import Dashboard_Nav from '../Dashboard/Components/Navbar/Dashboard_Nav'
import Header from '../Components/Home/Header'
import Dashboard_Breadcrumb from './Dashboard_Breadcrumb'
import Profile_Preferences_Header2 from '../Dashboard/Components/Profile_Preferences/Profile_Preferences_Header2'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Dashboard_Profile_Preferences = () => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
    (
      <Loader />
    )
    :
    isUser ?
    <div>
      <Header/>
        <Dashboard_Breadcrumb/>
        <Dashboard_Nav/>
        {/* <Profile_Preferences_Header/> */}
        <Profile_Preferences_Header2/>
    </div>
    : navigate('/')
  )
}

export default Dashboard_Profile_Preferences