import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Style/Inbox/Inbox_Navbar.css'
const Inbox_Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
  };
  const location = useLocation();
  return (
      <div className="Admin_Topbar">
          <nav className="navbar" >
              {/* <div className="logo">Your Logo</div> */}
              <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
              </div>
              <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                  <li className="nav-item">
                      {/* (23) */}
                      {/* <Link to="/inboxReceived" className={`nav-link text-white ${location.pathname === '/inboxReceived' ? 'active' : ''}`}>Received </Link> */}
                      <Link to="/inboxReceived-newestFirst" className={`nav-link text-white ${location.pathname === '/inboxReceived-newestFirst' ? 'active' : ''}`}>Received </Link>
                  </li>
                  <li className="nav-item">
                      <Link to="/inboxAccepted" className={`nav-link text-white ${location.pathname === '/inboxAccepted' ? 'active' : ''}`}>Accepted
                      </Link>
                  </li>
                  <li className="nav-item">
                      <Link to="/inboxSent" className={`nav-link text-white ${location.pathname === '/inboxSent' ? 'active' : ''}`}>Sent</Link>
                  </li>
                  <li className="nav-item">
                      <Link to="/inboxDeleted" className={`nav-link text-white ${location.pathname === '/inboxDeleted' ? 'active' : ''}`}>Deleted</Link>
                  </li>

              </ul>
          </nav>
      </div>
  );

};

export default Inbox_Navbar