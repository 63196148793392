import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Matches_Sidebar = () => {
    let [contactDetails, updateContactDetails] = useState()
    let [profile, setProfile] = useState()
    let navigate = useNavigate()
    const [formErrors, setFormErrors] = useState({});
    let validateField = () => {
        const errors = {};
        if (!profile._id) {
            errors._id = '_id is required';
        }
        if (profile?._id?.length < 24) {
            errors._id = 'ID should be minimum 24 characters';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }
    let inputEvent2 = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value })
    }
    let getProfile = async (e) => {
        e.preventDefault()
        try {
            let isValid = validateField()
            if (isValid) {
                let val = await axios.get(`/User/${profile?._id}`);
                if (val.status === 200 || val.status === 202) {
                    localStorage.setItem('singleProfileId', JSON.stringify(profile?._id))
                    toast.success('Id found is successfull!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                    });
                    setTimeout(() => {
                        navigate('/singleprofile')
                    }, 1940);
                }
                else {
                    toast.error('No ID found!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000
                    });
                }
            }
        }
        catch (err) {
            toast.error('No ID found!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000
            });
            console.log(err)
        }
    }

    return (
        <div className='All_Matches_Sidebar'>
        <div className='DashboardMyContact_Header'>
            <ToastContainer />
            <div className="quick-links-container">
                <div>
                    <div className="sidebar">
                        <h2>Quick Links</h2>
                        <ul>
                            <li onClick={() => { navigate('/newMatches') }} className='section' >New Matches</li >
                            <li onClick={() => { navigate('/myMatches') }} className='section' >My Matches</li >
                            <li onClick={() => { navigate('/nearMatches') }} className='section' >Near Me</li >
                            <li onClick={() => { navigate('/Referrals') }} className='section' >Refer A Friend</li >
                            <li onClick={() => { navigate('/DashboardProfile') }} className='section' >My Contact Details</li >
                            {/* <li className='section'  >Shortlists & more</li >
            <li className='section' >My Saved Searches</li >
            <li className='section' >My Help</li >
            <li className='section' >Need Help?</li >
            <li className='section' >Security Tips</li > */}
                        </ul>
                        <h2>Profile ID Search</h2>
                        {formErrors._id && <p className="error">{formErrors._id}</p>}
                        <div className="profile-search">
                            <input type="text" onChange={inputEvent2} name="_id" placeholder="Enter Profile ID" />
                            <button className='custom-button' onClick={getProfile} >Go</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Matches_Sidebar