import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const User_Panel_Header = () => {
    const showToast = () => {
        toast.success('Request successful!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
            //   onClose:()=>{
            //       navigate('/dashboard')
            //   }
        });
    };
    // let [newUser, modifyUser] = useState({})
    let [user, setUser] = useState({})
    const [formErrors, setFormErrors] = useState({});
    let validateForm = () => {
        const errors = {};
        if (!user.name) {
            errors.name = 'Name is required';
        }
        else if (!/^[a-zA-Z\s]+$/.test(user.name)) {
            errors.name = 'Name should only contain alphabets';
        }
        if (!user.email) {
            errors.email = 'email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(user.email)) {
            errors.email = 'Email is invalid';
        }
        if (!user.description) {
            errors.description = 'description is required';
        }
        if (!user.country) {
            errors.country = 'country is required';
        }
        if (!user.city) {
            errors.city = 'city is required';
        }
        if (!user.occupation) {
            errors.occupation = 'occupation is required';
        }
        if (!user.birthPlace) {
            errors.birthPlace = 'birthPlace is required';
        }
        if (!user.status) {
            errors.status = 'status is required';
        }
        if (!user.dob) {
            errors.dob = 'dob is required';
        }
        //   <input style={{color:'black'}} onChange={inputEvent} value={user?.birthPlace} name="birthPlace" type="text" placeholder="Birthplace" />
        { formErrors.name && <p className="error">{formErrors.name}</p> }
        // if (!user.age) {
        //   errors.age = 'age is required';
        // }
        if (!user.height) {
            errors.height = 'height is required';
        }
        if (!user.postedBy) {
            errors.postedBy = 'posted By is required';
        }
        if (!user.religion) {
            errors.religion = 'religion is required';
        }
        if (!user.motherTongue) {
            errors.motherTongue = 'mother Tongue is required';
        }
        if (!user.diet) {
            errors.diet = 'diet is required';
        }
        if (!user.healthInformation) {
            errors.healthInformation = 'health Information is required';
        }
        if (!user.fatherStatus) {
            errors.fatherStatus = 'father Status is required';
        }
        if (!user.motherStatus) {
            errors.motherStatus = 'mother Status is required';
        }
        if (!user.familyType) {
            errors.familyType = 'family Type is required';
        }
        if (!user.jobPlace) {
            errors.jobPlace = 'job Place is required';
        }
        if (!user.jobStartedYear) {
            errors.jobStartedYear = 'job Started Year is required';
        }
        if (!user.educationTitle) {
            errors.educationTitle = 'education Title is required';
        }

        setFormErrors(errors);
        console.log('here_formErrors', formErrors);
        return Object.keys(errors).length === 0;
    }

    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let fetchUser = async () => {
        try {
            let val = await axios.get(`/user/${keyId}`)
            console.log('user val.data', val.data)
            setUser(val.data)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchUser()
    }, [])

    let inputEvent = (e) => {
        if (e.target.name === 'dob') {
            const userDateOfBirth = e.target.value; // Replace this with the user's date of birth
            const currentDate = new Date();
            const birthYear = new Date(userDateOfBirth).getFullYear();
            const currentYear = currentDate.getFullYear();
            const age = currentYear - birthYear;
            console.log('age', age)
            // setUser({ ...user, age: age })
            // setUser({ ...user, dob: userDateOfBirth })
            setUser({ ...user, dob: userDateOfBirth, age: age });
            console.log('user', user)
            console.log('user.dob', user.dob)
            console.log('user.age', user.age)
        }
        else {
            console.log('user', user)
            setUser({ ...user, [e.target.name]: e.target.value })
        }
    }
    let Discard = () => {
        let y = window.confirm('Continue without saving changes?')
        if (y) {
            window.location.reload();
        }
    }
    let submitEvent = async (e) => {
        e.preventDefault()
        const isValid = validateForm();
        console.log('user', user)
        if (isValid) {
            try {
                let val = await fetch(`user/${keyId}`, {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(user)
                })
                if (val.status === 200) {
                    showToast();
                    window.location.reload();
                }
                else {
                    toast.error('Request failed!', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000 // Duration in milliseconds
                    });
                }
            } catch (error) {
                console.log('error', error)
            }
        }
        else {
            // alert('Error! Fill all fields to proceed');
            toast.error('Error! Fill all fields to proceed!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000 // Duration in milliseconds
            });
        }
    }
    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <div>
            <section className="user-setting-section">
                <div className="container">
                    <ToastContainer />
                    <div className="row">
                        <div className="col-xl-4 col-md-5">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <button className="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div className="icon">
                                                <i className="fas fa-user"></i>
                                            </div>
                                            <span>My Profile</span>
                                            <div className="t-icon">
                                                <i className="fas fa-plus"></i>
                                                <i className="fas fa-minus"></i>
                                            </div>
                                        </button>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul className="links">
                                                <li>
                                                    <a className="active" href="/DashboardProfile">Profile Info</a>
                                                </li>
                                                <li>
                                                    <a href="/notification">Notifications</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <button className="collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div className="icon">
                                                <i className="fas fa-cogs"></i>
                                            </div>
                                            <span>
                                                Account
                                            </span>
                                            <div className="t-icon">
                                                <i className="fas fa-plus"></i>
                                                <i className="fas fa-minus"></i>
                                            </div>
                                        </button>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul className="links">
                                                <li>
                                                    <a href="/change-password">Change Password</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <button className="collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div className="icon">
                                                <i className="far fa-credit-card"></i>
                                            </div>
                                            <span>
                                                Subscriptions & Payments
                                            </span>
                                            <div className="t-icon">
                                                <i className="fas fa-plus"></i>
                                                <i className="fas fa-minus"></i>
                                            </div>
                                        </button>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">
                                            <ul className="links">
                                                <li>
                                                    <a href="/allplan">Upgrade Membership Plan</a>
                                                </li>
                                                <li>
                                                    <a href="/purchaseHistory">View Purchase History</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-md-7 ">
                            {/* <div className="page-title">
                                Profile Info
                            </div> */}
                            <div className="row">
                                {/* <div className="col-lg-6">
                                    <div className="profile-about-box">
                                        <div className="top-bg"></div>
                                        <div className="p-inner-content">
                                            <div className="profile-img">
                                                {
                                                    user?.gender === 'male' ?
                                                        user?.photos && user?.photos?.length !== 0 ?
                                                            <img style={{ height: "120px", width: "120px" }} src={`uploads/${user?.photos[0]}`} alt="" />
                                                            :
                                                            <img style={{ height: "120px", width: "120px" }} src='img/default_man.jpg' alt="" />
                                                        :
                                                        user?.photos && user?.photos?.length !== 0 ?
                                                            <img style={{ height: "120px", width: "120px" }} src={`uploads/${user?.photos[0]}`} alt="" />
                                                            :
                                                            <img style={{ height: "120px", width: "120px" }} src='img/default_woman.jpg' alt="" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-6">
                                    {/* <div className="up-photo-card mb-30">
                                        <div className="icon">
                                            <i className="fas fa-user"></i>
                                        </div>
                                        <div className="content">
                                            <h4>
                                                Change Avatar
                                            </h4>
                                            <span>
                                                120x120p size minimum
                                            </span>
                                        </div>
                                    </div> */}
                                    {/* <div className="up-photo-card">
                                        <div className="icon">
                                            <i className="fas fa-image"></i>
                                        </div>
                                        <div className="content">
                                            <h4>
                                                Change Cover
                                            </h4>
                                            <span>
                                                1200x300p size minimum
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className="header">
                                    Edit your Profile
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Profile Name</label>
                                                <input style={{ color: 'black' }} onChange={inputEvent} value={user?.name} name="name" type="text" placeholder="Profile Name" />
                                                {formErrors.name && <p className="error">{formErrors.name}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Public Email</label>
                                                <input style={{ color: 'black' }} onChange={inputEvent} value={user?.email} name="email" type="email" placeholder="Public Email" />
                                                {formErrors.email && <p className="error">{formErrors.email}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <textarea onChange={inputEvent} value={user?.description} name="description" placeholder="Write a little description about you..."></textarea>
                                                {formErrors.description && <p className="error">{formErrors.description}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Country</label>
                                                <select onChange={inputEvent} value={user?.country} name="country" id="">
                                                    <option value="">select option</option>
                                                    {user?.country ? <option selected value={user?.country}>{user?.country}</option> : ''}
                                                    <option value="Afganistan">Afghanistan</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Andorra">Andorra</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Anguilla">Anguilla</option>
                                                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Armenia">Armenia</option>
                                                    <option value="Aruba">Aruba</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Azerbaijan">Azerbaijan</option>
                                                    <option value="Bahamas">Bahamas</option>
                                                    <option value="Bahrain">Bahrain</option>
                                                    <option value="Bangladesh" selected="">Bangladesh</option>
                                                    <option value="Barbados">Barbados</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Belize">Belize</option>
                                                    <option value="Benin">Benin</option>
                                                    <option value="Bermuda">Bermuda</option>
                                                    <option value="Bhutan">Bhutan</option>
                                                    <option value="Bolivia">Bolivia</option>
                                                    <option value="Bonaire">Bonaire</option>
                                                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                                    <option value="Botswana">Botswana</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                                    <option value="Brunei">Brunei</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Burkina Faso">Burkina Faso</option>
                                                    <option value="Burundi">Burundi</option>
                                                    <option value="Cambodia">Cambodia</option>
                                                    <option value="Cameroon">Cameroon</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Canary Islands">Canary Islands</option>
                                                    <option value="Cape Verde">Cape Verde</option>
                                                    <option value="Cayman Islands">Cayman Islands</option>
                                                    <option value="Central African Republic">Central African Republic</option>
                                                    <option value="Chad">Chad</option>
                                                    <option value="Channel Islands">Channel Islands</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Christmas Island">Christmas Island</option>
                                                    <option value="Cocos Island">Cocos Island</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Comoros">Comoros</option>
                                                    <option value="Congo">Congo</option>
                                                    <option value="Cook Islands">Cook Islands</option>
                                                    <option value="Costa Rica">Costa Rica</option>
                                                    <option value="Cote DIvoire">Cote DIvoire</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="Curaco">Curacao</option>
                                                    <option value="Cyprus">Cyprus</option>
                                                    <option value="Czech Republic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Djibouti">Djibouti</option>
                                                    <option value="Dominica">Dominica</option>
                                                    <option value="Dominican Republic">Dominican Republic</option>
                                                    <option value="East Timor">East Timor</option>
                                                    <option value="Ecuador">Ecuador</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="El Salvador">El Salvador</option>
                                                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                    <option value="Eritrea">Eritrea</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Falkland Islands">Falkland Islands</option>
                                                    <option value="Faroe Islands">Faroe Islands</option>
                                                    <option value="Fiji">Fiji</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="French Guiana">French Guiana</option>
                                                    <option value="French Polynesia">French Polynesia</option>
                                                    <option value="French Southern Ter">French Southern Ter</option>
                                                    <option value="Gabon">Gabon</option>
                                                    <option value="Gambia">Gambia</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Gibraltar">Gibraltar</option>
                                                    <option value="Great Britain">Great Britain</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Greenland">Greenland</option>
                                                    <option value="Grenada">Grenada</option>
                                                    <option value="Guadeloupe">Guadeloupe</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Guatemala">Guatemala</option>
                                                    <option value="Guinea">Guinea</option>
                                                    <option value="Guyana">Guyana</option>
                                                    <option value="Haiti">Haiti</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Honduras">Honduras</option>
                                                    <option value="Hong Kong">Hong Kong</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="India">India</option>
                                                    <option value="Iran">Iran</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Isle of Man">Isle of Man</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kazakhstan">Kazakhstan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Kiribati">Kiribati</option>
                                                    <option value="Korea North">Korea North</option>
                                                    <option value="Korea Sout">Korea South</option>
                                                    <option value="Kuwait">Kuwait</option>
                                                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                    <option value="Laos">Laos</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lesotho">Lesotho</option>
                                                    <option value="Liberia">Liberia</option>
                                                    <option value="Libya">Libya</option>
                                                    <option value="Liechtenstein">Liechtenstein</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Luxembourg">Luxembourg</option>
                                                    <option value="Macau">Macau</option>
                                                    <option value="Macedonia">Macedonia</option>
                                                    <option value="Madagascar">Madagascar</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Malawi">Malawi</option>
                                                    <option value="Maldives">Maldives</option>
                                                    <option value="Mali">Mali</option>
                                                    <option value="Malta">Malta</option>
                                                    <option value="Marshall Islands">Marshall Islands</option>
                                                    <option value="Martinique">Martinique</option>
                                                    <option value="Mauritania">Mauritania</option>
                                                    <option value="Mauritius">Mauritius</option>
                                                    <option value="Mayotte">Mayotte</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Midway Islands">Midway Islands</option>
                                                    <option value="Moldova">Moldova</option>
                                                    <option value="Monaco">Monaco</option>
                                                    <option value="Mongolia">Mongolia</option>
                                                    <option value="Montserrat">Montserrat</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Mozambique">Mozambique</option>
                                                    <option value="Myanmar">Myanmar</option>
                                                    <option value="Nambia">Nambia</option>
                                                    <option value="Nauru">Nauru</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherland Antilles">Netherland Antilles</option>
                                                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                                    <option value="Nevis">Nevis</option>
                                                    <option value="New Caledonia">New Caledonia</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Nicaragua">Nicaragua</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Niue">Niue</option>
                                                    <option value="Norfolk Island">Norfolk Island</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Oman">Oman</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Palau Island">Palau Island</option>
                                                    <option value="Palestine">Palestine</option>
                                                    <option value="Panama">Panama</option>
                                                    <option value="Papua New Guinea">Papua New Guinea</option>
                                                    <option value="Paraguay">Paraguay</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Phillipines">Philippines</option>
                                                    <option value="Pitcairn Island">Pitcairn Island</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Qatar">Qatar</option>
                                                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                                                    <option value="Republic of Serbia">Republic of Serbia</option>
                                                    <option value="Reunion">Reunion</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russia</option>
                                                    <option value="Rwanda">Rwanda</option>
                                                    <option value="St Barthelemy">St Barthelemy</option>
                                                    <option value="St Eustatius">St Eustatius</option>
                                                    <option value="St Helena">St Helena</option>
                                                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                                    <option value="St Lucia">St Lucia</option>
                                                    <option value="St Maarten">St Maarten</option>
                                                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                                    <option value="Saipan">Saipan</option>
                                                    <option value="Samoa">Samoa</option>
                                                    <option value="Samoa American">Samoa American</option>
                                                    <option value="San Marino">San Marino</option>
                                                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Senegal">Senegal</option>
                                                    <option value="Seychelles">Seychelles</option>
                                                    <option value="Sierra Leone">Sierra Leone</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="Solomon Islands">Solomon Islands</option>
                                                    <option value="Somalia">Somalia</option>
                                                    <option value="South Africa">South Africa</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="Sri Lanka">Sri Lanka</option>
                                                    <option value="Sudan">Sudan</option>
                                                    <option value="Suriname">Suriname</option>
                                                    <option value="Swaziland">Swaziland</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="Tahiti">Tahiti</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Tajikistan">Tajikistan</option>
                                                    <option value="Tanzania">Tanzania</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Togo">Togo</option>
                                                    <option value="Tokelau">Tokelau</option>
                                                    <option value="Tonga">Tonga</option>
                                                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Turkmenistan">Turkmenistan</option>
                                                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                                    <option value="Tuvalu">Tuvalu</option>
                                                    <option value="Uganda">Uganda</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="United Arab Erimates">United Arab Emirates</option>
                                                    <option value="United States of America">United States of America</option>
                                                    <option value="Uraguay">Uruguay</option>
                                                    <option value="Uzbekistan">Uzbekistan</option>
                                                    <option value="Vanuatu">Vanuatu</option>
                                                    <option value="Vatican City State">Vatican City State</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Vietnam</option>
                                                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                                    <option value="Wake Island">Wake Island</option>
                                                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zaire">Zaire</option>
                                                    <option value="Zambia">Zambia</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                                {formErrors.country && <p className="error">{formErrors.country}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="city">City</label>
                                                <input style={{ color: 'black' }} id='city' onChange={inputEvent} value={user?.city} name="city" type="text" />
                                                {formErrors.city && <p className="error">{formErrors.city}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="dob">Birthday</label>
                                                <input style={{ color: 'black' }} id='dob' onChange={inputEvent} value={user?.dob} name="dob" type="date" />
                                                {formErrors.dob && <p className="error">{formErrors.dob}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Occupation</label>
                                                {/* <input style={{color:'black'}} type="text"  name="occupation" placeholder="Occupation" /> */}
                                                {/* <select name="occupation" value={selectedProfession} onChange={handleProfessionChange}> */}
                                                
                                                <select name="occupation" onChange={inputEvent} value={user?.occupation}>
                                                    <option value="">select option</option>
                                                    <option value="Accounting, Banking & Finance">Accounting, Banking & Finance</option>
                                                    <option value="Administration & HR">Administration & HR</option>
                                                    <option value="Advertising, Media & Entertainment">Advertising, Media & Entertainment</option>
                                                    <option value="Agriculture">Agriculture</option>
                                                    <option value="Airline & Aviation">Airline & Aviation</option>
                                                    <option value="Architecture & Design">Architecture & Design</option>
                                                    <option value="Artists, Animators & Web Designers">Artists, Animators & Web Designers</option>
                                                    <option value="Beauty, Fashion & Jewellery Designers">Beauty, Fashion & Jewellery Designers</option>
                                                    <option value="BPO, KPO, & Customer Support">BPO, KPO, & Customer Support</option>
                                                    <option value="Civil Services / Law Enforcement">Civil Services / Law Enforcement</option>
                                                    <option value="Defense">Defense</option>
                                                    <option value="educationTitle & Training">educationTitle & Training</option>
                                                    <option value="Engineering">Engineering</option>
                                                    <option value="Hotel & Hospitality">Hotel & Hospitality</option>
                                                    <option value="IT & Software Engineering">IT & Software Engineering</option>
                                                    <option value="Legal">Legal</option>
                                                    <option value="Medical & Healthcare">Medical & Healthcare</option>
                                                    <option value="Merchant Navy">Merchant Navy</option>
                                                    <option value="Sales & Marketing">Sales & Marketing</option>
                                                    <option value="Science">Science</option>
                                                    <option value="Corporate Professionals">Corporate Professionals</option>
                                                    <option value="Others">Others</option>
                                                    <option value="Non Working">Non Working</option>
                                                </select>
                                                {formErrors.occupation && <p className="error">{formErrors.occupation}</p>}
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Status</label>
                                                <select onChange={inputEvent} value={user?.status} name="status" id="">
                                                    <option value="">select option</option>
                                                    {user?.status ? <option selected value={user?.status}>{user?.status}</option> : ''}
                                                    {/* <option value="">In a Relationship</option> */}
                                                    <option value="single">Single</option>
                                                    <option value="married">Married</option>
                                                    <option value="divorced">Divorced</option>
                                                    <option value="never married">Never Married</option>
                                                    <option value="widowed">Widowed</option>
                                                    <option value="awaiting divorce">Awaiting Divorce</option>
                                                    <option value="annulled">Annulled</option>
                                                </select>
                                                {formErrors.status && <p className="error">{formErrors.status}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Birthplace</label>
                                                <input style={{ color: 'black' }} onChange={inputEvent} value={user?.birthPlace} name="birthPlace" type="text" placeholder="Birthplace" />
                                                {formErrors.birthPlace && <p className="error">{formErrors.birthPlace}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className="header">
                                    More info
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <div className="my-input-box">
                                                <label for="">Age</label>
                                                <input style={{color:'black'}} type="number" onChange={inputEvent} value={user?.age} name="age" placeholder="Age"></input>
                                                {formErrors.age && <p className="error">{formErrors.age}</p>}
                                            </div> */}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Height</label>
                                                <input style={{ color: 'black' }} min='2' type="number" onChange={inputEvent} value={user?.height} name="height" placeholder="Height"></input>
                                                {formErrors.height && <p className="error">{formErrors.height}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Posted by</label>
                                                {/* <textarea onChange={inputEvent} value={user?.postedBy} name="postedBy" placeholder="Profile Posted By"></textarea> */}
                                                <select onChange={inputEvent} value={user?.postedBy} name="postedBy" id="">
                                                    <option value="">select option</option>
                                                    {user?.postedBy ? <option selected value={user?.postedBy}>{user?.postedBy}</option> : ''}
                                                    <option value="self">Self</option>
                                                    <option value="parent">Parent / Guardian</option>
                                                    <option value="friends">Friends / Siblings</option>
                                                </select>
                                                {formErrors.postedBy && <p className="error">{formErrors.postedBy}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Religion  </label>
                                                {/* <textarea onChange={inputEvent} value={user?.religion} name="religion" placeholder="Religion"></textarea> */}

                                                <select onChange={inputEvent} value={user?.religion} name="religion" id="">
                                                    <option value="">select option</option>
                                                    {user?.religion ? <option selected value={user?.religion}>{user?.religion}</option> : ''}
                                                    <option value="muslim">Muslim</option>
                                                    <option value="christian">Christian</option>
                                                    <option value="jewish">Jewish</option>
                                                    <option value="hindu">Hindu</option>
                                                    <option value="sikh">Sikh</option>
                                                    <option value="parsi">Parsi</option>
                                                    <option value="jain">Jain</option>
                                                    <option value="buddhist">Buddhist</option>
                                                    <option value="no religion">No Religion</option>
                                                    <option value="spiritual - not religious">Spiritual - not religious</option>
                                                    <option value="other">Other</option>
                                                </select>
                                                {formErrors.religion && <p className="error">{formErrors.religion}</p>}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Location </label>
                                                <textarea onChange={inputEvent} value={user?.city} name="city" placeholder="City"></textarea>
                                            </div>
                                        </div> */}
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Mother tongue </label>
                                                {/* <textarea onChange={inputEvent} value={user?.motherTongue} name="motherTongue" placeholder="Mother Tongue"></textarea> */}
                                                <select onChange={inputEvent} value={user?.motherTongue} name="motherTongue" id="">
                                                    <option value="">select option</option>
                                                    {/* <select name="motherTongue" value={selectedLanguage} onChange={handleLanguageChange}> */}
                                                    
                                                    <option value="Hindi">Hindi</option>
                                                    <option value="Marathi">Marathi</option>
                                                    <option value="Punjabi">Punjabi</option>
                                                    <option value="Bengali">Bengali</option>
                                                    <option value="Gujarati">Gujarati</option>
                                                    <option value="Urdu">Urdu</option>
                                                    <option value="Telugu">Telugu</option>
                                                    <option value="Kannada">Kannada</option>
                                                    <option value="English">English</option>
                                                    <option value="Tamil">Tamil</option>
                                                    <option value="Odia">Odia</option>
                                                    <option value="Marwari">Marwari</option>
                                                    <option value="Aka">Aka</option>
                                                    <option value="Arabic">Arabic</option>
                                                    <option value="Arunachali">Arunachali</option>
                                                    <option value="Assamese">Assamese</option>
                                                    <option value="Awadhi">Awadhi</option>
                                                    <option value="Baluchi">Baluchi</option>
                                                    <option value="Bhojpuri">Bhojpuri</option>
                                                    <option value="Bhutia">Bhutia</option>
                                                    <option value="Brahui">Brahui</option>
                                                    <option value="Brij">Brij</option>
                                                    <option value="Burmese">Burmese</option>
                                                    <option value="Chattisgarhi">Chattisgarhi</option>
                                                    <option value="Chinese">Chinese</option>
                                                    <option value="Coorgi">Coorgi</option>
                                                    <option value="Dogri">Dogri</option>
                                                    <option value="French">French</option>
                                                    <option value="Garhwali">Garhwali</option>
                                                    <option value="Garo">Garo</option>
                                                    <option value="Haryanavi">Haryanavi</option>
                                                    <option value="Himachali/Pahari">Himachali/Pahari</option>
                                                    <option value="Hindko">Hindko</option>
                                                    <option value="Kakbarak">Kakbarak</option>
                                                    <option value="Kanauji">Kanauji</option>
                                                    <option value="Kashmiri">Kashmiri</option>
                                                    <option value="Khandesi">Khandesi</option>
                                                    <option value="Khasi">Khasi</option>
                                                    <option value="Konkani">Konkani</option>
                                                    <option value="Koshali">Koshali</option>
                                                    <option value="Kumaoni">Kumaoni</option>
                                                    <option value="Kutchi">Kutchi</option>
                                                    <option value="Ladakhi">Ladakhi</option>
                                                    <option value="Lepcha">Lepcha</option>
                                                    <option value="Magahi">Magahi</option>
                                                    <option value="Maithili">Maithili</option>
                                                    <option value="Malay">Malay</option>
                                                    <option value="Malayalam">Malayalam</option>
                                                    <option value="Manipuri">Manipuri</option>
                                                    <option value="Miji">Miji</option>
                                                    <option value="Mizo">Mizo</option>
                                                    <option value="Monpa">Monpa</option>
                                                    <option value="Nepali">Nepali</option>
                                                    <option value="Pashto">Pashto</option>
                                                    <option value="Persian">Persian</option>
                                                    <option value="Rajasthani">Rajasthani</option>
                                                    <option value="Russian">Russian</option>
                                                    <option value="Sanskrit">Sanskrit</option>
                                                    <option value="Santhali">Santhali</option>
                                                    <option value="Seraiki">Seraiki</option>
                                                    <option value="Sindhi">Sindhi</option>
                                                    <option value="Sinhala">Sinhala</option>
                                                    <option value="Sourashtra">Sourashtra</option>
                                                    <option value="Spanish">Spanish</option>
                                                    <option value="Swedish">Swedish</option>
                                                    <option value="Tagalog">Tagalog</option>
                                                    <option value="Tulu">Tulu</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {formErrors.motherTongue && <p className="error">{formErrors.motherTongue}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Diet </label>
                                                {/* <textarea onChange={inputEvent} value={user?.diet} name="diet" placeholder="diet"></textarea> */}
                                                <select onChange={inputEvent} value={user?.diet} name="diet">
                                                    <option value="">select option</option>
                                                    <option value="veg">Veg</option>
                                                    <option value="non-veg">Non veg</option>
                                                    <option value="eggeterian">Eggeterian</option>
                                                </select>
                                                {formErrors.diet && <p className="error">{formErrors.diet}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Health Information</label>
                                                <input style={{ color: 'black' }} type="text" onChange={inputEvent} value={user?.healthInformation} name="healthInformation" placeholder="Health Info"></input>
                                                {formErrors.healthInformation && <p className="error">{formErrors.healthInformation}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Smoke</label>
                                                <select onChange={inputEvent} value={user?.smoke} name="smoke" id="">
                                                    <option value="">select option</option>
                                                    {user?.smoke ? <option selected value={user?.smoke}>{user?.smoke}</option> : ''}
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                                {/* {formErrors.fatherStatus && <p className="error">{formErrors.fatherStatus}</p>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Drink</label>
                                                <select onChange={inputEvent} value={user?.drink} name="drink" id="">
                                                    <option value="">select option</option>
                                                    {user?.drink ? <option selected value={user?.drink}>{user?.drink}</option> : ''}
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                                {/* {formErrors.fatherStatus && <p className="error">{formErrors.fatherStatus}</p>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className="header">
                                    Family Details
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Father's Status</label>
                                                {/* <textarea onChange={inputEvent} value={user?.fatherStatus} name="fatherStatus" placeholder="Father's status"></textarea> */}
                                                <select onChange={inputEvent} value={user?.fatherStatus} name="fatherStatus" id="">
                                                    <option value="">select option</option>
                                                    {user?.fatherStatus ? <option selected value={user?.fatherStatus}>{user?.fatherStatus}</option> : ''}
                                                    <option value="employed">Employed</option>
                                                    <option value="business">Business</option>
                                                    <option value="retired">Retired</option>
                                                    <option value="passed Away">Passed Away</option>
                                                </select>
                                                {formErrors.fatherStatus && <p className="error">{formErrors.fatherStatus}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Mother's Status</label>
                                                {/* <select onChange={inputEvent} value={user?.mottherStatus} name="mottherStatus" id=""> */}
                                                
                                                <select onChange={inputEvent} value={user?.motherStatus} name="motherStatus" id="">
                                                    <option value="">select option</option>
                                                    <option value="employed">Employed</option>
                                                    <option value="business">Business</option>
                                                    <option value="retired">Retired</option>
                                                    <option value="houseWife">House Wife</option>
                                                    <option value="passed Away">Passed Away</option>
                                                </select>
                                                {formErrors.motherStatus && <p className="error">{formErrors.motherStatus}</p>}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                    <div className="my-input-box">
                                        <label for="">Family Location</label>
                                        <textarea name="" placeholder="Favourite Movies"></textarea>
                                    </div>
                                </div> */}
                                        {/* <div className="col-md-12">
                                    <div className="my-input-box">
                                        <label for="">Native Place </label>
                                        <textarea name="" placeholder="Favourite Games "></textarea>
                                    </div>
                                </div> */}
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">No. of Brothers </label>
                                                <input style={{ color: 'black' }} min='0' type="number" onChange={inputEvent} value={user?.brothers} name="brothers" placeholder="No of Brothers"></input>
                                                {formErrors.brothers && <p className="error">{formErrors.brothers}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">No. of Sisters </label>
                                                <input style={{ color: 'black' }} min='0' type="number" onChange={inputEvent} value={user?.sisters} name="sisters" placeholder="No of Sisters"></input>
                                                {formErrors.sisters && <p className="error">{formErrors.sisters}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Family Type </label>
                                                {/* <textarea onChange={inputEvent} value={user?.familyType} name="familyType" placeholder="Family Type"></textarea> */}
                                                <select onChange={inputEvent} value={user?.familyType} name="familyType" id="">
                                                    <option value="">select option</option>
                                                    {user?.familyType ? <option selected value={user?.familyType}>{user?.familyType}</option> : ''}
                                                    <option value="joint">Joint</option>
                                                    <option value="nuclear">Nuclear</option>
                                                </select>
                                                {formErrors.familyType && <p className="error">{formErrors.familyType}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-info-box mt-30">
                                <div className="header">
                                    Jobs & education
                                </div>
                                <div className="content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">Job place</label>
                                                {/* <input style={{color:'black'}} type="text" onChange={inputEvent} value={user?.jobPlace} name="jobPlace" placeholder="working with" /> */}
                                                <select onChange={inputEvent} value={user?.jobPlace} name="jobPlace" id="">
                                                    <option value="">select option</option>
                                                    {user?.jobPlace ? <option selected value={user?.jobPlace}>{user?.jobPlace}</option> : ''}
                                                    <option value="private Company">Private Company</option>
                                                    <option value="government / Public Sector">Government / Public Sector</option>
                                                    <option value="business / Self Employed">GBusiness / Self Employed</option>
                                                    <option value="defense / Civil Services">Defense / Civil Services</option>
                                                    <option value="non Working">Non Working</option>
                                                </select>
                                                {formErrors.jobPlace && <p className="error">{formErrors.jobPlace}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="my-input-box">
                                                <label for="">Year Started</label>
                                                <input style={{ color: 'black' }} type="date" max={currentDate} onChange={inputEvent} value={user?.jobStartedYear} name="jobStartedYear" placeholder="Job started year"></input>
                                                {formErrors.jobStartedYear && <p className="error">{formErrors.jobStartedYear}</p>}
                                                {/* <select id="" onChange={inputEvent} value={user?.jobStartedYear} name="jobStartedYear" placeholder="Job started year">
                                                
                                                {formErrors.name && <p className="error">{formErrors.name}</p>}
                                                    <option value="">2014</option>
                                                </select> */}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                    <div className="my-input-box">
                                        <label for="">Year End</label>
                                        <select name="" id="">
                                        <option value="">select option</option>
                                            <option value="">2017</option>
                                        </select>
                                    </div>
                                </div> */}
                                        {/* <div className="col-md-12">
                                    <div className="my-input-box">
                                        <label for="">Description</label>
                                        <textarea name="" id="" placeholder="Description"></textarea>
                                    </div>
                                </div> */}
                                        <div className="col-md-12">
                                            <div className="my-input-box">
                                                <label for="">educationTitle</label>
                                                {/* <input style={{color:'black'}} type="text" onChange={inputEvent} value={user?.educationTitle} name="educationTitle" placeholder="educationTitle" /> */}
                                                <select onChange={inputEvent} value={user?.educationTitle} name="educationTitle" id="">
                                                    <option value="">select option</option>
                                                    {user?.educationTitle ? <option selected value={user?.educationTitle}>{user?.educationTitle}</option> : ''}
                                                    <option value="doctorate">Doctorate</option>
                                                    <option value="masters">Masters</option>
                                                    <option value="bachelor / Undergraduate">Bachelor / Undergraduate</option>
                                                    <option value="associate / Diploma">Associate / Diploma</option>
                                                    <option value="high School and below">High School and below</option>
                                                </select>
                                                {formErrors.educationTitle && <p className="error">{formErrors.educationTitle}</p>}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                    <div className="my-input-box">
                                        <label for="">Year Started</label>
                                        <select name="" id="">
                                        <option value="">select option</option>
                                            <option value="">2014</option>
                                        </select>
                                    </div>
                                </div> */}
                                        {/* <div className="col-md-6">
                                    <div className="my-input-box">
                                        <label for="">Year End</label>
                                        <select name="" id="">
                                        <option value="">select option</option>
                                            <option value="">2017</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="my-input-box">
                                        <label for="">Description</label>
                                        <textarea name="" id="" placeholder="Description"></textarea>
                                    </div>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="buttons  mt-30">
                                <button onClick={submitEvent} type="submit" className="custom-button">Save Changes</button>
                                <button onClick={Discard} className="custom-button2">Discard All</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default User_Panel_Header