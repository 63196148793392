import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Style/Cards/Card8.css'; // Create a CSS file for styling
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import arrow icons
import { useNavigate } from 'react-router-dom';
import User_Info from '../UserDetails/User_Info';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User_Info_For_Some_Images from '../UserDetails/User_Info_For_Some_Images';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';

import axios from 'axios';

const Card8 = (params) => {
  let navigate = useNavigate()
  let user = params.user
  let user2 = params.user2
  let user3 = params.user3
  let mainUser = params.mainUser
  let socket = params.socket
  let value = params.value
  let nextPage = async (e, id) => {
    console.log('e', e.target)
    console.log('id', id)
    localStorage.setItem('singleProfileId', JSON.stringify(id))
    navigate('/singleprofile')
  }
  console.log('value', value)
  console.log('user', user)
  console.log('user2', user2)
  console.log('user3', user3)
  // const cardData = [
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   {
  //     name: 'Faisal K',
  //     age: '38 yrs',
  //     height: '6\' 0"',
  //     religion: 'Muslim',
  //     language: 'Urdu',
  //     caste: 'Pathan',
  //     location: 'Lives in Varanasi, India',
  //   },
  //   // Add more card data objects for other items
  // ];

  let cardData = []
  if (value === 'visitors') {
    try {

      user?.visitors?.map((ele, ind) => {
        return (
          cardData.push(ele)
        )
      })
    } catch (error) {
      console.log('yahan b hun', error)
    }
  }
  else if (value == 'premium') {
    // cardData = []
    try {
      if (Array.isArray(user2)) {
        console.log('There is user2', user2)
        console.log('There is user2', user2.length)
        user2?.map((ele, ind) => {
          return (
            // console.log('ele',ele)
            cardData.push(ele._id)
          )
        })
      }
    } catch (error) {
      console.log('yahan hun mn', error)
    }
  }
  else if (value === 'looking_for_me') {
    // cardData = []
    try {
      user3?.map((ele, ind) => {
        return (
          // console.log('recievedRequests',ele)
          cardData.push(ele)
        )
      })
    } catch (error) {
      console.log('or yahan b hun mn', error)
    }
  }
  const showToast = () => {
    toast.success('Request Successful!!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Duration in milliseconds
    });
  };

  let connected = async (e, id) => {
    console.log(e.target)
    console.log(id)
    try {
      let val = await fetch(`add/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json"
        }
      })
      if (val.status === 200) {
        let date = new Date()
        date = date.toLocaleTimeString()
        let keyId = JSON.parse(localStorage.getItem('keyId'))
        params.socket?.emit("sendNotification", {
          sender: keyId,
          receiverName: id,
          receiverId: id,
          type: 'sent_Request',
          date: date
        });
        showToast()
        // window.location.reload();
      }
      else {
        toast.error('Request failed!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000 // Duration in milliseconds
        });
      }
    }
    catch (err) {

    }

  }
  // let vall = []
  let fetchData = async (id) => {
    try {
      let val = await axios.get(`/user/${id}`)
      console.log('fetchData', val.data)
      return val.data
    } catch (error) {

    }
  }
  // console.log('cardData vall', vall)
  // console.log('cardData', cardData)
  // cardData.map((ele, ind) => {
  //   if ((!mainUser.blockedUsers.includes(ele))) {
  //     return (
  //       fetchData(ele)
  //   )
  //   }
  // })

  // Generate an array of items to display in the carousel (4 items per slide)
  const itemsPerPage = 4;
  const totalSlides = Math.ceil(cardData.length / itemsPerPage);

  const renderCarouselItems = () => {
    const carouselItems = [];
    for (let i = 0; i < totalSlides; i++) {
      const slideStartIndex = i * itemsPerPage;
      const slideEndIndex = slideStartIndex + itemsPerPage;
      // cardData=vall;
      const slideItems = cardData.slice(slideStartIndex, slideEndIndex).map((ele, index) => (
        (!mainUser?.blockedUsers?.includes(ele)) && (!fetchData(ele)?.blockedUsers?.includes(mainUser?._id)) ?
          <div key={index} >
            <div key={index} style={{ width: "17rem" }} className="card Card1">
              <div style={{ margin: "auto" }}>
                <User_Info_For_Some_Images id={ele} title={'photos'} />
              </div>
              {
                mainUser && mainUser?.sentRequests && mainUser?.sentRequests?.includes(ele) && (

                  <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                    <div class="col">
                      <a id="report_a_id_42"
                        onclick="report_member(42)" class="text-reset c-pointer">
                        <span id="report_id_42"
                          class="text-dark">
                          <PendingIcon />
                          <span
                            class="d-block fs-10 opacity-60">Waiting Approval</span>
                        </span>
                      </a>
                    </div>
                  </div>
                )
              }
              {
                mainUser && mainUser?.recievedRequests && mainUser?.recievedRequests?.includes(ele) && (

                  <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                    <div class="col">
                      <a id="report_a_id_42"
                        onclick="report_member(42)" class="text-reset c-pointer">
                        <span id="report_id_42"
                          class="text-dark">
                          {/* <PendingIcon /> */}
                          <>
                            <button onClick={(e) => { Accepted(e, ele) }} className='whatsapp'> <DoneIcon style={{ fontSize: '14px' }} /> Accept</button>
                            <button onClick={(e) => { Declined(e, ele) }}>  <CancelIcon style={{ fontSize: '14px' }} /> Decline</button>
                          </>
                          {/* <span
                            class="d-block fs-10 opacity-60">Waiting Approval</span> */}
                        </span>
                      </a>
                    </div>
                  </div>
                )
              }
              {
                mainUser && mainUser?.rejectedByReciever && mainUser?.rejectedByReciever?.includes(ele) && (
                  <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                    {/* <div className="custom-message">Declined</div> */}
                    <div class="col">
                      <a id="report_a_id_42"
                        onclick="report_member(42)" class="text-reset c-pointer">
                        <span id="report_id_42"
                          class="text-dark">
                          <CancelIcon />
                          <span
                            class="d-block fs-10 opacity-60">Declined</span>
                        </span>
                      </a>
                    </div>
                  </div>
                )
              }
              {
                mainUser && mainUser?.friends && mainUser?.friends?.includes(ele) && (
                  <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                    {/* <div className="custom-message bg-success text-white">Accepted</div> */}
                    <div className='card-details1_Right'>
                      <div class="col">
                        <a id="report_a_id_42"
                          onclick="report_member(42)" class="text-reset c-pointer">
                          <span id="report_id_42"
                            class="text-dark">
                            <i class="las la-info-circle fs-20 text-primary"></i>
                            {mainUser?.accountType == 'free' || mainUser?.isActive !== 'true' ?
                              <p> <i> Upgrade to Contact him directly</i></p>
                              : ''}
                            <div style={{textAlign:'center'}}>
                              <button class="custom-button" onClick={(e) => nextPage(e, ele._id)}>  <CallIcon className='buttons' /> </button>
                              {/* <button class="d-block fs-10 opacity-60" onClick={() => openChat(ele._id)} > <SendIcon className='buttons' /> Chat</button> */}
                            </div>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                mainUser && !mainUser?.sentRequests?.includes(ele) && !mainUser?.rejectedByReciever?.includes(ele) && !mainUser.friends?.includes(ele) && !mainUser.recievedRequests?.includes(ele) && (
                  <div className="profile-container">
                    {/* <p> <i> Like this profile?</i></p> */}
                    <div className='icon-container'>
                      <h5 onClick={(e) => nextPage(e, ele)} className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                      <p onClick={(e) => nextPage(e, ele)} className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                      <p onClick={(e) => { connected(e, ele) }} className="custom-button text-white">Connect Now </p>
                    </div>
                  </div>
                )
              }
            </div>
          </div >
          : ''
      ));

      carouselItems.push(
        <div key={i} style={{ display: 'flex' }} className="carousel-slide">
          {slideItems}
        </div>
      );
    }

    return carouselItems;
  };
  let Accepted = async (e, id) => {
    console.log(e)
    console.log(e.target)
    console.log(id)
    try {
      let val = await fetch(`accept/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json"
        }
      })
      if (val.status === 200) {
        showToast()
        let date = new Date()
        date = date.toLocaleTimeString()
        let keyId = JSON.parse(localStorage.getItem('keyId'))
        socket?.emit("sendNotification", {
          sender: keyId,
          receiverName: id,
          receiverId: id,
          type: 'received_Request',
          date: date
        });
        window.location.reload();
      }
      else {
        toast.error('Request failed!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000 // Duration in milliseconds
        });
      }
    }
    catch (err) {

    }
  }
  let Declined = async (e, id) => {
    console.log(e)
    console.log(e.target)
    console.log(id)
    try {
      let val = await fetch(`decline/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json"
        }
      })
      if (val.status === 200) {
        showToast()
        window.location.reload();
      }
      else {
        toast.error('Request failed!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000 // Duration in milliseconds
        });
      }
    } catch (error) {

    }
  }
 
  return (
    <div className="Card8">
      {
        value === 'visitors' ?
          <>
            <p style={{ fontSize: "17px", margin: '0%', padding: '0%' }}> <b>  Recent Visitors  ({cardData?.length})</b></p>
            <p style={{ margin: '0%', padding: '0%' }}>Members who recently visited your Profile</p>
          </>
          :
          value === 'premium' ?
            <>
              <p style={{ fontSize: "17px", margin: '0%', padding: '0%' }}> <b>  Premium Matches ({cardData?.length})</b></p>
              <p style={{ margin: '0%', padding: '0%' }}>Premium Member's who meet your Profile preferences</p>
            </>
            :
            value === 'looking_for_me' ?
              <>
                <p style={{ fontSize: "17px", margin: '0%', padding: '0%' }}> <b>  Members Looking for me ({cardData?.length})</b></p>
                <p style={{ margin: '0%', padding: '0%' }}>Your profile meet their Profile preferences</p>
              </>
              :
              ''
      }
      <div className="card-carousel">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          showIndicators={false}
          emulateTouch={true}
          renderThumbs={() => { }} // Disable the default thumbnail navigation
          thumbWidth={0} // Set thumbnail width to 0 to hide it
        // renderArrowPrev={(onClickHandler, hasPrev, label) => (
        //   <button onClick={onClickHandler} disabled={!hasPrev} className="custom-left-arrow">
        //     <FaArrowLeft />
        //   </button>
        // )}
        // renderArrowNext={(onClickHandler, hasNext, label) => (
        //   <button onClick={onClickHandler} disabled={!hasNext} className="custom-right-arrow">
        //     <FaArrowRight />
        //   </button>
        // )}
        >
          {renderCarouselItems()}
        </Carousel>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Card8;
