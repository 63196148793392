import axios from 'axios';
import React, { useState,useEffect } from 'react'
import Admin_Home_Revenue_Chart from './Admin_Home_Revenue_Chart';

const Admin_Home_Header = ({ data }) => {
    console.log('data', data)
    let trial = 0, free = 0, gold = 0, platinium = 0, newMember = 0, newlyAccounts = 0, silver = 0;
    let [within24Hours, setIsWithin24Hours] = useState(false)
    let [weekData, setWeekData] = useState('')
    let [monthData, setMonthData] = useState(false)
    let [yearData, setYearData] = useState(false)
    let newAccount = async (createdAt) => {
        const createdDate = new Date(createdAt);
        const currentTime = new Date();
        const differenceInMilliseconds = currentTime - createdDate;
        const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
        const isWithin24Hrs = differenceInMilliseconds < twentyFourHoursInMilliseconds;
        setIsWithin24Hours(isWithin24Hrs);
    }

    data?.map((ele, ind) => {
        if (ele) {
            const createdDate = new Date(ele?.createdAt);
            const currentTime = new Date();
            const differenceInMilliseconds = currentTime - createdDate;
            const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
            const isWithin24Hrs = differenceInMilliseconds < twentyFourHoursInMilliseconds;
            if (isWithin24Hrs) {
                newlyAccounts += 1;
            }
        }
        if (ele.accountType === 'trial' || ele.accountType === 'welcome') {
            trial += 1;
        }
        if (ele.accountType === 'free' || ele.accountType === 'Free') {
            free += 1;
        }
        if (ele.accountType === 'gold' || ele.accountType === 'Gold') {
            gold += 1;
        }
        if (ele.accountType === 'platinium' || ele.accountType === 'Platinium') {
            platinium += 1;
        }
        if (ele.accountType === 'silver' || ele.accountType === 'silver') {
            silver += 1;
        }

        // else if(ele.accountType==='newMember')
        // {
        //     newMember+=1;
        // }
    })
    let fetchWeek = async () => {
        try {
            let val = await axios.get('/earnings/weekly')
            console.log('val.dayya',val.data)
            setWeekData(val.data.weeklyEarnings)
        }
        catch (err) {
            console.log(err)
        }
    }
    let fetchMonthly = async () => {
        try {
            let val = await axios.get('/earnings/monthly')
            console.log('val.dayya',val.data)
            setMonthData(val.data.monthlyEarnings)
        }
        catch (err) {
            console.log(err)
        }
    }
    let fetchYearly = async () => {
        try {
            let val = await axios.get('/earnings/yearly')
            console.log('val.dayya',val.data)
            setYearData(val.data.yearlyEarnings)
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchWeek()
        fetchMonthly()
        fetchYearly()
    },)
    return (
        <div>
            <div class="row ">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card">
                        <div class="card-statistic-4">
                            <div class="align-items-center justify-content-between">
                                <div class="row ">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                                        <div class="card-content">
                                            <h5 class="font-15">Trial Members</h5>
                                            {/* <h2 class="mb-3 font-18">258</h2> */}
                                            <h2 class="mb-3 font-18">{trial}</h2>
                                            {/* <p class="mb-0"><span class="col-green">10%</span> Increase</p> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                                        <div class="banner-img">
                                            <img src="assets6/img/banner/1.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card">
                        <div class="card-statistic-4">
                            <div class="align-items-center justify-content-between">
                                <div class="row ">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                                        <div class="card-content">
                                            <h5 class="font-15">Free Members</h5>
                                            <h2 class="mb-3 font-18">{free}</h2>
                                            {/* <p class="mb-0"><span class="col-orange">09%</span> Decrease</p> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                                        <div class="banner-img">
                                            <img src="assets6/img/banner/2.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card">
                        <div class="card-statistic-4">
                            <div class="align-items-center justify-content-between">
                                <div class="row ">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                                        <div class="card-content">
                                            <h5 class="font-15">Gold Members</h5>
                                            <h2 class="mb-3 font-18">{gold}</h2>
                                            {/* <p class="mb-0"><span class="col-green">18%</span> */}
                                            {/* Increase</p> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                                        <div class="banner-img">
                                            <img src="assets6/img/banner/3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card">
                        <div class="card-statistic-4">
                            <div class="align-items-center justify-content-between">
                                <div class="row ">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                                        <div class="card-content">
                                            <h5 class="font-15">Platinium </h5>
                                            <h2 class="mb-3 font-18">{platinium}</h2>
                                            {/* <p class="mb-0"><span class="col-green">42%</span> Increase</p> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                                        <div class="banner-img">
                                            <img src="assets6/img/banner/2.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div class="card">
                        <div class="card-statistic-4">
                            <div class="align-items-center justify-content-between">
                                <div class="row ">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                                        <div class="card-content">
                                            <h5 class="font-15">Silver Members</h5>
                                            <h2 class="mb-3 font-18">{silver}</h2>
                                            {/* <p class="mb-0"><span class="col-green">42%</span> Increase</p> */}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                                        <div class="banner-img">
                                            <img src="assets6/img/banner/4.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Admin_Home_Revenue_Chart monthData={monthData} weekData={weekData} yearData={yearData} />
        </div>
    )
}

export default Admin_Home_Header