import React, { useEffect, useState } from 'react'
import Billing_Breadcrumb from '../Billing_Components/Billing_Breadcrumb'
import Billing_Header from '../Billing_Components/Billing_Header'
import Header from '../Home/Header'
import Purchase_Header from '../Purchase_Components/Purchase_Header'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Purchase_Home = () => {
  let navigate=useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
    <div>
        <Header/>
        <Billing_Breadcrumb/>
        <Purchase_Header/>
    </div>
    :navigate('/login')
  )
}

export default Purchase_Home