import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
const Admin_Coupon_Table = ({ data }) => {
    let params = useParams();
    let navigate = useNavigate();
    let member_link = JSON.parse(localStorage.getItem('member_link'));
    console.log('member_link', member_link)
    console.log('params', params)
    console.log('data here')
    console.log('data here', data)
    let [fetchedData, setFetchedData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [photoUploadMessage, setPhotoUploadMessage] = useState('');
    const [showNote, setShowNote] = useState(false);
    const [userPlan, setUserPlan] = useState({});
    const [userPlan2, setUserPlan2] = useState({});
    const [newData, setNewData] = useState([]);
    const handleClose = () => setShowModal(false);
    const handleClose2 = () => setShowDropdown(false);
    const handleClose3 = () => setShowNote(false);
    const handleClose4 = () => setShowDropdown2(false);
    const handleShow = (ele) => {
        setShowModal(true)
        setFetchedData(ele)
    };
    // const photos = new FormData();
    // const [photos,setPhotos] = useState();
    let deleteAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/coupons/${id}`,
                    {
                        method: "Delete"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) { }
        }
    }
    const handleEdit = (fetchedData) => {
        setShowDropdown(true); // Show the dropdown when the button is clicked
        setUserPlan(fetchedData)
    };
    const handleEdit2 = (fetchedData) => {
        setShowDropdown2(true); // Show the dropdown when the button is clicked
        setUserPlan2(fetchedData)
    };
    const addNote = (fetchedData) => {
        setShowNote(true); // Show the dropdown when the button is clicked
    };

    let changePlan = (e) => {
        // else {
        setUserPlan({ ...userPlan, [e.target.name]: e.target.value })
        // }
    }
    let submitPlan = async (e) => {
        e.preventDefault()
        console.log('userPlan', userPlan)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/success-story/${fetchedData?._id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(userPlan)
            })
            if (val.status === 200) {
                alert('changes updated!')
                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (err) {

        }
    }

    let newDataPlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            console.log('balue', value)
            setNewData({ ...newData, [e.target.name]: value })
        }
        else {
            console.log('e.target.name and value',e.target.name)
            console.log('e.target.name and value',e.target.value)
            setNewData({ ...newData, [e.target.name]: e.target.value })
        }
    }
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file)
        //   setNewData({...newData,[e.target.name]:file});
    };

    let submitNewDataPlan = async (e) => {
        e.preventDefault()
        // const formData = new FormData();
        // formData.append('code', newData?.code);
        // formData.append('discountType', newData?.discountType);
        // formData.append('discountAmount', newData?.discountAmount);
        // formData.append('validFrom', newData?.validFrom);
        // formData.append('validUntil', newData?.validUntil);

        console.log('newData,', newData)
        try{
        let val = await fetch(`/coupons`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            // body: newData
            body: JSON.stringify(newData)
        })
        if (val.status === 200) {
            alert('new plan added!')
            window.location.reload()
        }
        else { alert('error!') }}
        catch(err){}
    }
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    let approveStory = async (data) => {
        let y;
        {
            data.approval ?
                y = window.confirm(`Do you want to remove its approval?`)
                :
                y = window.confirm(`Do you want to approve?`)
        }
        if (y) {
            let approval;
            if (data?.approval) {
                approval = false
            }
            else {
                approval = true
            }
            try{
            let val = await fetch(`/success-story/${fetchedData?._id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ approval })
            })
            if (val.status === 200) {
                alert('changes updated!')
                window.location.reload()
            }
            else { alert('error!') }}
            catch(err){}
        }

    }


    return (
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            {/* <h4>Plans</h4> */}
                            <div class="card-header-form">
                                {/* <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" />
                                        <div class="input-group-btn">
                                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        <Button className="custom-button w-25 mx-auto mt-1 " style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                            Add New Coupon
                        </Button>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tr>
                                        {/* <th class="text-center">
                                            <div class="custom-checkbox custom-checkbox-table custom-control">
                                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad"
                                                    class="custom-control-input" id="checkbox-all" />
                                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                                            </div>
                                        </th> */}
                                        {/* <th>LV Number</th>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th>Date Registered</th>
                                        <th>Expiry Date</th>
                                        <th>Status</th>
                                        <th>Email Address</th> */}
                                        <th>Id</th>
                                        <th>Code</th>
                                        <th>Type</th>
                                        <th>Discount %</th>
                                        <th>From</th>
                                        <th>Untill</th>
                                        <th>Actions</th>
                                    </tr>
                                    {/* {member_link?.map((ele, ind) => { */}
                                    {/* free?.map((ele, ind) => { */}

                                    {
                                        data?.map((ele, ind) => {
                                            return (
                                                <tr>
                                                    {/* <td class="p-0 text-center">
                                                        <div class="custom-checkbox custom-control">
                                                            <input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
                                                                id="checkbox-1" />
                                                            <label for="checkbox-1" class="custom-control-label">&nbsp;</label>
                                                        </div>
                                                    </td> */}
                                                    <td>{ele?._id}</td>
                                                    <td class="text-truncate">
                                                        {ele?.code}
                                                    </td>
                                                    <td class="align-middle">
                                                        {ele?.discountType}
                                                    </td>
                                                    <td class="align-middle">
                                                        {ele?.discountAmount}
                                                    </td>
                                                    <td class="align-middle">
                                                        <td>{setDates(ele?.validFrom)?.toString()?.split('T')[0]}</td>
                                                    </td>
                                                    <td class="align-middle">
                                                        <td>{setDates(ele?.validUntil)?.toString()?.split('T')[0]}</td>
                                                    </td>
                                                    {/* {console.log('ele.approval', ele.approval)} */}
                                                    {/* {ele.approval ?
                                                        <td>
                                                            <div class="badge badge-success">Approved</div>
                                                        </td>
                                                        : <td>
                                                            <div class="badge badge-success">Not Approved</div>
                                                        </td>} */}
                                                    <td>
                                                        <a href="#" className="btn btn-outline-primary" onClick={() => handleShow(ele)}>
                                                            Detail
                                                        </a>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Actions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* <Button className="" variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit2(fetchedData) }} >
                                            View 
                                        </Button>
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => blockAccount(fetchedData?._id)}>
                                            Block Account
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => deleteAccount(fetchedData?._id)}>
                                            Delete 
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit(fetchedData) }}>
                                            Change 
                                        </Button>
                                        {/* {fetchedData.approval ?
                                            <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { approveStory(fetchedData) }}>
                                                Remove Approval
                                            </Button>
                                            : <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { approveStory(fetchedData) }}>
                                                Approve Story
                                            </Button>} */}
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                                            Create Plan
                                        </Button> */}


                                        {/* {member_link==='deleted'?
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { reactivateAccount(fetchedData) }}>
                                            Reactivate Account
                                        </Button>
                                        :''} */}
                                        {/* {member_link==='blocked'?
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { unblockAccount(fetchedData) }}>
                                            Unblock Account
                                        </Button>
                                        :''} */}
                                        {/* Add more options as needed */}
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showDropdown} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        {/* <Modal.Title>Select a Plan</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Edit Story:</Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Code</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="code" id="" value={userPlan?.code} />
                                                <label for="name" class="">Discount Type</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="discountType" id="" value={userPlan?.discountType} />
                                                <label for="name" class="">Discount Amount</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="discountAmount" id="" value={userPlan?.discountAmount} />
                                                <label for="name" class="">Valid From</label>
                                                <input onChange={changePlan} type="date" className='form-control' name="validFrom" id="" value={userPlan?.validFrom} />
                                                <label for="name" class="">Valid Until</label>
                                                <input onChange={changePlan} type="date" className='form-control' name="validUntil" id="" value={userPlan?.validUntil} />
                                                {/* <label for="name" class="">Description</label> */}
                                                {/* <input onChange={changePlan} type="text" className='form-control' name="description" id="" value={userPlan?.description} /> */}
                                                {/* <textarea onChange={changePlan} className='form-control' name="description" id="" cols="30" rows="3" value={userPlan?.description}></textarea> */}
                                                {/* <label for="name" class="">Features</label> */}
                                                {/* <input onChange={changePlan}  type="text" className='form-control' name="features" id="" value={userPlan?.features} /> */}
                                                {/* </FormControl> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showDropdown2} onHide={handleClose4}>
                                    <Modal.Header closeButton>
                                        {/* <Modal.Title>Select a Plan</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                {/* <Form.Label>Viewing Story:</Form.Label> */}
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <p> <b>Code:</b> {userPlan2?.code}</p>
                                                <p> <b>Discount Type:</b> {userPlan2?.discountType}</p>
                                                <p> <b>Discount Amount:</b> {userPlan2?.discountAmount}</p>
                                                <p> <b>Valid From:</b> {userPlan2?.validFrom}</p>
                                                <p> <b>Valid Until:</b> {userPlan2?.validUntil}</p>
                                                {/* <p> <b>Description:</b> {userPlan2?.description}</p> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Save
                                        </Button> */}
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create new coupon</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Create:</Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Code</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="code" id="" />
                                                <label for="name" class="">Discount Type</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="discountType" id="" />
                                                <label for="name" class="">Discount Amount</label>
                                                <input onChange={newDataPlan} type="number" className='form-control' name="discountAmount" id="" />
                                                <label for="name" class="">Valid From</label>
                                                <input onChange={newDataPlan} type="date" className='form-control' name="validFrom" id="" />
                                                <label for="name" class="">Valid Until</label>
                                                <input onChange={newDataPlan} type="date" className='form-control' name="validUntil" id="" />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="custom-button" variant="secondary" onClick={submitNewDataPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Coupon_Table