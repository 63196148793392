import React, { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';
import '../Style/MembershipPlan/Plan.css'
import PaypalCheckOut2 from '../Paypal/PaypalCheckOut2';
import PaypalCheckOut3 from '../Paypal/PaypalCheckOut3';
let stripeKey = "pk_test_51MFGxxGsyHFe5lQADpu8EQF9BKy1N7wdpsmYQhwFGtv6ovbZ93uDWb6cTW92Dsr50q2p0zyjOI01O6VDYjthwf5K00zyktNRfo";
const My_Plan = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [price, setPrice] = useState(null);
    const [plan, setPlan] = useState(null);
    const [allPlans, setAllPlans] = useState(null);
    const [trialPeriodDays, setTrialPeriodDays] = useState(null);
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let fetchPlans = async (category) => {
        // let val=await axios.get(`/allPlanss/${category}`)
        try {
            let keyId = JSON.parse(localStorage.getItem('keyId'))
            console.log('keyId', keyId)
            let val = await axios.get(`/membership-plan/${keyId}`)
            // let val = await axios.get(`/membership-plan/6576963f966331254ce074ff`)
            console.log('val.data', val.data)
            setAllPlans(val.data)
        }
        catch (err) {
            console.log('err', err)
        }
    }
    useEffect(() => {
        fetchPlans()
    }, [])
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    let UpgradePlan = () => {
        
    }
    return (
        <div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="content">
                            <div className="section-header">
                                <h6 className="sub-title extra-padding">
                                    Your Recent Plans
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex">
                    <div className="col-lg-12">
                        <div className="pricing-plan-wrapper">
                                <div >
                            <div className="row" style={{display:'flex',justifyContent:'center'}}>
                                    {allPlans ?
                                        allPlans?.map((ele, ind) => {
                                            return (
                                                    <div className="col-lg-3 col-md-6 single-plan mr-3">
                                                        {/* <div className=""> */}
                                                            <p className="duration">
                                                                {ele.plan} Plan
                                                                <br />
                                                                <p style={{fontSize:'11px',fontWeight:'100'}} >invoice # ({ele?.invoiceNum})</p> 
                                                            </p>

                                                            <h4 className="number">
                                                                <sup>$</sup>{ele?.price/100}
                                                            </h4>
                                                            <p className="stamet" style={{textAlign:'center',margin:'auto'}}>
                                                                {/* {
                                                                        ele?.time_period_months !== 0 ? <>{ele?.time_period_months} Months</> : ''
                                                                    } */}
                                                                {
                                                                    ele?.trialPeriodDays !== 0 ? <>{ele?.trialPeriodDays} Days Plan</> : ''
                                                                }
                                                                <br />
                                                                {/* Paid with {ele?.paymentMethod} */}
                                                                 {/* {ele?.createdAt} */}
                                                                 <p style={{fontSize:'9px',fontWeight:'100',textAlign:'center',margin:'auto'}}>{setDates(ele?.createdAt)?.toString()?.split('T')[0]}</p>
                                                            </p>
                                                            <div >
                                                            </div>
                                                            {/* <div onClick={()=>UpgradePlan(ele._id)} className="custom-button">Upgrade Now!</div> */}
                                                            <a href="/allplan" className="custom-button">Upgrade Now!</a>
                                                            <img className="shape" src="assets/images/membership/plan-bg.png" alt="" />
                                                        {/* </div> */}
                                                     </div>
                                                    
                                            )
                                        })
                                        : ''}
                                </div >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default My_Plan