import React, { useState, useEffect } from 'react'
import axios from 'axios'
import User_Info from '../UserDetails/User_Info.jsx'
import '../Style/Notification/Notification_Header.css'
const Notification_Header = ({ socket }) => {
    const [notifications, setNotifications] = useState([]);
    let fetchDetails = async () => {
        let keyId = JSON.parse(localStorage.getItem('keyId'));
        try {
            let val = await axios.get(`/User/${keyId}`)
            console.log('val.data', val.data)
            console.log('val.data.notifications', val.data.notifications)
            setNotifications(val.data.notifications);
        }
        catch (err) {

        }
    }
    useEffect(() => {
        console.log('socket', socket)
        socket?.socket?.on("getNotification", (data) => {
            fetchDetails()
        });
        fetchDetails()
    }, [socket]);
    const handleRead = async () => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            console.log('val0');
            let id = JSON.parse(localStorage.getItem('keyId'));
            console.log('id', id);
            let val = await fetch('/deletingNotification/deletingNotification', {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id })
            })
            console.log('val.json()', val.json());
            setNotifications([]);
            // setOpen(false);
        };
    };

    return (
        <div className='Notification_Header'>
            <section className="section-50">
                <div className="container">
                    <h3 className="m-b-50 heading-line">Notifications <i className="fa fa-bell text-muted"></i></h3>
                    <div className="notification-ui_dd-content">
                        {notifications?.length > 0 &&
                            <p style={{ textAlign: 'end', cursor: 'pointer' }} onClick={handleRead}>
                                Mark all as read
                            </p>}
                        {notifications?.map((ele, ind) => {
                            return (
                                <div className="notification-list notification-list--unread">
                                    <div className="notification-list_content">
                                        {/* <div className="notification-list_img">
                                            <img src="https://i.imgur.com/zYxDCQT.jpg" alt="user" />
                                        </div> */}
                                        {
                                            ele?.type === "message" ?
                                                <div className="notification-list_detail">
                                                    <p><b> <User_Info title={'name'} id={ele?.sender} /> </b> has sent you a message</p>
                                                    <p className="text-muted">{ele?.content}</p>
                                                    {/* <p className="text-muted"><small>10 mins ago</small></p> */}
                                                </div>
                                                :
                                                ele?.type === "admin contact reply" ?
                                                    <div className="notification-list_detail">
                                                        <p><b> <User_Info title={'name'} id={ele?.sender} /> </b> (Admin) has replied to your query</p>
                                                        <p className="text-muted">{ele?.content}</p>
                                                        {/* <p className="text-muted"><small>10 mins ago</small></p> */}
                                                    </div>
                                                    :
                                                    ele?.type === "sent_Request" ?
                                                        <div className="notification-list_detail">
                                                            <p><b> <User_Info title={'name'} id={ele?.sender} /> </b>  has sent you a request</p>
                                                            {/* <p className="text-muted">{ele?.content}</p> */}
                                                            <p className="text-muted"><small>{ele?.date}</small></p>
                                                        </div>

                                                        : ''
                                        }
                                    </div>
                                    <div className="notification-list_feature-img">
                                        <img src="https://i.imgur.com/AbZqFnR.jpg" alt="Feature image" />
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div className="notification-list notification-list--unread">
                    <div className="notification-list_content">
                        <div className="notification-list_img">
                            <img src="https://i.imgur.com/w4Mp4ny.jpg" alt="user"/>
                        </div>
                        <div className="notification-list_detail">
                            <p><b>Richard Miles</b> liked your post</p>
                            <p className="text-muted">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
                                dolorem.</p>
                            <p className="text-muted"><small>10 mins ago</small></p>
                        </div>
                    </div>
                    <div className="notification-list_feature-img">
                        <img src="https://i.imgur.com/AbZqFnR.jpg" alt="Feature image"/>
                    </div>
                </div>
                <div className="notification-list">
                    <div className="notification-list_content">
                        <div className="notification-list_img">
                            <img src="https://i.imgur.com/ltXdE4K.jpg" alt="user"/>
                        </div>
                        <div className="notification-list_detail">
                            <p><b>Brian Cumin</b> reacted to your post</p>
                            <p className="text-muted">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
                                dolorem.</p>
                            <p className="text-muted"><small>10 mins ago</small></p>
                        </div>
                    </div>
                    <div className="notification-list_feature-img">
                        <img src="https://i.imgur.com/bpBpAlH.jpg" alt="Feature image"/>
                    </div>
                </div>
                <div className="notification-list">
                    <div className="notification-list_content">
                        <div className="notification-list_img">
                            <img src="https://i.imgur.com/CtAQDCP.jpg" alt="user"/>
                        </div>
                        <div className="notification-list_detail">
                            <p><b>Lance Bogrol</b> reacted to your post</p>
                            <p className="text-muted">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
                                dolorem.</p>
                            <p className="text-muted"><small>10 mins ago</small></p>
                        </div>
                    </div>
                    <div className="notification-list_feature-img">
                        <img src="https://i.imgur.com/iIhftMJ.jpg" alt="Feature image"/>
                    </div>
                </div>
                <div className="notification-list">
                    <div className="notification-list_content">
                        <div className="notification-list_img">
                            <img src="https://i.imgur.com/zYxDCQT.jpg" alt="user"/>
                        </div>
                        <div className="notification-list_detail">
                            <p><b>Parsley Montana</b> reacted to your post</p>
                            <p className="text-muted">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
                                dolorem.</p>
                            <p className="text-muted"><small>10 mins ago</small></p>
                        </div>
                    </div>
                    <div className="notification-list_feature-img">
                        <img src="https://i.imgur.com/bpBpAlH.jpg" alt="Feature image"/>
                    </div>
                </div> */}
                    </div>

                    {/* <div className="text-center">
                        <a href="#!" className="dark-link">Load more activity</a>
                    </div> */}

                </div>
            </section>
        </div>
    )
}

export default Notification_Header