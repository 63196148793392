import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../Style/Chat/Chat_Header.css'
import User_Info from '../UserDetails/User_Info'
import Chat_Section from './Chat_Section'
const Chat_Header = (props) => {
    console.log('Chat_Header_props', props)
    let mainUser = props.mainUser
    console.log('miainUser in chatprops', mainUser)
    let [chat, setChat] = useState([])
    let [chat2, setChat2] = useState([])
    const [selectedChat, setSelectedChat] = useState(null);
    const [show, setShow] = useState(false);

    let keyId = JSON.parse(localStorage.getItem('keyId'))
    const [receiver, setReceiver] = useState();
    const [message, sentMessage] = useState({ content: '', sender: keyId, receiver: receiver });
    const [socket, setSocket] = useState(null);
    let fetchChat = async () => {
        let keyId = JSON.parse(localStorage.getItem('keyId'))
        setShow(false)
        console.log('keyId', keyId)
        try {
            let val = await axios.get(`/chats/${keyId}`)
            console.log('val.data', val)
            console.log('val.data', val.data)
            setChat(val.data)
            if (chat) {
                setShow(true)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        fetchChat()
    }, [])
    let fetchChat2 = async () => {
        let keyId = JSON.parse(localStorage.getItem('keyId'))
        setShow(false)
        console.log('keyId', keyId)
        try {
            let val = await axios.get(`/all-initiated-by-others-chats/${keyId}`)
            console.log('val.data', val)
            console.log('val.data', val.data)
            setChat2(val.data)
            if (chat) {
                setShow(true)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        fetchChat2()
    }, [])
    const handleChatClick = (e, chatItem) => {
        setShow(false)
        e.preventDefault();
        setSelectedChat(chatItem);
        console.log('These keyId', keyId)
        console.log('These chatItem?.sender', chatItem?.sender)
        console.log('These chatItem?.receiver', chatItem?.receiver)
        if (keyId === chatItem?.sender) {
            setReceiver(chatItem?.receiver)
            sentMessage({ ...message, receiver: chatItem?.receiver })
        }
        else if (keyId === chatItem?.receiver) {
            setReceiver(chatItem?.sender)
            sentMessage({ ...message, receiver: chatItem?.sender })
        }
        console.log('chatItem', chatItem)
        setShow(true)
    };
    useEffect(() => {
        // Refetches chat when selected chat changes
        if (selectedChat) {
            console.log('fetching again')
            fetchChat();
        }
    }, [selectedChat]);
    useEffect(() => {
        // Refetches chat when selected chat changes
        if (selectedChat) {
            console.log('fetching again')
            fetchChat2();
        }
    }, [selectedChat]);
    // useEffect(() => {
    //     // Refetches chat when selected chat changes
    //     if (selectedChat) {
    //         console.log('fetching again')
    //         fetchChat();
    //     }
    // }, [chat]);
    // let sumValue=0;
    // let [chatMessage,setChatMessages]=useState(0);
    // useEffect(() => {
    //     socket.on('getNotification', (notificationData) => {
    //       console.log('Hey:', notificationData);
    //       setChatMessages(chatMessage+1);
    //     });
    //     return () => {
    //       socket.off('getNotification');
    //     };
    //   }, [socket]);
    return (
        <div className='Chat_Header'>
            <main className="content">
                <div className="container p-0">
                    <h1 className="h3 mb-3">Messages</h1>
                    <div className="card">
                        <div className="row g-0">
                            <div className="col-12 col-lg-5 col-xl-3 border-right">
                                <div className="px-4 d-none d-md-block">
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-grow-1">
                                            <input type="text" className="form-control my-3" placeholder="Search..." />
                                        </div> */}
                                    </div>
                                </div>
                                {/* <h6>Chats</h6> */}
                                {
                                    chat?.length !== 0 ?
                                        chat?.map((ele, ind) => {
                                            return (
                                                <a href='' className="list-group-item list-group-item-action border-0" key={ind} onClick={(e) => handleChatClick(e, ele)}>
                                                    <div className="badge bg-success float-right">{ele?.messages?.length}</div>
                                                    <div className="d-flex align-items-start">
                                                        {/* <img src="https://bootdey.com/img/Content/avatar/avatar5.png" className="rounded-circle mr-1" alt="Vanessa Tucker" width="40" height="40" /> */}
                                                        {ele?.photos && ele?.photos?.length !== 0 ?
                                                            <img src={`uploads/${ele?.photos[0]}`} className="rounded-circle mr-1" alt={ele?.name} width="40" height="40" />
                                                            :
                                                            <img src={'img/default_man.jpg'} className="rounded-circle mr-1" alt={ele?.name} width="40" height="40" />
                                                        }
                                                        <div className="flex-grow-1 ml-3">
                                                            {/* Vanessa Tucker */}
                                                            <User_Info id={ele?.receiver} title={'name'} />
                                                            {/* <div className="small"><span className="fas fa-circle chat-online"></span> Online</div> */}
                                                        </div>
                                                    </div>
                                                </a>
                                            )
                                        })
                                        : ''}
                                <hr className="d-block d-lg-none mt-1 mb-0" />
                                {/* <h6>Other Chat</h6> */}
                                {
                                    chat2?.length !== 0 ?
                                        chat2?.map((ele, ind) => {
                                            return (
                                                <a href='' className="list-group-item list-group-item-action border-0" key={ind} onClick={(e) => handleChatClick(e, ele)}>
                                                    <div className="badge bg-success float-right">{ele?.messages?.length}</div>
                                                    <div className="d-flex align-items-start">
                                                        {/* <img src="https://bootdey.com/img/Content/avatar/avatar5.png" className="rounded-circle mr-1" alt="Vanessa Tucker" width="40" height="40" /> */}
                                                        {/* <img src={`uploads/${ele?.photos[0]}`} className="rounded-circle mr-1" alt={ele?.name} width="40" height="40" /> */}
                                                        {ele?.photos && ele?.photos?.length !== 0 ?
                                                            <img src={`uploads/${ele?.photos[0]}`} className="rounded-circle mr-1" alt={ele?.name} width="40" height="40" />
                                                            :
                                                            <img src={'img/default_man.jpg'} className="rounded-circle mr-1" alt={ele?.name} width="40" height="40" />
                                                        }
                                                        <div className="flex-grow-1 ml-3">
                                                            {/* Vanessa Tucker */}
                                                            <User_Info id={ele?.sender} title={'name'} />
                                                            {/* <div className="small"><span className="fas fa-circle chat-online"></span> Online</div> */}
                                                        </div>
                                                    </div>
                                                </a>
                                            )
                                        })
                                        : ''}
                                <hr className="d-block d-lg-none mt-1 mb-0" />
                            </div>

                            <div className="col-12 col-lg-7 col-xl-9">
                                {
                                    show ?
                                        selectedChat?.receiver !== undefined ?
                                            // <Chat_Section selectedChat={selectedChat} receiver={receiver} mainUser={mainUser} socket={props?.socket} />
                                            <Chat_Section selectedChat={selectedChat} receiver={receiver} mainUser={mainUser} socket={props?.socket} me={props.me} activeUsers={props.activeUsers} />
                                            : ''
                                        : ''
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Chat_Header