import React,{useEffect, useState} from 'react'
import '../../../Style/Dashboard_Home/Dashboard_Card_Main1.css'
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import User_Info from '../../../UserDetails/User_Info';
import axios from 'axios';
const Dashboard_Card_Main1 = (params) => {
    let user = params.user
    let navigate = useNavigate()
    let [showModal,setShowModal] = useState(false)
    let [initiatedChats,setInitiatedChats] = useState([])
    const handleClose = () => setShowModal(false);
    let fetchInitiatedChat=async()=>{
        let keyId=JSON.parse(localStorage.getItem('keyId'))
        try {
            let val=await axios.get(`/all-initiated-chats/${keyId}`);
            setInitiatedChats(val.data.length)
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        try{
        fetchInitiatedChat()}
        catch(err)
        {}
    },[])
    return (
        <div className=''>
            Your Activity Summary
            <div className='Dashboard_Card_Main1'>
                <div className="card" style={{ "width": "11rem", marginBottom: '5px', padding: '1%' }}>
                    <div className="card-body" style={{ cursor: "pointer" }} onClick={() => { navigate('/inboxReceived') }}>
                        <h5 style={{ cursor: "pointer" }} className="card-title">{user?.recievedRequests?.length || 0}</h5>
                        <p style={{ cursor: "pointer" }} className="card-text">Pending Invitations</p>
                    </div>
                </div>
                <div className="card" style={{ "width": "11rem", marginBottom: '5px', padding: '1%' }}>
                    <div className="card-body" style={{ cursor: "pointer" }} onClick={() => { navigate('/inboxAccepted') }} >
                        <h5 style={{ cursor: "pointer" }} className="card-title">{user?.friends?.length || 0}</h5>
                        <p style={{ cursor: "pointer" }} className="card-text">Accepted Invitations</p>
                    </div>
                </div>
                <div className="card" style={{ "width": "11rem", marginBottom: '5px', padding: '1%' }}>
                    <div className="card-body" style={{ cursor: "pointer" }} onClick={() => { navigate('/moreMatches') }}>
                        <h5 style={{ cursor: "pointer" }} className="card-title">{user?.visiors?.length || 0} </h5>
                        <p style={{ cursor: "pointer" }} className="card-text">Recent Visitors</p>
                    </div>
                </div>
                {user?.accountType === 'free' ?
                    <>
                        <div className="card" style={{ "width": "11rem", marginBottom: '5px', padding: '1%' }}>
                            <div className="card-body">
                                <h5 className="card-title"></h5>
                                <p className="card-text"> <i> Only Premium Members can avail these benefits</i></p>
                            </div>
                        </div>

                        <div className="card" onClick={() => navigate('/allplan')} style={{ "width": "11rem", marginBottom: '5px', padding: '1%', opacity: "30%", cursor: 'pointer' }}>
                            <div className="card-body">
                                <h5 style={{ cursor: "pointer" }} className="card-title">{user?.contactsViewed?.length || 0}</h5>
                                <p style={{ cursor: "pointer" }} className="card-text" >Contacts Viewed</p>
                            </div>
                        </div>
                        <div className="card" onClick={() => navigate('/allplan')} style={{ "width": "11rem", marginBottom: '5px', padding: '1%', opacity: "30%", cursor: 'pointer' }}>
                            <div className="card-body">
                                <h5 style={{ cursor: "pointer" }} className="card-title">{initiatedChats || 0}</h5>
                                <p style={{ cursor: "pointer" }} className="card-text"  >Chats Initiated</p>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="card" onClick={()=>{setShowModal(true)}} style={{ "width": "11rem", marginBottom: '5px', padding: '1%', opacity: "100%", cursor: 'pointer' }}>
                            <div className="card-body">
                                <h5 style={{ cursor: "pointer" }} className="card-title">{user?.contactsViewed?.length || 0}</h5>
                                <p style={{ cursor: "pointer" }}  className="card-text" >Contacts Viewed</p>
                            </div>
                        </div>
                        <div className="card" onClick={() => navigate('/chat')} style={{ "width": "11rem", marginBottom: '5px', padding: '1%', opacity: "100%", cursor: 'pointer' }}>
                            <div className="card-body">
                                <h5 style={{ cursor: "pointer" }} className="card-title">{initiatedChats || 0}</h5>
                                <p style={{ cursor: "pointer" }} className="card-text"  >Chats Initiated</p>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contacts Viewed</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {user?.contactsViewed?.map((ele, ind) => {
                        return (
                            <>
                                {/* <h6>{ele?.name}</h6> */}
                                {console.log('ele',ele)}
                                <User_Info id={ele} title={'name'} />
                                <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => {
                                    localStorage.setItem('singleProfileId', JSON.stringify(ele?._id))
                                    navigate('/singleProfile')
                                }}>
                                    View Profile
                                </Button>
                            </>
                        )
                    })

                    }                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Dashboard_Card_Main1