import React, { useEffect, useState } from 'react'
import Admin_Sidebar from '../Admin_Home/Components/Admin_Sidebar'
import Admin_Home_Header from '../Admin_Home/Components/Admin_Home_Header'
import Admin_Home_Table from '../Admin_Home/Components/Admin_Home_Table'
import Admin_Members_Nav from '../Admin_Members/Components/Admin_Members_Nav'
import User_Panel_Header from '../../Components/User_Panel/User_Panel_Header'
import Admin_Plan_Table from '../Admin_Plan/Components/Admin_Plan_Table'
import Admin_Tickets_Table from '../Admin_Tickets/Components/Admin_Tickets_Table'
import Admin_Tickets_Nav from '../Admin_Tickets/Components/Admin_Tickets_Nav'
import Admin_Staff_Table from '../Admin_Staff/Components/Admin_Staff_Table'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Loader/Loader'
import Admin_Topbar from '../Admin_Topbar'

const Admin_Staff = () => {
    let [plans, updatePlans] = useState([])
    let allPlans = async () => {
        try {
            let val = await axios.get('/user');
            let val2 = []
            console.log('val in ', val)
            val?.data?.map((ele, ind) => {
                if (ele?.isEmployee === true && ele?.block === false) {
                    val2.push(ele);
                }
            })
            console.log('val2', val2)
            updatePlans(val2)
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        allPlans()
    }, [])

    let navigate = useNavigate()
    let [isAdmin, setIsAdmin] = useState()
    // let [isStaff, setIsStaff] = useState()
    let [loading, setLoading] = useState(true)
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let verify_Admin_And_Staff = async (keyId) => {
        try {
            let val = await axios.get(`/user/${keyId}`)
            console.log('val.data.isAdmin', val.data.isAdmin)
            console.log('val.data.isEmployee', val.data.isEmployee)
            setIsAdmin(val.data.isAdmin)
            // setIsStaff(val.data.isEmployee)
            setLoading(false)
        }
        catch (err) {
            setLoading(false)
            console.log(err)
        }
    }
    useEffect(() => {
        verify_Admin_And_Staff(keyId)
    }, [])
    return (
        loading ?
            (
                <Loader />
            )
            :
            isAdmin ?
                <div className='Admin_Home'>
                    <div id="app">
                        <div class="main-wrapper main-wrapper-1">
                            <div class=""></div>
                            <Admin_Topbar />
                            <div className='hideElements'>
                                <Admin_Sidebar />
                            </div>
                            <div class="main-content">
                                <section class="section">
                                    <Admin_Staff_Table data={plans} />
                                </section>
                            </div>
                        </div>
                    </div >
                </div >
                : navigate('/')
    )
}

export default Admin_Staff