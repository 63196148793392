import React, { useEffect, useState } from 'react'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Header from '../Components/Home/Header'
import Generate_Tickets_Header from '../Generate_Ticket_Components/Generate_Tickets_Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
const Generate_Tickets = () => {
  let navigate = useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div>
          <Header />
          <Dashboard_Breadcrumb />
          <Generate_Tickets_Header />
        </div>
        : navigate('/login')
  )
}

export default Generate_Tickets