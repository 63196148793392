import React, { useEffect, useState } from 'react'
import Dashboard_Referrals_Header from '../Dashboard/Components/Referrals/Dashboard_Referrals_Header'
import Dashboard_Breadcrumb from './Dashboard_Breadcrumb'
import Dashboard_Nav from '../Dashboard/Components/Navbar/Dashboard_Nav'
import Header from '../Components/Home/Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Dashboard_Referrals = () => {
  let [isUser, setIsUser] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
    <div>
        <Dashboard_Breadcrumb/>
        <Header/>
        <Dashboard_Nav/>
        <Dashboard_Referrals_Header/>
    </div>
    : navigate('/')
  )
}

export default Dashboard_Referrals