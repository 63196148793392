import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import '../../../Style/Dashboard_Home/Profile_Preferences_Header2.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Email_Alerts_Header2() {
    let [partner, setPartner] = useState(null)
    const [preferences, setPreferences] = useState({
        premiumMatch: partner?.premiumMatch,
        recentVisitor: partner?.recentVisitor,
        viewedProfile: partner?.viewedProfile,
        contact: partner?.contact,
        receivedMessage: partner?.receivedMessage,
        sms: partner?.sms,
    })
    const showToast = () => {
        toast.success('Request successful!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };

    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let fetchDetails = async () => {
        try {
            let val = await axios.get(`/alert/${keyId}`)
            console.log('val.data', val.data)
            setPartner(val.data)
            setPreferences({
                premiumMatch: val.data?.premiumMatch,
                recentVisitor: val.data?.recentVisitor,
                viewedProfile: val.data?.viewedProfile,
                contact: val.data?.contact,
                receivedMessage: val.data?.receivedMessage,
                sms: val.data?.sms,
            })
        } catch (error) {
            setPartner(error)
            console.log('error', error)
        }
    }
    const [preferences2, setPreferences2] = useState({})
    const [editingField, setEditingField] = useState(null);
    const [newOption, setNewOption] = useState('');
    const [communityOptions, setCommunityOptions] = useState([]);
    const fieldOptions = {
        // motherTongue: [
        //     'Awadhi',
        // ],
        premiumMatch: [
            'weekly',
            'unsubscribe',
        ],
        recentVisitor: [
            'daily',
            'unsubscribe',
        ],
        viewedProfile: [
            'weekly',
            'unsubscribe',
        ],
        contact: [
            'instant',
            'daily',
            'unsubscribe',
        ],
        receivedMessage: [
            'daily',
            'unsubscribe',
        ],
        sms: [
            'every Invitation',
            'every Accept',
        ],


    };

    const handleEdit = (field) => {
        setEditingField(field);
        setNewOption(preferences[field]);
    };

    const handleInputChange = (e) => {
        setNewOption(e.target.value);
        // setPreferences({ ...preferences, [editingField]: newOption });
    };

    const handleConfirm = async () => {
        if (window.confirm(`Confirm changes to ${editingField}?`)) {
            console.log('newOption before', newOption)
            console.log('preferences before', preferences)
            setPreferences({ ...preferences, [editingField]: newOption });
            setPreferences2(preferences.preferences);
            console.log('preferences after', preferences)
            console.log('preferences2', preferences2)
            console.log('newOption  after', newOption)
            try{
            let val = await fetch(`/alert/${keyId}`,
                {
                    method: "PUT",
                    headers: {
                        "content-Type": "application/json"
                    },
                    body: JSON.stringify(preferences)
                }
            )
            console.log('val', val)
            if (val.status === 200) {
                showToast()
                window.location.reload();
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }
            setEditingField(null);}
            catch(err)
            {
                
            }
        }
    };

    const handleCancel = () => {
        setEditingField(null);
    };

    const handleCheckboxChange = (e, field) => {
        const { value } = e.target;
        setPreferences(prevPreferences => ({
            ...prevPreferences,
            [field]: value
        }));
        console.log('preferences', preferences); // Note: preferences won't immediately reflect the update due to closure
    };


    useEffect(() => {
        fetchDetails()
    }, []);

    return (
        <div className="Profile_Preferences_Header2">
            <div className="partner-preferences">
                <h2>Email Alert</h2>
                <ToastContainer />
                {Object.entries(preferences).map(([field, value]) => (
                    <div className="preference-item" key={field}>
                        <span>{field}</span>
                        {editingField === field ? (
                            <div className='container'>
                                {
                                    (
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center' }}>
                                                <select name={field} value={preferences[field] || ''} onChange={(e) => handleCheckboxChange(e, field)}>
                                                    {fieldOptions[field] &&
                                                        fieldOptions[field].map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </>
                                    )}
                                <div className='mt-2 w-50'>
                                    <button className='custom-button w-5 m-1' onClick={handleConfirm}>Confirm</button>
                                    <button className='custom-button w-5' onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <span>{value}</span>
                        )}
                        <i className="edit-icon" onClick={() => handleEdit(field)}>
                            Edit
                        </i>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Email_Alerts_Header2;
