import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import User_Info from './User_Info';

const User_Card_Info3 = ({ ele,user }) => {
    let navigate = useNavigate()
    // Define images for the slider
    const images = [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
        // Add more image URLs here
    ];

    // State to manage the currently selected slide
    const [selectedSlide, setSelectedSlide] = useState(0);

    const handleSlideChange = (index) => {
        setSelectedSlide(index);
    };
    let nextPage = async (e, id) => {
        console.log('e', e.target)
        console.log('id', id)
        localStorage.setItem('singleProfileId', JSON.stringify(id))
        navigate('/singleprofile')
    }

    return (
        <>
            <div className="Card6 Card1">
                <div className="card-container">
                    <div className="image-slider">
                        <Carousel
                            showStatus={false}
                            showThumbs={false}
                            selectedItem={selectedSlide}
                            onChange={handleSlideChange}
                        >
                            {/* {images.map((image, index) => (
                                <div key={index}>
                                    <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src='img/fawad.png' alt={`Image ${index}`} />
                                </div>
                            ))} */}
                            {ele?.gender === 'male' ?
                                ele?.photos && ele?.photos?.length !== 0 ?
                                    ele?.photos?.map((image, index) => (
                                        <div key={index}>
                                            <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                        </div>
                                    ))
                                    :
                                    <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={'img/default_man.jpg'} />
                                :
                                ele?.photos && ele?.photos?.length !== 0 ?
                                    ele?.photos?.map((image, index) => (
                                        <div key={index}>
                                            <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                        </div>
                                    ))
                                    :
                                    <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={'img/default_woman.jpg'} />
                            }
                        </Carousel>
                    </div>

                    <div className="card-details">
                        <h4> <User_Info id={ele} title={'name'} /></h4>
                        <div style={{ display: 'flex', margin: '0%' }}>
                            <div onClick={(e) => nextPage(e, ele)} style={{ cursor: 'pointer', marginRight: "24px", paddingRight: "24px" }} >
                                <div style={{ display: 'flex', margin: '0%', justifyContent: 'space-between' }}>
                                    <div style={{}}>
                                        <p style={{ margin: '0%' }}><QuestionAnswerIcon style={{ fontSize: '14px', marginRight: '2px' }} />Online Now</p>
                                        <p style={{ margin: '0%', padding: '0%' }} ><User_Info id={ele} title={'age'} /> yrs , <User_Info id={ele} title={'height'} /></p>
                                        <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'motherTongue'} /> , <User_Info id={ele} title={'religion'} /></p>
                                        <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'city'} />, <User_Info id={ele} title={'country'} /></p>
                                        <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'education'} /></p>
                                        <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'occupation'} /></p>
                                    </div>
                                </div>
                                {/* <p style={{ width: "420px" }}>
                                    Looking for an understanding and caring life partner ..!!! Assalamu Alaikum, Caring And Intelligent ,...More
                                </p> */}
                            </div>
                            {/* <div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
                                <p> <i> Upgrade to Contact him directly</i></p>
                                <div>
                                    <button >  <CallIcon style={{ fontSize: '14px' }} /> Call</button>
                                    <button className='whatsapp'> <WhatsAppIcon style={{ fontSize: '14px' }} /> Whatsapp</button>
                                    <button> <SendIcon style={{ fontSize: '14px' }} /> Lovodi Chat</button>
                                </div>
                            </div> */}
                            {/* ele hun{ele} */}
                        </div>
                        
                        {
                            user && user.rejectedByReciever?.includes(ele) && (
                                // <div className="custom-message bg-danger text-white">Declined</div>
                                <p style={{ backgroundColor: '#FCEBEC' }}>
                                    This member cannot longer be contacted 
                                </p>
                            )
                        }

                    </div>
                </div>
            </div>
            {/* </div > */}
        </>
    );
};

export default User_Card_Info3;