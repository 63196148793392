import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';

const Blocked_By_You = (params) => {
    let navigate=useNavigate();
    const Unblock = async () => {
        try {
        let y=window.confirm('Do you really want to unblock?')      
        if(y){
            const response = await axios.post(`/user/unblock/${params.id}`);
            if(response.status==200)
            {
                alert('unblocked sucessfull!')
                navigate('/dashboard')
            }
            else{
                alert('error in proceeding!')
            }
        }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // setIsBlocked(true);
            }
        }
    };
    return (
        <div>
            <section className="section-404">
                <div className="container">
                    <div className="thumb-404">
                        <img src="assets/images/404.png" alt="404" />
                    </div>
                    <h3 className="title">Oops.. You have blocked the user :( </h3>
                    <button onClick={Unblock} className='custom-button'>Unblock User</button>
                    
                    {/* <a href="/dashboard" className="custom-button">Back To Home <i className="flaticon-right"></i></a> */}
                </div>
            </section>

        </div>
    )
}

export default Blocked_By_You