import React, { useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import User_Info from '../UserDetails/User_Info';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

const User_Card_Info2 = ({ ele, user, socket }) => {
    // Define images for the slider
    const images = [
        'image1.jpg',
        'image2.jpg',
        'image3.jpg',
        // Add more image URLs here
    ];
    let navigate = useNavigate()
    const [selectedSlide, setSelectedSlide] = useState(0);

    const handleSlideChange = (index) => {
        setSelectedSlide(index);
    };
    const showToast = () => {
        toast.success('Request Successful!!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };
    let Accepted = async (e, id) => {
        console.log(e)
        console.log(e.target)
        console.log(id)
        try {
            let val = await fetch(`accept/${id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                }
            })
            if (val.status === 200) {
                let date = new Date()
                date = date.toLocaleTimeString()
                let keyId = JSON.parse(localStorage.getItem('keyId'))
                socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: id,
                    receiverId: id,
                    type: 'received_Request',
                    date: date
                });

                showToast()
                window.location.reload();
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }
        }
        catch (err) {

        }

    }
    let Declined = async (e, id) => {
        console.log(e)
        console.log(e.target)
        console.log(id)
        try {
            let val = await fetch(`decline/${id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                }
            })
            if (val.status === 200) {
                showToast()
                window.location.reload();
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }
        }
        catch (err) {

        }
    }
    let nextPage = async (e, id) => {
        console.log('e', e.target)
        console.log('id', id)
        localStorage.setItem('singleProfileId', JSON.stringify(id))
        navigate('/singleprofile')
    }
    return (
        <div>
            <ToastContainer />
            
            <div  className="Card4 Card1">
                <div  class="row no-gutters border border-gray-300 rounded hov-shadow-md mb-4 has-transition position-relative"
                    id="block_id_42">

                    <div style={{minHeight:'390px'}}  className="card-container">
                        <div className="image-slider">
                            <Carousel
                                showStatus={false}
                                showThumbs={false}
                                selectedItem={selectedSlide}
                                onChange={handleSlideChange}
                            >
                                {/* {images.map((image, index) => (
                                <div key={index}>
                                    <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src='img/fawad.png' alt={`Image ${index}`} />
                                </div>
                            ))} */}
                                {ele?.gender === 'male' ?
                                    ele?.photos && ele?.photos?.length !== 0 ?
                                        ele?.photos?.map((image, index) => (
                                            <div key={index}>
                                                <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                            </div>
                                        ))
                                        :
                                        <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={'img/default_man.jpg'} />
                                    :
                                    ele?.photos && ele?.photos?.length !== 0 ?
                                        ele?.photos?.map((image, index) => (
                                            <div key={index}>
                                                <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={`uploads/${image}`} alt={`Image ${index}`} />
                                            </div>
                                        ))
                                        :
                                        <img style={{ width: '150px', height: '150px', borderRadius: "200%" }} src={'img/default_woman.jpg'} />
                                }
                            </Carousel>
                        </div>
                        <div className="card-details">
                            {/* <h4> <User_Info id={ele} title={'name'} /></h4> */}
                            {/* <div style={{ display: 'flex', margin: '0%' }}> */}
                                {/* <div onClick={(e) => nextPage(e, ele)} style={{ borderRight: '1px solid', marginRight: "24px", paddingRight: "24px", cursor: 'pointer' }} >
                                    <div style={{ display: 'flex', margin: '0%', justifyContent: 'space-between' }}>
                                        <div style={{}}>
                                            <p style={{ margin: '0%' }}><QuestionAnswerIcon style={{ fontSize: '14px', marginRight: '2px' }} />Online Now</p>
                                            <p style={{ margin: '0%', padding: '0%' }} ><User_Info id={ele} title={'age'} /> yrs , <User_Info id={ele} title={'height'} /></p>
                                            <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'motherTongue'} /> , <User_Info id={ele} title={'religion'} /></p>
                                            <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'city'} />, <User_Info id={ele} title={'country'} /></p>
                                            <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'education'} /></p>
                                            <p style={{ margin: '0%', padding: '0%' }}><User_Info id={ele} title={'occupation'} /></p>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="col-md position-static d-flex align-items-center">
                                    <div class="px-md-4 p-3 flex-grow-1">

                                        <h2 class="h6 fw-600 fs-18 text-truncate mb-1">
                                            <User_Info id={ele} title={'name'} /></h2>
                                        <div class="mb-2 fs-12">
                                            <span class="opacity-60">Member ID: </span>
                                            <span class="ml-4 text-primary"><User_Info id={ele} title={'_id'} /></span>
                                        </div>
                                        <table class="w-100 opacity-70 mb-2 fs-12">
                                            <tr onClick={(e) => nextPage(e, ele)}>
                                                <td class="py-1 w-25">
                                                    <span>age</span>
                                                </td>
                                                <td class="py-1 w-25 fw-400">
                                                    <User_Info id={ele} title={'age'} /></td>
                                                <td class="py-1 w-25"><span>Height</span></td>
                                                <td class="py-1 w-25 fw-400">
                                                    <User_Info id={ele} title={'height'} />
                                                </td>
                                            </tr>
                                            <tr onClick={(e) => nextPage(e, ele)}>
                                                <td class="py-1"><span>Religion</span></td>
                                                <td class="py-1 fw-400">
                                                    <User_Info id={ele} title={'religion'} />
                                                </td>
                                                <td class="py-1"><span>Caste</span></td>
                                                <td class="py-1 fw-400">
                                                    <User_Info id={ele} title={'community'} />
                                                </td>
                                            </tr>
                                            <tr onClick={(e) => nextPage(e, ele)}>
                                                <td class="py-1"><span>First Language</span>
                                                </td>
                                                <td class="py-1 fw-400">
                                                    <User_Info id={ele} title={'motherTongue'} />
                                                </td>
                                                <td class="py-1"><span>Marital Status</span>
                                                </td>
                                                <td class="py-1 fw-400">
                                                    <User_Info id={ele} title={'status'} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="py-1"><span>location</span></td>
                                                <td class="py-1 fw-400">
                                                    <User_Info id={ele} title={'city'} />
                                                </td>
                                            </tr>
                                        </table>
                                        <div class="row  text-center">
                                            <div style={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
                                                <p> <i> Invited you to Connect</i></p>
                                                <div>
                                    <button onClick={(e) => { Accepted(e, ele) }} className='whatsapp'> <DoneIcon  style={{ fontSize: '14px' }} /> Accept</button>
                                    <button onClick={(e) => { Declined(e, ele) }}>  <CancelIcon style={{ fontSize: '14px' }} /> Decline</button>
                                </div>
                                            </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default User_Card_Info2