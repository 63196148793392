import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Style/Cards/Card1.css';
import User_Card_Info4 from '../UserDetails/User_Card_Info4';
import Privacy_Check_Header from '../Privacy_Check_Components/Privacy_Check_Header';
const Card1 = (params) => {
    let user = params.user;
    let value = params.value;
    let mainUser = params.mainUser;
    let socket = params.socket;
    let onlineUsers = params.onlineUsers;
    let previousActive = params.previousActive;
    console.log('user and value', user)
    console.log('paramsCard1', params)
    
    return (
        value === 'newMatches' ?
            user?.length !== 0 ?
            user?.map((ele, ind) => {
                    if((!mainUser?.blockedUsers?.includes(ele?._id))&&(!ele?.blockedUsers?.includes(mainUser?._id)))
                    {
                    console.log('ele', ele)
                    return (
                        <>
                            {localStorage.setItem('newMatches', JSON.stringify(user?.length))}
                            <User_Card_Info4  previousActive={previousActive} onlineUsers={onlineUsers} ele={ele} mainUser={mainUser} socket={params.socket} />
                            {/* <Privacy_Check_Header id={ele?._id} title={'email'} /> */}
                        </>
                    )
                }
                })
                : ''
            :
            value === 'todayMatches' ?
                user?.length !== 0 ?
                    user?.map((ele, ind) => {
                        if((!mainUser?.blockedUsers?.includes(ele?._id))&&(!ele?.blockedUsers?.includes(mainUser?._id)))
                    {
                        console.log('ele', ele)
                        return (
                            <>
                                {localStorage.setItem('todayMatches', JSON.stringify(user?.length))}
                                <User_Card_Info4 previousActive={previousActive} onlineUsers={onlineUsers} ele={ele} mainUser={mainUser} socket={params.socket} />
                            </>
                        )
                    }
                    })
                    : ''
                :
                value === 'myMatches' ?
                    user?.length !== 0 ?
                        user?.map((ele, ind) => {
                            console.log('ele', ele)
                            if((!mainUser?.blockedUsers?.includes(ele?._id))&&(!ele?.blockedUsers?.includes(mainUser?._id)))
                    {
                            return (
                                <>
                                    {localStorage.setItem('myMatches', JSON.stringify(user?.length))}
                                    <User_Card_Info4 previousActive={previousActive} onlineUsers={onlineUsers} ele={ele} mainUser={mainUser} socket={params.socket} />
                                </>
                            )
                    }
                        })
                        : ''
                    :
                    value === 'nearMeMatches' ?
                        user?.length !== 0 ?
                            user?.map((ele, ind) => {
                                if((!mainUser?.blockedUsers?.includes(ele?._id))&&(!ele?.blockedUsers?.includes(mainUser?._id)))
                    {
                                console.log('ele', ele)
                                return (
                                    <>
                                        {localStorage.setItem('nearMeMatches', JSON.stringify(user?.length))}
                                        <User_Card_Info4 previousActive={previousActive} onlineUsers={onlineUsers} ele={ele} mainUser={mainUser} socket={params.socket} />
                                    </>
                                )
                    }
                            })
                            : ''
                        : ''

    );
};

export default Card1;