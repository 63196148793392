import React, { useEffect, useState } from 'react'
import Register_Header from '../Components/Register/Register_Header'
import Register_Modal from '../Components/Register/Register_Modal'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Register = () => {
  let navigate = useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      !isUser ?
        <div>
          <Register_Header />
          <Register_Modal />
        </div>
        : navigate('/dashboard')
  )
}

export default Register