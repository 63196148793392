import React, { useState } from 'react';
import '../Style/Admin/Admin_Topbar/Admin_Topbar.css'; // Create a CSS file for styling
import '../Style/Admin/Admin_Topbar/Admin_Topbar_main.css'; // Create a CSS file for styling
import { Link, useLocation, useNavigate } from 'react-router-dom';
const Admin_Topbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    let navigate=useNavigate();
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };
    const location = useLocation();
    return (
        <div className="Admin_Topbar2">
            <nav className="navbar" >
                <div className="logo"><img onClick={()=>navigate('/dashboard')} style={{ height: "90px", marginTop: "-20%", marginBottom: '-25%', padding: "0%" }} src="img/default4.png" alt="logo" /></div>
                
                <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-home" class="nav-link"><i data-feather="monitor"></i><span>Dashboard</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-members-active" class="nav-link"><i data-feather="monitor"></i><span>Members</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-profile" class="nav-link"><i data-feather="monitor"></i><span>Profile</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-plan" class="nav-link"><i data-feather="monitor"></i><span>Plans</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-package-payment" class="nav-link"><i data-feather="monitor"></i><span>Package payment</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-happy-stories" class="nav-link"><i data-feather="monitor"></i><span>Happy Stories</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-contact" class="nav-link"><i data-feather="monitor"></i><span>Contact Us inquiries</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-tickets-open" class="nav-link"><i data-feather="monitor"></i><span>Tickets</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-referrel" class="nav-link"><i data-feather="monitor"></i><span>Referral</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-staff" class="nav-link"><i data-feather="monitor"></i><span>Staff</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-blog" class="nav-link"><i data-feather="monitor"></i><span>Blog</span></a>
                    </li>
                    <li class="dropdown active">
                        <a onClick={toggleMobileMenu} href="/admin-social" class="nav-link"><i data-feather="monitor"></i><span>Social Media</span></a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Admin_Topbar;
