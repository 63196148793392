import React, { useEffect, useState } from 'react'
import Dashboard_Breadcrumb from '../Dashboard_Pages/Dashboard_Breadcrumb'
import Header from '../Components/Home/Header'
import Inbox_Navbar from './Inbox_Navbar'
import Card4 from '../Cards/Card4'
import Inbox_Sidebar from './Inbox_Sidebar'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const Received_Messages = ({socket,value}) => {
  let [isUser, setIsUser] = useState()
  let [user, setUser] = useState(null)
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  console.log('value inbox',value)
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/User/${keyId}`)
      console.log('val.data', val.data)
      setUser(val.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div>
          <Dashboard_Breadcrumb />
          <Header />
          <Inbox_Navbar />
          <div style={{ display: 'flex', margin: '1%' }}>
            <Inbox_Sidebar user={user} value='recieve' />
            <div>
              {
                user !== null ?
                  <Card4 socket={socket} user={user} value='recieve' mainValue={value} />
                  : ''
              }
            </div>
          </div>
        </div>
        : navigate('/')
  )
}

export default Received_Messages