import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
const Admin_Blog_Table = ({ data }) => {
    let params = useParams();
    let navigate = useNavigate();
    let member_link = JSON.parse(localStorage.getItem('member_link'));
    console.log('member_link', member_link)
    console.log('params', params)
    console.log('data here')
    console.log('data here', data)
    let [fetchedData, setFetchedData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [showNote2, setShowNote2] = useState(false);
    const [userPlan, setUserPlan] = useState({});
    const [newData, setNewData] = useState([]);
    const handleClose = () => setShowModal(false);
    const handleClose2 = () => setShowDropdown(false);
    const handleClose3 = () => setShowNote(false);
    const handleClose4 = () => setShowNote2(false);
    const handleShow = (ele) => {
        setShowModal(true)
        setFetchedData(ele)
    };
    let deleteAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/blog/${id}`,
                    {
                        method: "Delete"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) {

            }
        }
    }
    const handleEdit = (fetchedData) => {
        setShowDropdown(true); // Show the dropdown when the button is clicked
        setUserPlan(fetchedData)
    };
    const addNote = (fetchedData) => {
        setShowNote(true); // Show the dropdown when the button is clicked
    };
    const addNote2 = (fetchedData) => {
        setShowNote2(true); // Show the dropdown when the button is clicked
    };

    let changePlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            setUserPlan({ ...userPlan, [e.target.name]: value })
        }
        else {
            setUserPlan({ ...userPlan, [e.target.name]: e.target.value })
        }
    }
    let submitPlan = async (e) => {
        e.preventDefault()
        console.log(userPlan)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/blog/${fetchedData?._id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(userPlan)
            })
            if (val.status === 200) {
                alert('changes updated!')
                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (err) {

        }
    }

    let newDataPlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            console.log('balue', value)
            setNewData({ ...newData, [e.target.name]: value })
        }
        else {
            setNewData({ ...newData, [e.target.name]: e.target.value })
        }
    }
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file)
        //   setNewData({...newData,[e.target.name]:file});
    };
    let submitNewDataPlan = async (e) => {
        e.preventDefault()
        // console.log('notePlan', notePlan)
        const formData = new FormData();
        formData.append('img', selectedImage);
        formData.append('title', newData?.title);
        formData.append('category', newData?.category);
        formData.append('description', newData?.description);

        console.log('submitNewDataPlan', newData)
        console.log(fetchedData?._id)
        try{
        let val = await fetch(`/blog`, {
            method: "POST",
            // headers: {
            //     "content-type": "application/json"
            // },
            body: formData
        })
        if (val.status === 200) {
            alert('new plan added!')
            window.location.reload()
        }
        else { alert('error!') }}
        catch(err){
            
        }
    }

    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    return (
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            {/* <h4>Plans</h4> */}
                            <div class="card-header-form">
                                {/* <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" />
                                        <div class="input-group-btn">
                                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        <Button className="custom-button w-25 mx-auto mt-1 " style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                            Create Blog
                        </Button>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tr>
                                        {/* <th>LV Number</th>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th>Date Registered</th>
                                        <th>Expiry Date</th>
                                        <th>Status</th>
                                        <th>Email Address</th> */}
                                        <th>Id</th>
                                        <th>Title</th>
                                        <th>Date</th>
                                        <th>Category</th>
                                        {/* <th>Images</th> */}
                                        <th>Actions</th>
                                    </tr>
                                    {/* {member_link?.map((ele, ind) => { */}
                                    {/* free?.map((ele, ind) => { */}

                                    {
                                        data?.map((ele, ind) => {
                                            return (
                                                <tr>
                                                    {/* <td class="p-0 text-center">
                                                        <div class="custom-checkbox custom-control">
                                                            <input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
                                                                id="checkbox-1" />
                                                            <label for="checkbox-1" class="custom-control-label">&nbsp;</label>
                                                        </div>
                                                    </td> */}
                                                    <td>{ele?._id}</td>
                                                    <td class="text-truncate">
                                                        {ele?.title}
                                                    </td>
                                                    {/* <td class="align-middle">
                                                        {ele?.createdAt}
                                                    </td> */}
                                                    <td>{setDates(ele?.createdAt)?.toString()?.split('T')[0]}</td>
                                                    <td class="align-middle">
                                                        {ele?.category}
                                                    </td>
                                                    {/* <td class="align-middle">
                                                        {ele?.category}
                                                    </td> */}
                                                    {/* <td class="align-middle">
                                                        {
                                                            ele?.features?.map((ele, ind) => {
                                                                return (
                                                                    <>
                                                                        {ele}
                                                                        <br />
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </td> */}
                                                    <td>
                                                        <a href="#" className="btn btn-outline-primary" onClick={() => handleShow(ele)}>
                                                            Detail
                                                        </a>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Actions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* <Button className="" variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => {
                                            addNote2(fetchedData)
                                        }}>
                                            View
                                        </Button>
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => blockAccount(fetchedData?._id)}>
                                            Block Account
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => deleteAccount(fetchedData?._id)}>
                                            Delete
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit(fetchedData) }}>
                                            Update
                                        </Button>
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                                            Create Plan
                                        </Button> */}


                                        {/* {member_link==='deleted'?
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { reactivateAccount(fetchedData) }}>
                                            Reactivate Account
                                        </Button>
                                        :''} */}
                                        {/* {member_link==='blocked'?
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { unblockAccount(fetchedData) }}>
                                            Unblock Account
                                        </Button>
                                        :''} */}
                                        {/* Add more options as needed */}
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showDropdown} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        {/* <Modal.Title>Select a Plan</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Edit </Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Title</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="title" id="" value={userPlan?.title} />
                                                <label for="name" class="">Category</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="category" id="" value={userPlan?.category} />
                                                <label for="name" class="">Description</label>
                                                <textarea onChange={changePlan} className='form-control' name="description" id="" cols="30" rows="3" value={userPlan?.description}></textarea>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Create new Plan</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            {/* <Form.Group> */}
                                            {/* <Form.Label>Select a plan:</Form.Label> */}
                                            {/* <FormControl   as="select" multiple> */}
                                            {/* <textarea name="description" onChange={changeNote} value={notes?.description}>{notes?.description}</textarea> */}
                                            {/* </FormControl> */}
                                            {/* </Form.Group> */}
                                            <Form.Group>
                                                <Form.Label>Create:</Form.Label>
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Title</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="title" id="" />
                                                <label for="name" class="">Category</label>
                                                <input onChange={newDataPlan} type="text" className='form-control' name="category" id="" />
                                                <label for="name" class="">Description</label>
                                                <textarea onChange={newDataPlan} className='form-control' name="description" id="" cols="30" rows="3"></textarea>
                                                <label htmlFor="img" className="">Photos</label>
                                                <input type="file" accept="image/*" name="img" onChange={handleImageChange} />
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="custom-button" variant="secondary" onClick={submitNewDataPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote2} onHide={handleClose4}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                {/* <Form.Label></Form.Label> */}
                                                {/* <FormControl onChange={changePlan} name="accountType" as="select" multiple> */}
                                                <br />
                                                <label for="name" class="">Title:</label>
                                                <p>{fetchedData?.title}</p>
                                                <label for="name" class="">Category:</label>
                                                <p>{fetchedData?.category}</p>
                                                <label for="name" class="">Description:</label>
                                                <p>{fetchedData?.description}</p>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="custom-button" variant="secondary" onClick={submitNewDataPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Blog_Table