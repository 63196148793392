import React, { useEffect, useState } from 'react'
import Card1 from '../Cards/Card1'
import Header from '../Components/Home/Header'
import Members_Breadcrumb from '../Components/Members/Members_Breadcrumb'
import Matches_Nav from '../Matches_Components/Matches_Nav'
import Card8 from '../Cards/Card8'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader/Loader'
import axios from 'axios'
const More_Matches = ({socket}) => {
  let [isUser, setIsUser] = useState()
  let [user, setUser] = useState()
  let [user2, setUser2] = useState()
  let [user3, setUser3] = useState()
  let [details, setDetails] = useState()
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
  let [loading, setLoading] = useState(true)
  let navigate = useNavigate()
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])

  let fetchDetails = async (keyId) => {
    try {
      let val = await axios.get(`/user/${keyId}`)
      console.log('val.data', val.data)
      setDetails(val?.data)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser = async (keyId) => {
    try {
      let val = await axios.get(`/User/${keyId}`)
      console.log('val.data', val.data)
      setUser(val.data)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser2 = async (keyId) => {
    try {
      let val = await axios.get(`/users/premium/${keyId}`)
      // let val = await axios.get(`/users/search/${keyId}`)
      console.log('val.data_premium', val)
      console.log('val.data_premium', val.data)
      setUser2(val?.data?.potentialMatches)
    } catch (error) {
      console.log(error)
    }
  }
  let fetchUser3 = async (keyId) => {
    try {
      let val = await axios.get(`/users/members-looking-for-me//${keyId}`)
      // let val = await axios.get(`/users/search/${keyId}`)
      console.log('val.data_premium', val)
      console.log('val.data_premium', val.data)
      setUser3(val?.data?.userIDs)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    fetchUser(keyId)
    fetchUser2(keyId)
    fetchUser3(keyId)
    fetchDetails(keyId)
  }, [])


  return (
    loading ?
      (
        <Loader />
      )
      :
      isUser ?
        <div className='My_Matches'>
          <Header />
          <Members_Breadcrumb />
          <Matches_Nav />
          <div style={{ margin: '1% auto', textAlign: 'center' }}>
          <Card8 socket={socket} mainUser={details} user={user} value='visitors' />
          <Card8 socket={socket} mainUser={details} user2={user2} value='premium' />
          <Card8 socket={socket} mainUser={details} user3={user3} value='looking_for_me' />

          </div>

        </div>
        : navigate('/')
  )
}

export default More_Matches