import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../Style/Cards/Card7.css';
import User_Card_Info3 from '../UserDetails/User_Card_Info3';
const Card7 = (params) => {
    let user = params.user;
    let value = params.value;
    return (
        user?.rejectedByReciever?.length !== 0 ?
            user?.rejectedByReciever?.map((ele, ind) => {
                console.log('ele', ele)
                return (
                    <>
                        <User_Card_Info3 user={user} ele={ele} />
                    </>
                )
            })
            : ''
    );
};

export default Card7;