import React, { useEffect, useState } from 'react'
import Admin_Sidebar from '../Admin_Home/Components/Admin_Sidebar'
import Admin_Home_Header from '../Admin_Home/Components/Admin_Home_Header'
import Admin_Home_Table from '../Admin_Home/Components/Admin_Home_Table'
import Admin_Members_Nav from '../Admin_Members/Components/Admin_Members_Nav'
import axios from 'axios'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom'
import Admin_Topbar from '../Admin_Topbar'

const Admin_Members = () => {
  let [data, setData] = useState([])
  let fetchDetails = async () => {
    try {
      let val = await axios.get('/user')
      let val2 = []
      val?.data?.map((ele, ind) => {
        if (ele?.isAdmin === false) {
          val2.push(ele)
        }
      })
      setData(val2)
    }
    catch (err) { }
  }
  useEffect(() => {
    fetchDetails()
  }, [])

  let navigate = useNavigate()
  let [isAdmin, setIsAdmin] = useState()
  let [isStaff, setIsStaff] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  let verify_Admin_And_Staff = async (keyId) => {
    try {
      let val = await axios.get(`/user/${keyId}`)
      console.log('val.data.isAdmin', val.data.isAdmin)
      console.log('val.data.isEmployee', val.data.isEmployee)
      setIsAdmin(val.data.isAdmin)
      setIsStaff(val.data.isEmployee)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    verify_Admin_And_Staff(keyId)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      isAdmin || isStaff ?
        <div className='Admin_Home'>
          <div id="app">
            <div class="main-wrapper main-wrapper-1">
              <div class="">  </div>
              <Admin_Topbar />
              <div className='hideElements'>
                <Admin_Sidebar />
              </div>
              <div class="main-content">
                <section class="section">
                  <Admin_Members_Nav />
                  <Admin_Home_Table data={data} />
                </section>
              </div>
            </div>
          </div >
        </div >
        : navigate('/login')
  )
}

export default Admin_Members