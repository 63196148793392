// import Button from "@material-ui/core/Button"
// import IconButton from "@material-ui/core/IconButton"
// import TextField from "@material-ui/core/TextField"
import AssignmentIcon from "@material-ui/icons/Assignment"
import PhoneIcon from "@material-ui/icons/Phone"
import React, { useEffect, useRef, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Peer from "simple-peer"
import io from "socket.io-client"
import "../Style/Chat/Lobby/Lobby.css"
import User_Info from "../UserDetails/User_Info"
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import process from 'process'

// const socket = io.connect('http://localhost:5000')
function Lobby_Header({ mainUser, me2, socket, activeUsers }) {
	const [me, setMe] = useState(me2)
	const [videoEnabled, setVideoEnabled] = useState(true); // Initial state for video enabled
	const [stream, setStream] = useState()
	const [receivingCall, setReceivingCall] = useState(false)
	const [caller, setCaller] = useState("")
	const [callerSignal, setCallerSignal] = useState()
	const [callAccepted, setCallAccepted] = useState(false)
	const [idToCall, setIdToCall] = useState("")
	const [callEnded, setCallEnded] = useState(false)
	const [name, setName] = useState('')
	// mainUser?.email
	const myVideo = useRef(null)
	const userVideo = useRef()
	const connectionRef = useRef()
	useEffect(() => {                       
		// navigator.mediaDevices.getUserMedia({ video: true, audio: true })?.then((stream) => {
		navigator.mediaDevices.getUserMedia({ video: videoEnabled, audio: true })?.then((stream) => {
			setStream(stream)
			if (myVideo.current) {
				myVideo.current.srcObject = stream
			  }
		})

		socket.on("me", (id) => {
			setMe(id)
		})

		socket.on("callUser", (data) => {
			setReceivingCall(true)
			setCaller(data.from)
			// setName(mainUser?.email)
			setName(data.name)
			setCallerSignal(data.signal)
		})
	}, [])
	useEffect(() => {
		const constraints = { audio: true, video: videoEnabled }; // Apply video constraint based on videoEnabled state
	
		navigator.mediaDevices.getUserMedia(constraints)
		  .then((userStream) => {
			setStream(userStream);
			if (myVideo.current) {
				myVideo.current.srcObject = userStream;
			  }
		  })
		  .catch((error) => {
			console.error('Error accessing media devices:', error);
		  });
	
		return () => {
		  // Clean up by stopping tracks when component unmounts or video is toggled off
		  if (stream) {
			stream.getTracks().forEach((track) => track.stop());
		  }
		};
	  }, [videoEnabled]);
	//   useEffect(() => {
	// 	// Other existing code...
	
	// 	socket.on("callEnded", () => {
	// 		// Handle the call ending event here
	// 		console.log("Call has ended");
	// 		// Additional cleanup or UI updates if needed
	// 	});
	
	// 	// Cleanup when the component unmounts
	// 	return () => {
	// 		socket.off("callEnded"); // Remove the event listener to avoid memory leaks
	// 		// Other cleanup code if needed
	// 	};
	// }, []);
	// }, [name])

	const callUser = (id) => {
		console.log(id)
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("callUser", {
				userToCall: id,
				signalData: data,
				from: me,
				name: name
			})
		})
		peer?.on("stream", (stream) => {

			userVideo.current.srcObject = stream

		})
		socket.on("callAccepted", (signal) => {
			setCallAccepted(true)
			peer.signal(signal)
		})

		connectionRef.current = peer
	}

	const answerCall = () => {
		setCallAccepted(true)
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: stream
		})
		peer.on("signal", (data) => {
			socket.emit("answerCall", { signal: data, to: caller })
		})
		peer?.on("stream", (stream) => {
			userVideo.current.srcObject = stream
		})

		peer.signal(callerSignal)
		connectionRef.current = peer
	}
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		// Your code here
	// 		window.process = {};
	// 		// window.process = {
	// 		//   ...window.process,
	// 		// };
	// 	  }, 1);
		  
	//   }, []);
	const notifyCallEnd = () => {
		socket.emit("endCall");
	};
	// const leaveCall = () => {
	// 	notifyCallEnd()
	// 	setCallEnded(true)
	// 	console.log('connectionRef.current',connectionRef.current)
	// 	console.log('stream',stream)
	// 	if (stream) {
	// 		stream.getTracks().forEach((track) => track.stop());
	// 	}
	// 	if (connectionRef.current) {
	// 			// connectionRef?.current?.destroy(); 
				
	// 	  }
	// }
	
// const leaveCall = () => {
//     notifyCallEnd(); // Notify the server that the call is ending

//     if (connectionRef.current) {
//         // connectionRef.current.destroy();
// 		connectionRef.current.destroy();
// 		connectionRef.current = null
//     }

//     // Clean up the stream
//     if (stream) {
//         stream.getTracks().forEach((track) => track.stop());
//     }

//     setCallEnded(true);
//     setCallAccepted(false); // Reset callAccepted state
//     setReceivingCall(false); // Reset receivingCall state
//     setCaller(""); // Reset caller state
//     setCallerSignal(null); // Reset callerSignal state
//     setIdToCall(""); // Reset idToCall state
//     setName(""); // Reset name state
//     setVideoEnabled(true); // Reset videoEnabled state if needed
// 	return () => {
// 		socket.off("callEnded"); // Remove the event listener to avoid memory leaks
// 		// Other cleanup code if needed
// 	};
// }
const leaveCall = () => {
    console.log("Leaving call...");

    notifyCallEnd();

    if (connectionRef.current) {
        console.log("Destroying connection...");
        connectionRef.current.destroy();
        connectionRef.current = null;
    }

    if (stream) {
        console.log("Stopping tracks...");
        stream.getTracks().forEach((track) => track.stop());
    }

    setCallEnded(true);
    setCallAccepted(false);
    setReceivingCall(false);
    setCaller("");
    setCallerSignal(null);
    setIdToCall("");
    setName("");
    setVideoEnabled(true);

    console.log("Call left successfully.");

    return () => {
        console.log("Removing event listener...");
        socket.off("callEnded");
        // Other cleanup code if needed
    };
};
	console.log('me', me)
	const toggleVideo = () => {
		setVideoEnabled((prevVideoEnabled) => !prevVideoEnabled); // Toggle videoEnabled state
	};
	return (
		<>
			<div className="Lobby">
				<h1 style={{ textAlign: "center", color: '#fff', marginBottom: "3%", paddingTop: "3%" }}>My LOVODI</h1>
				<div className="container">
					<div className="video-container">
						<div className="video">
							{/* style={{ width: "300px" }} */}
							{/* muted */}
							{stream &&
								<>
									<h3 style={{ fontSize: '18px', textAlign: 'center', color: 'white' }}>You</h3>
									<video className="" playsInline ref={myVideo} autoPlay controls muted controlslist
									/>
									<button style={{background:'none',color:'white',border:'none',outline:'none'}} onClick={toggleVideo}>
										{/* {videoEnabled ? 'Turn Video Off' : 'Turn Video On'} */}
										{videoEnabled ? <VideocamOffIcon /> : <VideocamIcon />}
									</button>

									{/* <button onClick={toggleMute}>{muted ? 'Unmute' : 'Mute'}</button> */}

								</>
							}
						</div>
						<div className="video">
							{/* // style={{ width: "300px"}} */}
							{callAccepted && !callEnded ?
								<>
									<h3 style={{ fontSize: '18px', textAlign: 'center', color: 'white' }}>User({idToCall}) </h3>
									{/* <User_Info id={rec} /> */}
									{console.log('userVideo', userVideo)}
									<video playsInline ref={userVideo} autoPlay controls controlslist controlsList="nodownload"
									/>
								</>
								:

								null}
						</div>
					</div>
					<div className="myId">
						<input
							id="filled-basic"
							placeholder="name"
							variant="filled"
							value={name}
							type="text"
							// value={mainUser?.email}
							onChange={(e) => setName(e.target.value)}
							// onChange={(e) => setName(mainUser?.email)}
							style={{ marginBottom: "20px" }}
						/>
						{/* <TextField
							id="filled-basic"
							label="name"
							variant="filled"
							value={name}
							// value={mainUser?.email}
							onChange={(e) => setName(e.target.value)}
							// onChange={(e) => setName(mainUser?.email)}
							style={{ marginBottom: "20px" }}
						/> */}
						<CopyToClipboard text={me} style={{ marginBottom: "2rem" }}>
							<button variant="contained" color="primary" startIcon={<AssignmentIcon fontSize="large" />}>
								Copy ID
							</button>
							{/* <Button variant="contained" color="primary" startIcon={<AssignmentIcon fontSize="large" />}>
								Copy ID
							</Button> */}
						</CopyToClipboard>

						<input
							id="filled-basic"
							type="text"
							placeholder="ID to call"
							variant="filled"
							value={idToCall}
							onChange={(e) => setIdToCall(e.target.value)}
						/>
						{/* <TextField
							id="filled-basic"
							label="ID to call"
							variant="filled"
							value={idToCall}
							onChange={(e) => setIdToCall(e.target.value)}
						/> */}
						<div className="call-button">
							{callAccepted && !callEnded ? (
								// <Button variant="contained" color="secondary" onClick={leaveCall}>
								<button variant="contained" color="secondary" onClick={leaveCall}>
									End Call
								</button>
							) : (
								<iconButton color="primary" aria-label="call" onClick={() => callUser(idToCall)}>
									<PhoneIcon fontSize="large" />
								</iconButton>
							)}
							{/* {idToCall} */}
						</div>
							{/* <IconButton color="primary" aria-label="call" onClick={() => callUser(idToCall)}>
										<PhoneIcon fontSize="large" />
									</IconButton> */}
					</div>
					<div>
						{receivingCall && !callAccepted ? (
							<div className="caller">
								<h1 >{name} is calling...</h1>
								<button variant="contained" color="primary" onClick={answerCall}>
									Answer
								</button>
								{/* <Button variant="contained" color="primary" onClick={answerCall}>
									Answer
								</Button> */}
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	)
}

export default Lobby_Header