import React, { useEffect, useState } from 'react'
import { io } from "socket.io-client";
import { Routes, Route } from 'react-router-dom'
import Home from './Pages/Home'
import Community from './Pages/Community'
import Membership from './Pages/Membership'
import Blog from './Pages/Blog'
import Blog_Single from './Pages/Blog_Single'
import User_Panel from './Pages/User_Panel'
import About from './Pages/About'
import Community_Single from './Pages/Community_Single'
import Members from './Pages/Members'
import Profile_Single from './Pages/Profile_Single'
import Contact from './Pages/Contact'
import Register from './Pages/Register'
import Login from './Pages/Login'
import Error_404 from './Pages/Error_404'
import Dashboard_Home from './Dashboard_Pages/Dashboard_Home'
import Dashboard_MyProfile from './Dashboard_Pages/Dashboard_MyProfile'
import Dashboard_Profile_Preferences from './Dashboard_Pages/Dashboard_Profile_Preferences'
import Dashboard_MyPhotos from './Dashboard_Pages/Dashboard_MyPhotos'
// import Dashboard_MyOrders from './Dashboard_Pages/Dashboard_MyOrders'
// import Search_Home from './Search_Pages/Search_Home'
import Chat_Home from './Chat_Pages/Chat_Home'
import Chat_Initiation from './Chat_Pages/Chat_Initiation'
import Notification_Home from './Notification_Pages/Notification_Home'
// import Help_Home from './Help_Pages/Help_Home'
// import Billing_Home from './Components/Billing_Pages/Billing_Home'
import './App.css'
import DashboardMyContact from './Dashboard_Pages/DashboardMyContact'
import Dashboard_Referrals from './Dashboard_Pages/Dashboard_Referrals'
import Inbox_Navbar from './Inbox_Components/Inbox_Navbar'
// import Inbox_Home from './Inbox_pages/Inbox_Home'
import Contact_Filter from './Contact_Fllters_Pages/Contact_Filter'
import Email_Alerts from './Email_Alerts_Pages/Email_Alerts'
import Privacy_Policy from './Privacy_Policy_Pages/Privacy_Policy'
import New_Matches from './Matches_Components/New_Matches'
import My_Matches from './Matches_Components/My_Matches'
import Near_Me_Matches from './Matches_Components/Near_Me_Matches'
import More_Matches from './Matches_Components/More_Matches'
import Recently_Viewed_Matches from './Matches_Components/Recently_Viewed_Matches'
import Accepted_Messages from './Inbox_Components/Accepted_Messages'
import Received_Messages from './Inbox_Components/Received_Messages'
import Deleted_Messages from './Inbox_Components/Deleted_Messages'
import Sent_Messages from './Inbox_Components/Sent_Messages'
// import Requested_Messages from './Inbox_Components/Requested_Messages'
import Purchase_Home from './Components/Purchase_Pages/Purchase_Home'
import Today_Matches from './Matches_Components/Today_Matches'
import Cookies from 'js-cookie';
import Admin_Members from './Admin/Pages/Admin_Members';
import Admin_Home from './Admin/Pages/Admin_Home';
import Admin_Profile from './Admin/Pages/Admin_Profile';
import Admin_Plan from './Admin/Pages/Admin_Plan';
import Admin_Happy_Stories from './Admin/Pages/Admin_Happy_Stories';
import Admin_Coupon from './Admin/Pages/Admin_Coupon';
import Admin_Contact from './Admin/Pages/Admin_Contact';
import Admin_Tickets from './Admin/Pages/Admin_Tickets';
import Admin_Terms from './Admin/Pages/Admin_Terms';
// import Admin_Notifications from './Admin/Pages/Admin_Notifications';
import Admin_Referrel from './Admin/Pages/Admin_Referrel';
import Admin_Blog from './Admin/Pages/Admin_Blog';
import Admin_Staff from './Admin/Pages/Admin_Staff';
import Admin_Package_Payment from './Admin/Pages/Admin_Package_Payment';
import Generate_Tickets from './Generate_Ticket_Pages/Generate_Tickets';
import Change_Password_Home from './Components/Change_Password_Pages/Change_Password_Home';
import Admin_Social from './Admin/Pages/Admin_Social';
import Forget_Part_One from './Forget_Pages/Forget_Part_One';
import Forget_Password from './Forget_Pages/Forget_Password';
import Lobby_Home from './Lobby_Pages/Lobby_Home';
import Lobby_Home2 from './Lobby_Pages/Lobby_Home2';
import Room from './Chat_Components/Room';
import Dashboard_MyProfile_View from './Dashboard_Pages/Dashboard_MyProfile_View';
const App = () => {
  const [socket, setSocket] = useState(null);
  const [me, setMe] = useState('');
  const [activeUsers, setActiveUsers] = useState([]);
  useEffect(() => {
    const token = getTokenFromCookie();
    console.log('token', token)
    // setSocket(io('http://localhost:8000', { query: { token } }))
    setSocket(io('http://lovodi.in', { query: { token } }))
  }, [])

  function getTokenFromCookie() {
    console.log('no token')
    return Cookies.get('user');
  }
  useEffect(() => {
    let keyId = JSON.parse(localStorage.getItem('keyId'));
    console.log('keyId', keyId)
    socket?.emit("newUser", keyId);
  }, [socket]);

  useEffect(() => {
    // const newSocket = io('http://localhost:8000'); // Replace with your backend URL
    const newSocket = io('http://lovodi.in'); // Replace with your backend URL
    setSocket(newSocket);

    newSocket.on('me', (id) => {
      setMe(id);
    });

    newSocket.on('onlineUsers', (users) => {
      setActiveUsers(users);
    });

    // Cleanup on unmount
    return () => {
      newSocket.disconnect();
    };  }, []);
  
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      {/* <Route  path='/community' element={<Community/>} /> */}
      {/* <Route path='/membership' element={<Membership />} /> */}
      {/* <Route  path='/blog' element={<Blog/>} /> */}
      {/* <Route path='/singleblog' element={<Blog_Single />} /> */}
      <Route path='/userpanel' element={<User_Panel />} />
      {/* <Route  path='/about' element={<About/>} /> */}
      {/* <Route  path='/singlecommunity' element={<Community_Single/>} /> */}
      {/* <Route  path='/members' element={<Members/>} /> */}
      <Route path='/singleprofile' element={<Profile_Single socket={socket} />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/register' element={<Register />} />
      <Route path='/login' element={<Login />} />
      {/* <Route  path='/help' element={<Help_Home/>} /> */}
      <Route path='/Dashboard' element={<Dashboard_Home socket={socket} />} />
      <Route path='/DashboardProfile' element={<Dashboard_MyProfile />} />
      <Route path='/DashboardProfileView' element={<Dashboard_MyProfile_View />} />
      <Route path='/DashboardPreferences' element={<Dashboard_Profile_Preferences />} />
      <Route path='/DashboardMyPhotos' element={<Dashboard_MyPhotos />} />
      {/* <Route path='/DashboardMyOrders' element={<Dashboard_MyOrders />} /> */}
      <Route path='/DashboardMyContact' element={<DashboardMyContact />} />
      <Route path='/Referrals' element={<Dashboard_Referrals />} />
      {/* <Route  path='/Matches' element={<Matches_Home/>} /> */}
      {/* <Route  path='/Search' element={<Search_Home/>} /> */}
      {/* <Route path='/Chat' element={<Chat_Home socket={socket} />} /> */}
      <Route path='/Chat' element={<Chat_Home socket={socket} me={me} activeUsers={activeUsers} />} />
      {/* <Route path='/new-chat' element={<Chat_Initiation socket={socket} />} /> */}
      <Route path='/notification' element={<Notification_Home socket={socket} />} />
      <Route path='/generate-tickets' element={<Generate_Tickets />} />
      {/* <Route  path='/billing' element={<Billing_Home/>} /> */}
      <Route path='/change-password' element={<Change_Password_Home />} />
      <Route path='/purchaseHistory' element={<Purchase_Home />} />
      <Route path='/myplan' element={<Membership value='myplan' socket={socket} />} />
      <Route path='/allplan' element={<Membership value='allplan' socket={socket} />} />
      {/* <Route  path='/inbox' element={<Inbox_Home/>} /> */}
      <Route path='/contactFilter' element={<Contact_Filter />} />
      <Route path='/emailAlert' element={<Email_Alerts />} />
      <Route path='/privacyPolicy' element={<Privacy_Policy />} />
      <Route path='/newMatches' element={<New_Matches socket={socket} />} />
      <Route path='/myMatches' element={<My_Matches socket={socket} />} />
      <Route path='/nearMatches' element={<Near_Me_Matches socket={socket} />} />
      <Route path='/todayMatches' element={<Today_Matches socket={socket} />} />
      <Route path='/recentlyViewed' element={<Recently_Viewed_Matches socket={socket} />} />
      <Route path='/moreMatches' element={<More_Matches socket={socket} />} />
      <Route path='/inboxReceived' element={<Received_Messages socket={socket} />} />
      <Route path='/inboxReceived-newestFirst' element={<Received_Messages value={'newest'} socket={socket} />} />
      <Route path='/inboxReceived-oldestFirst' element={<Received_Messages value={'oldest'} socket={socket} />} />
      {/* <Route  path='/inboxReceived-allRequests' element={<Received_Messages value={'all'} socket={socket} />} />
      <Route  path='/inboxReceived-premiumMembers' element={<Received_Messages value={'premium'} socket={socket} />} />
      <Route  path='/inboxReceived-emailVerifiedMembers' element={<Received_Messages value={'verified'} socket={socket} />} />
      <Route  path='/inboxReceived-membersWithPhotos' element={<Received_Messages value={'visible'} socket={socket} />} /> */}
      <Route path='/inboxAccepted' element={<Accepted_Messages socket={socket} />} />
      <Route path='/inboxDeleted' element={<Deleted_Messages socket={socket} />} />
      {/* <Route  path='/inboxRequested' element={<Requested_Messages socket={socket} />} /> */}
      <Route path='/inboxSent' element={<Sent_Messages socket={socket} />} />
      <Route path='/admin-home' element={<Admin_Home />} />
      <Route path='/admin-members-active' element={<Admin_Members />} />
      <Route path='/admin-members-trial' element={<Admin_Members />} />
      <Route path='/admin-members-free' element={<Admin_Members />} />
      <Route path='/admin-members-premium' element={<Admin_Members />} />
      <Route path='/admin-members-blocked' element={<Admin_Members />} />
      <Route path='/admin-members-reported' element={<Admin_Members />} />
      <Route path='/admin-members-deleted' element={<Admin_Members />} />
      <Route path='/admin-members-all' element={<Admin_Members />} />
      <Route path='/admin-profile' element={<Admin_Profile />} />
      <Route path='/admin-plan' element={<Admin_Plan />} />
      <Route path='/admin-happy-stories' element={<Admin_Happy_Stories />} />
      <Route path='/admin-coupon' element={<Admin_Coupon />} />
      <Route path='/admin-contact' element={<Admin_Contact socket={socket} />} />
      <Route path='/admin-tickets-open' element={<Admin_Tickets />} />
      <Route path='/admin-terms' element={<Admin_Terms />} />
      {/* <Route  path ='/admin-email-template1' element={<Admin_Notifications/>} /> */}
      <Route path='/admin-referrel' element={<Admin_Referrel />} />
      <Route path='/admin-blog' element={<Admin_Blog />} />
      <Route path='/admin-staff' element={<Admin_Staff />} />
      <Route path='/admin-social' element={<Admin_Social />} />
      <Route path='/admin-package-payment' element={<Admin_Package_Payment />} />
      <Route path='/reset' element={<Forget_Part_One />} />
      <Route path='/reset-password/:token' element={<Forget_Password />} />
      <Route path="/Lobby" element={<Lobby_Home socket={socket} me={me} activeUsers={activeUsers} />} />
      {/* <Route path="/Lobby2" element={<Lobby_Home2 socket={socket} me={me} activeUsers={activeUsers} />} /> */}
      {/* <Route path="/room/:roomId" element={<Room socket={socket} />} /> */}
      <Route path='*' element={<Error_404 />} />
    </Routes>

  )
}

export default App