import React, { useState } from 'react';
import '../Style/Matches/Matches_Sidebar.css';
import { Link, useLocation } from 'react-router-dom';
// Import your CSS file

const Inbox_Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const location = useLocation();
    return (
        <div className='Matches_Sidebar'>
            <div >
                <div className="sections">
                    <div className="section-heading card-header">Sort</div>
                    <div className="section">
                        {/* <input type="radio" name="visible-to-all" /> */}
                        {/* <label>Most relevant</label> */}
                        {/* <label> <Link to="/inboxReceived-inboxReceived" className={`nav-link  ${location.pathname === '/inboxReceived' ? 'active' : ''}`}>Inbox</Link></label> */}

                    </div>
                    <div className="section">
                        <input type="radio" name="visible-to-all" />
                        {/* <label>Newest first</label> */}
                        <label> <Link to="/inboxReceived-newestFirst" className={`nav-link  ${location.pathname === '/newestFirst' ? 'active' : ''}`}>Newest first</Link></label>

                    </div>
                    <div className="section">
                        <input type="radio" name="visible-to-all" />
                        {/* <label>Oldest first</label> */}
                        <label> <Link to="/inboxReceived-oldestFirst" className={`nav-link  ${location.pathname === '/oldestFirst' ? 'active' : ''}`}>Oldest first</Link></label>

                    </div>
                    {/* <div className="section-heading card-header">Filters</div> */}
                    {/* <div className="section">
                        <input type="radio" name="recently-joined" />
                        <label> <Link to="/inboxReceived-allRequests" className={`nav-link  ${location.pathname === '/allRequests' ? 'active' : ''}`}>All Requests</Link></label>

                    </div>
                    <div className="section">
                        <input type="radio" name="recently-joined" />
                        <label> <Link to="/inboxReceived-premiumMembers" className={`nav-link  ${location.pathname === '/premiumMembers' ? 'active' : ''}`}>Premium Members</Link></label>

                    </div>
                    <div className="section">
                        <input type="radio" name="recently-joined" />
                        <label> <Link to="/inboxReceived-emailVerifiedMembers" className={`nav-link  ${location.pathname === '/emailVerifiedMembers' ? 'active' : ''}`}>verified Emails</Link></label>

                    </div>
                    <div className="section">
                        <input type="radio" name="recently-joined" />
                        <label> <Link to="/inboxReceived-membersWithPhotos" className={`nav-link  ${location.pathname === '/membersWithPhotos' ? 'active' : ''}`}>Members with Photos</Link></label>

                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Inbox_Sidebar;
