import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import User_Info from '../UserDetails/User_Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';


const Card9 = (params) => {
    let navigate = useNavigate()
    let socket = params.socket
    const [showNote, setShowNote] = useState(false);
    let handleClose = () => { setShowNote(false) }
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    const [message, sentMessage] = useState({ content: '', sender: keyId });
    console.log('params.user', params.user);
    let user = params.user;
    let mainUser = params.mainUser;
    // let user = '655c2aa7da375c786aac72ff';
    console.log('user', user);
    const showToast = () => {
        toast.success('Request Successful!!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
        });
    };
    let connected = async (e, id) => {
        e.preventDefault()
        // alert('clicked')
        let date = new Date()
        date = date.toLocaleTimeString()
        let keyId = JSON.parse(localStorage.getItem('keyId'))
        params.socket?.emit("sendNotification", {
            sender: keyId,
            receiverName: id,
            receiverId: id,
            type: 'sent_Request',
            date: date
        });
        console.log(id)
        try {

            let val = await fetch(`add/${id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                }
            })
            if (val.status === 200) {
                showToast()
                setTimeout(() => {
                    window.location.reload();
                    // navigate('/inboxSent')
                }, 1940);
            }
            else {
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }

        } catch (error) {
            console.log('error', error)
        }
    }
    let nextPage = async (e, id) => {
        console.log('e', e.target)
        console.log('id', id)
        localStorage.setItem('singleProfileId', JSON.stringify(id))
        navigate('/singleprofile')
    }
    let openChat = async (id) => {
        if (mainUser?.accountType === 'free' || mainUser?.isActive == 'false') {
            navigate('/allplan')
        }
        else if (mainUser?.accountType !== 'free' || mainUser?.isActive == 'true') {
            setShowNote(true)
            let keyId = JSON.parse(localStorage.getItem('keyId'));
            sentMessage({ ...message, receiver: id })
        }
    }
    let inputEvent = (e) => {
        sentMessage({ ...message, [e.target.name]: e.target.value })
    }
    let submitEvent = async (e) => {
        try {
            e.preventDefault();
            let val = await fetch('/chat', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(message)
            })
            if (val.status === 200) {
                alert('sent')
                let date = new Date()
                date = date.toLocaleTimeString()
                socket?.emit("sendNotification", {
                    sender: keyId,
                    receiverName: message.receiver,
                    receiverId: message.receiver,
                    type: 'message initiation',
                    content: message?.content,
                    date: date
                });
            } else {
                alert('err')
            }
        } catch (error) {

        }

    }


    return (
        user?.visited?.map((ele, ind) => {
            if((!mainUser?.blockedUsers?.includes(ele?._id))&&(!ele?.blockedUsers?.includes(mainUser?._id))){
            return (
                <div style={{ margin: "2%" }}>
                    <ToastContainer />
                    {console.log('ele', ele)}
                    <div style={{ width: "17rem", margin: "2%", padding: "1%" }} className="card Card1">
                        <div style={{ margin: "auto" }}>
                            {/* <img src="img/fawad.png" style={{ height: "190px", width: '240px' }} className="card-img-top" alt="..." /> */}
                            {
                                ele?.gender === 'male' ?
                                    ele?.photos && ele?.photos?.length !== 0 ?
                                        <img src={`uploads/${ele?.photos[0]}`} style={{ height: "190px", width: '240px' }} className="card-img-top" alt="..." />
                                        :
                                        <img src='img/default_man.jpg' style={{ height: "190px", width: '240px' }} className="card-img-top" alt="..." />
                                    :
                                    ele?.photos && ele?.photos?.length !== 0 ?
                                        <img src={`uploads/${ele?.photos[0]}`} style={{ height: "190px", width: '240px' }} className="card-img-top" alt="..." />
                                        :
                                        <img src='img/default_woman.jpg' style={{ height: "190px", width: '240px' }} className="card-img-top" alt="..." />
                            }
                        </div>
                        {/* <div onClick={() => navigate('/singleprofile')} className="card-body" style={{ textAlign: 'left', cursor: 'pointer' }}>
                            <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                            <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                            <a href="#" onClick={(e) => Connected(e, ele)} className="custom-button">Connect Now</a>
                        </div> */}
                        {
                            mainUser && mainUser?.sentRequests && mainUser?.sentRequests?.includes(ele) && (
                                <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                                    <span id="report_id_42"
                                        class="text-dark">
                                        <PendingIcon />
                                        <span
                                            class="d-block fs-10 opacity-60">Waiting Approval</span>
                                    </span>
                                </div>
                            )
                        }

                        {
                            mainUser && mainUser?.rejectedByReciever && mainUser?.rejectedByReciever?.includes(ele) && (
                                <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                                    <span id="report_id_42"
                                        class="text-dark">
                                        <CancelIcon />
                                        <span
                                            class="d-block fs-10 opacity-60">Declined</span>
                                    </span>
                                </div>
                            )
                        }
                        {
                            mainUser && mainUser?.friends && mainUser?.friends?.includes(ele) && (
                                <div onClick={(e) => nextPage(e, ele)} style={{ textAlign: "center" }}>
                                    <h5 className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                                    <p className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                                    {/* <div className="custom-message bg-success text-white">Accepted</div> */}
                                    <span id="report_id_42"
                                        class="text-dark">
                                        <i class="las la-info-circle fs-20 text-primary"></i>
                                        {mainUser?.accountType == 'free' || mainUser?.isActive !== 'true' ?
                                            <p> <i> Upgrade to Contact him directly</i></p>
                                            : ''}
                                        <div>
                                            <button class="d-block fs-10 opacity-60" onClick={(e) => nextPage(e, ele._id)}>  <CallIcon className='buttons' /> Call</button>
                                            <button class="d-block fs-10 opacity-60" onClick={() => openChat(ele._id)} > <SendIcon className='buttons' /> Chat</button>
                                        </div>
                                    </span>
                                </div>
                            )
                        }
                        {
                            mainUser && !mainUser?.sentRequests?.includes(ele) && !mainUser?.rejectedByReciever?.includes(ele) && !mainUser.friends?.includes(ele) && !mainUser.recievedRequests?.includes(ele) && (
                                <div className="profile-container">
                                    {/* <p> <i> Like this profile?</i></p> */}
                                    <div onClick={(e) => { connected(e, ele) }} className='icon-container'>
                                        <h5 onClick={(e) => nextPage(e, ele)} className="card-title"> <User_Info id={ele} title={'name'} /> </h5>
                                        <p onClick={(e) => nextPage(e, ele)} className="card-text"><User_Info id={ele} title={'age'} /> yrs, <User_Info id={ele} title={'height'} />, <br /> <User_Info id={ele} title={'motherTongue'} /></p>
                                        <p className="custom-button text-white">Connect Now </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <Modal show={showNote} onHide={handleClose}>
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Select a Plan</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    {/* <Form.Label>Select a plan:</Form.Label> */}
                                    {/* <FormControl   as="select" multiple> */}
                                    <input onChange={(e) => inputEvent(e)} type="text" className="form-control" name='content' placeholder="Type your message" />
                                    {/* <textarea name=""  value={notePlan?.description}>{notePlan?.description}</textarea> */}
                                    {/* <textarea name="description" id="" cols="30" rows="10"></textarea> */}
                                    {/* Add more options as needed */}
                                    {/* </FormControl> */}
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                                <button onClick={(e) => submitEvent(e)} className=" custom-button mx-2">Send</button>
                            </div>
                            {/* Additional buttons or actions can be added here */}
                        </Modal.Footer>
                    </Modal>
                </div >
            )
                    }
        })
    )
}

export default Card9