import React, { useEffect, useState } from 'react'
import Login_Header from '../Components/Login/Login_Header'
import Loader from '../Loader/Loader'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  let navigate = useNavigate()
  let [isUser, setIsUser] = useState()
  let [loading, setLoading] = useState(true)
  let keyId = JSON.parse(localStorage.getItem('keyId'))
  useEffect(() => {
    setIsUser(keyId)
    setLoading(false)
  }, [])
  return (
    loading ?
      (
        <Loader />
      )
      :
      !isUser ?
        <div>
          <Login_Header />
        </div>
      :navigate('/dashboard')  
  )
}

export default Login