import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
const Admin_Referrel_Table = ({ data, socket }) => {
    let params = useParams();
    let navigate = useNavigate();
    let member_link = JSON.parse(localStorage.getItem('member_link'));
    console.log('member_link', member_link)
    console.log('params', params)
    console.log('data here')
    console.log('data here', data)
    console.log('socket here', socket)
    let keyId = JSON.parse(localStorage.getItem('keyId'))
    let [fetchedData, setFetchedData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [userPlan, setUserPlan] = useState({});
    const [newData, setNewData] = useState([]);
    const handleClose = () => setShowModal(false);
    const handleClose2 = () => setShowDropdown(false);
    const handleClose3 = () => setShowNote(false);
    const handleShow = (ele) => {
        setShowModal(true)
        setFetchedData(ele)
    };
    let deleteAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/refferel/${id}`,
                    {
                        method: "Delete"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (Err) {

            }
        }
    }
    const handleEdit = (fetchedData) => {
        setShowDropdown(true); // Show the dropdown when the button is clicked
        setUserPlan(fetchedData)
    };
    const addNote = (fetchedData) => {
        setShowNote(true); // Show the dropdown when the button is clicked
    };

    let changePlan = (e) => {
        // else {
        setUserPlan({ ...userPlan, [e.target.name]: e.target.value })
        // }
    }
    let submitPlan = async (e) => {
        e.preventDefault()
        console.log('userPlan', userPlan)
        console.log(fetchedData?.refferedEmail)
        let refferedEmail = fetchedData?.refferedEmail;
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/sent-email`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(userPlan, refferedEmail)
            })
            if (val.status === 200) {
                let complete = true
                let val = await fetch(`/refferel/${fetchedData?._id}`, {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({ complete })
                })
                if (val.status === 200) {
                    alert('Email sent Sucessfully!')
                }
                // props?.socket?.emit("sendNotification", {
                // console.log('sendNotification here socket', socket)
                // socket?.emit("sendNotification", {
                //     sender: keyId,
                //     // receiverName: receiver,
                //     receiverId: userPlan?.userId,
                //     type: 'admin contact reply',
                //     content: userPlan?.reply,
                //     chatId: userPlan?._id,
                //     // date: date
                // });

                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (err) { }
    }

    let newDataPlan = (e) => {
        let value
        if (e.target.name === 'features') {
            value = e.target.value.split(',');
            console.log('balue', value)
            setNewData({ ...newData, [e.target.name]: value })
        }
        else {
            setNewData({ ...newData, [e.target.name]: e.target.value })
        }
    }
    let submitNewDataPlan = async (e) => {
        e.preventDefault()
        // console.log('notePlan', notePlan)
        console.log('submitNewDataPlan', newData)
        console.log(fetchedData?._id)
        let val = await fetch(`/contact`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(newData)
        })
        if (val.status === 200) {
            alert('new plan added!')
            window.location.reload()
        }
        else { alert('error!') }
    }
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    let approveStory = async (data) => {
        let y;
        {
            data.approval ?
                y = window.confirm(`Do you want to remove its approval?`)
                :
                y = window.confirm(`Do you want to approve?`)
        }
        if (y) {
            try {
                let approval;
                if (data?.approval) {
                    approval = false
                }
                else {
                    approval = true
                }
                let val = await fetch(`/contact/${fetchedData?._id}`, {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({ approval })
                })
                if (val.status === 200) {
                    alert('changes updated!')
                    window.location.reload()
                }
                else { alert('error!') }
            } catch (error) {

            }
        }

    }


    return (
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Queries</h4>
                            <div class="card-header-form">
                                {/* <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" />
                                        <div class="input-group-btn">
                                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        {/* <Button className="custom-button w-25 mx-auto mt-1 " style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                            Create Story
                        </Button> */}
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tr>
                                        {/* <th class="text-center">
                                            <div class="custom-checkbox custom-checkbox-table custom-control">
                                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad"
                                                    class="custom-control-input" id="checkbox-all" />
                                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                                            </div>
                                        </th> */}
                                        {/* <th>LV Number</th>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th>Date Registered</th>
                                        <th>Expiry Date</th>
                                        <th>Status</th>
                                        <th>Email Address</th> */}
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Reffered Email</th>
                                        {/* <th>Message</th> */}
                                        <th>Phone</th>
                                        <th>Status</th>
                                        {/* <th>Approval</th> */}
                                        <th>Actions</th>
                                    </tr>
                                    {/* {member_link?.map((ele, ind) => { */}
                                    {/* free?.map((ele, ind) => { */}

                                    {
                                        data?.map((ele, ind) => {
                                            return (
                                                <tr>
                                                    {/* <td class="p-0 text-center">
                                                        <div class="custom-checkbox custom-control">
                                                            <input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
                                                                id="checkbox-1" />
                                                            <label for="checkbox-1" class="custom-control-label">&nbsp;</label>
                                                        </div>
                                                    </td> */}
                                                    <td>{ele?._id}</td>
                                                    <td class="text-truncate">
                                                        {ele?.name}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {ele?.email}
                                                    </td>
                                                    <td class="text-truncate">
                                                        {ele?.refferedEmail}
                                                    </td>
                                                    {/* <td class="align-middle">
                                                        {ele?.message}
                                                    </td> */}
                                                    <td class="align-middle">
                                                        {ele?.phone}
                                                    </td>
                                                    {/* <td class="align-middle">
                                                        <td>{setDates(ele?.createdAt)?.toString()?.split('T')[0]}</td>
                                                    </td> */}
                                                    {/* {console.log('ele.approval', ele.approval)} */}
                                                    {ele.complete ?
                                                        <td>
                                                            <div class="badge badge-success">Completed</div>
                                                        </td>
                                                        : <td>
                                                            <div class="badge badge-success">Not Completed</div>
                                                        </td>}
                                                    <td>
                                                        <a href="#" className="btn btn-outline-primary" onClick={() => handleShow(ele)}>
                                                            Detail
                                                        </a>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Actions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Button className="custom-button w-100 mx-auto mt-1 " style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                                            View
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => deleteAccount(fetchedData?._id)}>
                                            Delete
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit(fetchedData) }}>
                                            Sent Email
                                        </Button>
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showDropdown} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        {/* <Modal.Title>Select a Plan</Modal.Title> */}
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>write email:</Form.Label>
                                                <br />
                                                <label for="name" class="">Subject</label>
                                                <input onChange={changePlan} type="text" className='form-control' name="subject" id="" />
                                                <label for="name" class="">Content:</label>
                                                <br />
                                                <textarea onChange={changePlan} className='form-control' name="content" id="" cols="30" rows="3"></textarea>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Sent
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <br />
                                                <label for="name" class="">Name</label>
                                                {/* <input onChange={newDataPlan} type="text" className='form-control' name="name" id="" /> */}
                                                <p>{fetchedData?.name}</p>
                                                <label for="name" class="">Email</label>
                                                <p>{fetchedData?.email}</p>
                                                {/* <input onChange={newDataPlan} type="text" className='form-control' name="partnerName" id="" /> */}
                                                <label for="name" class="">Referred Email</label>
                                                <p>{fetchedData?.refferedEmail}</p>
                                                {/* <input onChange={newDataPlan} type="date" className='form-control' name="meetDate" id="" /> */}
                                                <label for="name" class="">Phone</label>
                                                <p>{fetchedData?.phone}</p>
                                                {/* <input onChange={newDataPlan} type="date" className='form-control' name="weddingDate" id="" /> */}
                                                <label for="name" class="">Message</label>
                                                <p>{fetchedData?.message}</p>
                                                {/* <textarea onChange={newDataPlan} className='form-control' name="description" id="" cols="30" rows="3" ></textarea> */}

                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {/* <Button className="custom-button" variant="secondary" onClick={submitNewDataPlan}>
                                            Save
                                        </Button> */}
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Referrel_Table