import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login_Header = () => {
    let [newUser, setNewUser] = useState()
    let navigate = useNavigate()
    let inputEvent = (e) => {
        if (e.target.name === 'email') {
            let email = e.target.value;
            email = email.toLowerCase()
            setNewUser({ ...newUser, [e.target.name]: email })
        }
        else {
            setNewUser({ ...newUser, [e.target.name]: e.target.value })
        }
    }
    const showToast = () => {
        toast.success('Request successful!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Duration in milliseconds
            //   onClose:()=>{
            //       navigate('/dashboard')
            //   }
        });
    };
    const [formErrors, setFormErrors] = useState({});
    let validateForm = () => {
        const errors = {};
        if (!newUser?.email) {
            errors.email = 'email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(newUser?.email)) {
            errors.email = 'Email is invalid';
        }
        if (!newUser?.password) {
            errors.password = 'password is required';
        }
        setFormErrors(errors);
        console.log('here_formErrors', formErrors);
        return Object.keys(errors).length === 0;
    }

    let submitEvent = async (e) => {
        e.preventDefault()
        const isValid = validateForm();
        try {
            if(isValid){
            console.log('newUser', newUser)
            let val = await fetch('/signin', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(newUser)
            })
            if (val.status === 200) {
                showToast();
                const data = await val.json();
                console.log('data', data)
                localStorage.setItem('isAdmin', JSON.stringify(data.isAdmin))
                localStorage.setItem('keyId', JSON.stringify(data._id))
                setTimeout(() => {
                    // navigate('/dashboard'); // Navigate after a delay
                    navigate('/DashboardProfile'); // Navigate after a delay
                }, 1940);
            }
            else if (val.status === 201) {
                showToast();
                const data = await val.json();
                console.log('data', data)
                localStorage.setItem('isAdmin', JSON.stringify(data.isAdmin))
                localStorage.setItem('keyId', JSON.stringify(data._id))
                setTimeout(() => {
                    navigate('/admin-home'); // Navigate after a delay
                }, 1940);
            }
            else {
                // alert('error creating account')
                toast.error('Request failed!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000 // Duration in milliseconds
                });
            }}
        }
        catch (err) {

        }
    }
    return (
        <div>
            <section className="log-reg">
                <div className="top-menu-area">
                    <div className="container">
                        <ToastContainer />
                        <div className="row">
                            <div className="col-lg-5">
                                <a href="/register" className="backto-home"><i className="fas fa-chevron-left"></i> Back to Register</a>
                            </div>
                            <div className="col-lg-7 ">
                                {/* <div className="logo">
                                    <img src="assets/images/logo2.png" alt="logo"/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="image image-log">
                        </div>
                        <div className="col-lg-7">
                            <div className="log-reg-inner">
                                <div className="section-header inloginp">
                                    <h2 className="title">
                                        Welcome to My Lovodi
                                    </h2>
                                </div>
                                <div className="main-content inloginp">
                                    <form action="#">
                                        <div className="form-group">
                                            <label for="">Your Email</label>
                                            <input type="email" name="email" onChange={inputEvent} className="my-form-control" placeholder="Enter Your Email" />
                                            {formErrors?.email && <p className="error">{formErrors?.email}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label for="">Password</label>
                                            <input type="password" name="password" onChange={inputEvent} className="my-form-control" placeholder="Enter Your Password" />
                                            {formErrors?.password && <p className="error">{formErrors?.password}</p>}
                                        </div>
                                        <p className="f-pass">
                                            Forgot your password? <a href="/reset">recover password</a>
                                        </p>
                                        <div className="button-wrapper">
                                            <button onClick={submitEvent} type="submit" className="custom-button">Sign IN</button>
                                        </div>
                                        {/* <div className="or">
                                            <p>OR</p>
                                        </div> */}
                                        {/* <div className="or-content">
                                            <p>Sign up with your email</p>
                                            <a href="#" className="or-btn"><img src="assets/images/google.png" alt="" /> Sign Up with Google</a>
                                            <p className="or-signup">
                                                Don't have an account? <a href="register.html">
                                                    Sign up here
                                                </a>
                                            </p>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Login_Header