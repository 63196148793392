import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import axios from 'axios';
// import json2csv from 'json2csv';
// import { createObjectCsvWriter } from 'csv-writer';

const Admin_Home_Table = ({ data }) => {
    let params = useParams();
    let navigate = useNavigate();
    let member_link = JSON.parse(localStorage.getItem('member_link'));
    console.log('member_link', member_link)
    console.log('params', params)
    let trial = [], free = [], gold = [], premium = [], newMember = [], newlyAccounts = [], blocked = [], reported = [], deleted = [];
    let [within24Hours, setIsWithin24Hours] = useState(false)
    let [fetchedData, setFetchedData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [editingField, setEditingField] = useState(null);
    const [newOption, setNewOption] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [showNote, setShowNote] = useState(false);
    const [plans, updatePlans] = useState([]);
    const [notes, updateNotes] = useState([]);
    const [userPlan, setUserPlan] = useState({ accountType: '' });
    const [notePlan, setNotePlan] = useState();
    const [details, setDetails] = useState([]);
    const handleClose = () => setShowModal(false);
    const handleClose2 = () => setShowDropdown(false);
    const handleClose3 = () => setShowNote(false);
    const handleShow = (ele) => {
        setShowModal(true)
        setFetchedData(ele)
    };
    let newAccount = async (createdAt) => {
        const createdDate = new Date(createdAt);
        const currentTime = new Date();
        const differenceInMilliseconds = currentTime - createdDate;
        const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
        const isWithin24Hrs = differenceInMilliseconds < twentyFourHoursInMilliseconds;
        setIsWithin24Hours(isWithin24Hrs);
    }
    data?.map((ele, ind) => {
        if (ele) {
            const createdDate = new Date(ele?.createdAt);
            const currentTime = new Date();
            const differenceInMilliseconds = currentTime - createdDate;
            const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
            const isWithin24Hrs = differenceInMilliseconds < twentyFourHoursInMilliseconds;
            if (isWithin24Hrs) {
                newlyAccounts.push(ele);;
            }
        }
        if (ele.accountType === 'trial' || ele.accountType === 'welcome') {
            trial.push(ele);
        }
        if (ele.accountType === 'free' || ele.accountType === 'Free') {
            free.push(ele);
        }
        if (ele.accountType != 'free' || ele.accountType != 'Free' || ele.accountType != 'trial' || ele.accountType != 'welcome') {
            premium.push(ele);
        }
        // if (ele.accountType === 'gold' || ele.accountType === 'Gold'  ) {
        //     gold.push(ele);
        // }
        // if (
        // ele.accountType === 'premium' || ele.accountType === 'premium' || (ele.accountType === 'gold' || ele.accountType === 'Gold'  ) || (ele.accountType === 'silver' || ele.accountType === 'Silver'  ) ||
        // ele.accountType === 'platinium' || ele.accountType === 'booster plan 1' || (ele.accountType === 'gold' || ele.accountType === 'Gold'  ) || (ele.accountType === 'silver' || ele.accountType === 'Silver'  )
        // ) {
        //     premium.push(ele);
        // }
        // if (ele.categpory=='membership' || ele.categpory=='profile booster' || ele.categpory=='contact' || ele.categpory=='block' ) {
        //     premium.push(ele);
        // }
        // console.log('ele.block',ele.block)
        if (ele.block === true) {
            blocked.push(ele);
        }
        if (ele.delete === true) {
            deleted.push(ele);
        }
    })
    let memberFunc = () => {

        if (member_link === 'free' || member_link === 'Free') {
            setDetails(free)
        }
        else if (member_link === 'trial') {
            setDetails(trial)
        }
        else if (member_link === 'premium') {
            setDetails(premium)
        }
        else if (member_link === 'deleted') {
            setDetails(deleted)
        }
        else if (member_link === 'blocked') {
            setDetails(blocked)
        }
        else if (member_link === 'reported') {
            setDetails(reported)
        }
        else if (member_link === 'all') {
            setDetails(data)
        }
    }
    // let trial = [], free = [], gold = [], premium = [], newMember = [], newlyAccounts = [];
    useEffect(() => {
        memberFunc()
    }, [member_link])
    let setDates = (date) => {
        console.log('dateObject_Date', date)
        const dateObject = new Date(date);
        console.log('dateObject', dateObject)
        return dateObject;
    }
    let blockAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/user/block/${id}`,
                    {
                        method: "PUT",
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({})
                    }
                )
                if (val.status === 200) {
                    alert('blocked')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) {

            }
        }
    }
    let unblockAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/user/unblock/${id}`,
                    {
                        method: "PUT",
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({})
                    }
                )
                if (val.status === 200) {
                    alert('blocked')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) { }
        }
    }
    let deleteAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/UserdeletebyAdmin/${id}`,
                    {
                        method: "PUT"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) { }
        }
    }
    let reactivateAccount = async (id) => {
        let y = window.confirm(`Confirm changes?`)
        if (y) {
            try {
                let val = await fetch(`/UserreactivatebyAdmin/${id}`,
                    {
                        method: "PUT"
                    }
                )
                if (val.status === 200) {
                    alert('deleted')
                    window.location.reload()
                }
                else {
                    alert('error')
                }
            }
            catch (err) { }
        }
    }
    const handleEdit = (fetchedData) => {
        // Logic for handling fetchedData or any other actions on edit
        setShowDropdown(true); // Show the dropdown when the button is clicked
        setUserPlan(fetchedData?.accountType)
    };
    const addNote = (fetchedData) => {
        // Logic for handling fetchedData or any other actions on edit
        setNotePlan(fetchedData?._id)
        setShowNote(true); // Show the dropdown when the button is clicked
    };

    let allPlans = async () => {
        try {
            let val = await axios.get('/allPlans');
            console.log('val.data', val.data)
            updatePlans(val.data)
        }
        catch (err) { }
    }
    useEffect(() => {
        allPlans()
    }, [])
    let allNotes = async () => {
        try {
            let val = await axios.get(`/adminNotes/${fetchedData?._id}`);
            console.log('val.data_allNotes', val.data)
            updateNotes(val.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        allNotes()
    }, [])
    let changePlan = (e) => {
        setUserPlan({ [e.target.name]: e.target.value })
    }
    let submitPlan = async (e) => {
        e.preventDefault()
        console.log(userPlan)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/user/${fetchedData?._id}`, {
                method: "PUT",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(userPlan)
            })
            if (val.status === 200) {
                alert('changes updated!')
                window.location.reload()
            }
            else { alert('error!') }
        } catch (err) { }
    }
    let changeNote = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        setNotePlan({ [e.target.name]: e.target.value })
    }
    let submitNote = async (e) => {
        e.preventDefault()
        console.log('notePlan', notePlan)
        console.log(fetchedData?._id)
        try {
            let val = await fetch(`/adminNotes/${fetchedData?._id}`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(notePlan)
            })
            if (val.status === 200) {
                alert('changes updated!')
                window.location.reload()
            }
            else { alert('error!') }
        }
        catch (err) {

        }
    }
    const generateCSV = () => {
        try {
            if (details.length === 0) {
                alert('Details array is empty!');
                return;
            }
            // Define CSV header
            
            //   const fields = Object.keys(details[0]);
            //   const csv = json2csv.parse(details, { fields });
            //   const blob = new Blob([csv], { type: 'text/csv' });
            //   const link = document.createElement('a');
            //   link.href = window.URL.createObjectURL(blob);
            //   link.download = 'details.csv';
            //   link.click();
        } catch (error) {
            console.error('Error generating CSV:', error);
        }
    };
    const objectToCsv = (data) => {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    const downloadCSV = () => {
        const csvContent = objectToCsv(details);

        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
    };
    return (
        <div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4>Members</h4>
                            <div class="card-header-form">
                                {/* <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search" />
                                        <div class="input-group-btn">
                                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <tr>
                                        {/* <th class="text-center">
                                            <div class="custom-checkbox custom-checkbox-table custom-control">
                                                <input type="checkbox" data-checkboxes="mygroup" data-checkbox-role="dad"
                                                    class="custom-control-input" id="checkbox-all" />
                                                <label for="checkbox-all" class="custom-control-label">&nbsp;</label>
                                            </div>
                                        </th> */}
                                        <th>LV Number</th>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th>Date Registered</th>
                                        {/* <th>Expiry Date</th> */}
                                        {/* <th>Status</th> */}
                                        <th>Email Address</th>
                                        <th>Actions</th>
                                    </tr>
                                    {/* {member_link?.map((ele, ind) => { */}
                                    {/* free?.map((ele, ind) => { */}
                                    {
                                        details?.map((ele, ind) => {
                                            return (
                                                <tr>
                                                    {/* <td class="p-0 text-center">
                                                    <div class="custom-checkbox custom-control">
                                                        <input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
                                                            id="checkbox-1" />
                                                        <label for="checkbox-1" class="custom-control-label">&nbsp;</label>
                                                    </div>
                                                </td> */}
                                                    <td>{ele?._id}</td>
                                                    {/* 
                                        <td class="text-truncate">
                                        <ul class="list-unstyled order-list m-b-0 m-b-0">
                                        <li class="team-member team-member-sm"><img class="rounded-circle"
                                                    src="assets6/img/users/user-8.png" alt="user" data-toggle="tooltip" title=""
                                                    data-original-title="Wildan Ahdian" /></li>
                                                <li class="team-member team-member-sm"><img class="rounded-circle"
                                                    src="assets6/img/users/user-9.png" alt="user" data-toggle="tooltip" title=""
                                                    data-original-title="John Deo" /></li>
                                                <li class="team-member team-member-sm"><img class="rounded-circle"
                                                    src="assets6/img/users/user-10.png" alt="user" data-toggle="tooltip" title=""
                                                    data-original-title="Sarah Smith" /></li>
                                                <li class="avatar avatar-sm"><span class="badge badge-primary">+4</span></li>
                                            </ul>
                                        </td> */}
                                                    <td class="text-truncate">
                                                        {ele?.name}
                                                    </td>
                                                    {/* 
                                                <td class="align-middle">
                                                <div class="progress-text">50%</div>
                                                <div class="progress" data-height="6">
                                                <div class="progress-bar bg-success" data-width="50%"></div>
                                                </div>
                                            </td> */}
                                                    <td class="align-middle">
                                                        {ele?.accountType}
                                                    </td>
                                                    {/* <td>{ele?.createdAt}</td> */}
                                                    {/* <td><setDates date={ele?.createdAt} /></td> */}
                                                    <td>{setDates(ele?.createdAt)?.toString()?.split('T')[0]}</td>
                                                    {/* <td>2019-05-28</td> */}
                                                    {/* <td>
                                                    <div class="badge badge-success">Hidden</div>
                                                </td> */}
                                                    <td>{ele?.email}</td>
                                                    <td>
                                                        {/* <a href="#" class="btn btn-outline-primary">Detail</a> */}
                                                        <a href="#" className="btn btn-outline-primary" onClick={() => handleShow(ele)}>
                                                            Detail
                                                        </a>
                                                    </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </table>
                                <div style={{textAlign:'center'}}>
                                <button className='custom-button ' style={{margin:'2% auto',textAlign:'center'}} onClick={downloadCSV}>Generate CSV</button>
                                </div>
                                <Modal show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Actions</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Your options here */}
                                        {/* <Button className="" variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => {
                                            console.log('fetchedData', fetchedData)
                                            localStorage.setItem('singleProfileId', JSON.stringify(fetchedData?._id))
                                            navigate('/singleProfile')
                                        }}>
                                            View Profile
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => blockAccount(fetchedData?._id)}>
                                            Block Account
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => deleteAccount(fetchedData?._id)}>
                                            Delete Account
                                        </Button>
                                        {/* <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => {  }}>
                                            Activities
                                        </Button> */}
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { handleEdit(fetchedData) }}>
                                            Change Plan
                                        </Button>
                                        <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { addNote(fetchedData) }}>
                                            Add Note
                                        </Button>
                                        {member_link === 'deleted' ?
                                            <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { reactivateAccount(fetchedData) }}>
                                                Reactivate Account
                                            </Button>
                                            : ''}
                                        {member_link === 'blocked' ?
                                            <Button className="custom-button w-100 mt-1 mx-auto" style={{ display: "block" }} size="sm" variant="primary" onClick={() => { unblockAccount(fetchedData) }}>
                                                Unblock Account
                                            </Button>
                                            : ''}
                                        {/* Add more options as needed */}
                                    </Modal.Body>
                                </Modal>
                                <Modal show={showDropdown} onHide={handleClose2}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Select a Plan</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Select a plan:</Form.Label>
                                                <FormControl onChange={changePlan} name="accountType" as="select" multiple>
                                                    {plans ?
                                                        plans?.name?.map((ele, ind) => {
                                                            return (
                                                                <option value={ele}>{ele}</option>
                                                            )
                                                        })
                                                        : ''
                                                    }
                                                    {/* <option value="plan2">Plan 2</option> */}
                                                    {/* Add more options as needed */}
                                                </FormControl>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button type="submit" className="custom-button" onClick={submitPlan}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showNote} onHide={handleClose3}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Select a Plan</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <Form.Group>
                                                {/* <Form.Label>Select a plan:</Form.Label> */}
                                                {/* <FormControl   as="select" multiple> */}
                                                <textarea name="description" onChange={changeNote} value={notes?.description}>{notes?.description}</textarea>
                                                {/* <textarea name=""  value={notePlan?.description}>{notePlan?.description}</textarea> */}
                                                {/* <textarea name="description" id="" cols="30" rows="10"></textarea> */}
                                                {/* Add more options as needed */}
                                                {/* </FormControl> */}
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="custom-button" variant="secondary" onClick={submitNote}>
                                            Save
                                        </Button>
                                        {/* Additional buttons or actions can be added here */}
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Home_Table